//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getAffiliateSubAffiliates,
    setAffiliateSubAffiliatesFilters
} from "store/actions/portal/affiliates/affiliates/subAffiliates.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import SubAffiliatesReferralUrl from './referralUrl';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { USER_ROLE } from 'constants/user.constants';
import { tableColumns, expandTableColumns } from './columns';
//#endregion

//#region types
import subAffiliateType from "types/affiliate/subAffiliate.type";
//#endregion

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

const ROW_UNIQUE_KEY_PROP = "id";
const EXPAND_TABLE_ROW_UNIQUE_KEY = "currencyCode";

/** Affiliate Edit Page Sub Affilaites Tab Component */
const SubAffiliatesComponent = ({
    subAffiliates,
    referralURL,
    referralCommissionPercent,
    setAffiliateSubAffiliatesFilters,
    getAffiliateSubAffiliates,
    isLoading,
    filters
}) => {
    const { t, locale } = useTranslation();

    const searchParams = useParams();

    const userInfo = useGlobalSelector(userInfoSelector);
    const role = useAuthSelector(userRoleSelector);

    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : searchParams.id;

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAffiliateSubAffiliatesFilters({
            ...filters,
            userNameOrId: value
        })
    }

    const mapTotalCommissions = receivedReferralCommissions => {
        return Object.keys(receivedReferralCommissions).map(currency => ({
            currencyCode: currency.toUpperCase(),
            amount: receivedReferralCommissions[currency]
        }))
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsForExport is only needed in the desktop version.
    const { 
        mainTableColumns,
        expandTableColumns: expandColumns
    } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
            expandColumns: expandTableColumns, 
            t
        });
    }, [t])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        search: {
            onSearch: handleSearchChange,
            loadFn: () => getAffiliateSubAffiliates(affiliateId, locale),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATES,
                actionParams: {
                    parentId: affiliateId
                }
            },
            placeholder: t("backoffice.affiliates.usernameOrId"),
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={
                <SubAffiliatesReferralUrl
                    referralUrl={referralURL}
                    referralCommissionPercent={referralCommissionPercent}
                />
            }
        >
            <div className='rt--affiliates-referral-list'>
                <Table
                    key={affiliateId}
                    loading={isLoading}
                    columns={mainTableColumns}
                    data={subAffiliates}
                    loadFn={() => getAffiliateSubAffiliates(affiliateId, locale)}
                    total={subAffiliates.length}
                    uniqueKey={ROW_UNIQUE_KEY_PROP}
                    disableFullView={false}
                    noPagination={true}
                    expandable={{
                        title: t("backoffice.affiliates.totalCommission"),
                        details: rowData => ({
                            columns: expandColumns,
                            data: mapTotalCommissions(rowData.receivedReferralCommissions),
                            uniqueKey: EXPAND_TABLE_ROW_UNIQUE_KEY,
                        }),
                    }}
                />
            </div>

        </TabTableDashboardLayout>
    )
}

/** SubAffiliatesComponent propTypes
    * PropTypes
*/
SubAffiliatesComponent.propTypes = {
    /** Redux action to get affiliate sub-affiliates */
    getAffiliateSubAffiliates: PropTypes.func,
    /** Redux state property, is true when affiliate sub-affiliates are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, represents the sub-affiliates of current editing affiliate */
    subAffiliates: PropTypes.arrayOf(subAffiliateType),
    /** Redux state property, represents the referral Url of current editing affiliate */
    referralURL: PropTypes.string,
    /** Redux state property, represents the referral Commission Percent of current editing affiliate */
    referralCommissionPercent: PropTypes.number,
    /** Redux state property, affiliate sub-affiliates filters */
    filters: PropTypes.object,
    /** Redux action to set affiliate sub-affiliates filters */
    setAffiliateSubAffiliatesFilters: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateSubAffiliates: (affiliateId, languageCode) => {
            dispatch(getAffiliateSubAffiliates(affiliateId, languageCode));
        },

        setAffiliateSubAffiliatesFilters: filters => {
            dispatch(setAffiliateSubAffiliatesFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        subAffiliates: state.affiliates.edit.subAffiliates.subAffiliates,
        referralURL: state.affiliates.edit.subAffiliates.referralURL,
        referralCommissionPercent: state.affiliates.edit.subAffiliates.referralCommissionPercent,
        filters: state.affiliates.edit.subAffiliates.filters,
        isLoading: state.affiliates.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubAffiliatesComponent)