import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

const autosuggestionService = {
    getAffiliates(params, signal){
        return httpService.get({
            url: API_URL.AUTOSUGGESTION_GET_AFFILIATES,
            signal,
            queryData: params
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getPostbacks(signal){
        return httpService.get({
            url: API_URL.AUTOSUGGESTION_GET_POSTBACKS,
            signal
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getCampaigns(data, signal){
        return httpService.get({
            url: API_URL.AUTOSUGGESTION_GET_CAMPAIGNS,
            signal,
            queryData: data
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getMedias(data, signal){
        return httpService.get({
            url: API_URL.AUTOSUGGESTION_GET_MEDIAS,
            signal,
            queryData: data
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getPlayers(params={}, signal){
        return httpService.get({
            url: API_URL.AUTOSUGGESTION_GET_PLAYERS,
            signal,
            queryData: params
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getBrands(signal){
        return httpService.get({
            url: API_URL.AUTOSUGGESTION_GET_BRANDS,
            signal
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getPromoCodes(params, signal){
        return httpService.get({
            url: API_URL.AUTOSUGGESTION_GET_PROMO_CODES,
            signal,
            queryData: params
        }).then(({ data: { value } }) => {
            return value;
        })
    },
}

export default autosuggestionService;