import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import TabTableDashboardLayout from 'components/layouts/tab/table';

import { Col, Row } from 'antd';

import OperationalLineChart from '../../../components/operationalLineChart';

import Filters from "../../../components/filters"

import { TIME_PERIOD } from '../../../constants';

import useFirstRender from 'hooks/useFirstRender';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { USER_ROLE } from 'core/constants/auth';

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        },
        affiliateId: '',
    }
)

const Player = ({
    updateLastUpdatedDate
}) => {

    const [ filters, setFilters ] = useState(getDefaultFilters())

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const userRole = useAuthSelector(userRoleSelector);

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })

    const showAffiliateFilter = userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.AFFILIATE_MANAGER;

    return (
        <TabTableDashboardLayout
            header={
                {
                    filters: <Filters filters={filters} updateFilters={updateFilters} showAffiliateFilter={showAffiliateFilter}/>
                }
            }
        >
            <div className='rt--operational-general'>
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <OperationalLineChart
                            updateLastUpdatedDate={updateLastUpdatedDate}
                            filters={filters}
                            updateFilters={updateFilters}
                        />
                    </Col>
                </Row>
            </div>
            
        </TabTableDashboardLayout>
    )
}

Player.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default Player;