const initialState = {
    notifications: [],
    unreadNotifications: [],
    total: 0,
    unreadTotal: 0,
    isSoundEnabled: true,
    playSound: false,
};

export default initialState;


