import React, { useState, useEffect, useCallback, useRef } from "react";

import { useTranslation } from "core/hooks/useTranslation";

import { Input } from 'antd';

import AutoComplete from "components/common/autoComplete";
import Icon from "components/common/icon";

import useAutosuggestion from "hooks/useAutosuggestion";

import { debounce, isMobile } from "utils/common";

import searchType from 'types/components/search.type';

/** Autocomplete Search Component */
const AutocompleteSearch = ({
    autoSuggestion,
    loadFn,
    placeholder,
    onSearch,
    onChange,
    showId=false
}) => {

    const { t } = useTranslation();

    const isSearched = useRef("");

    const [value, setValue] = useState("");

    const [ autoSuggestionData, getAutoSuggestionData, resetAutoSuggestionData ] = useAutosuggestion({
        type: autoSuggestion?.type, 
        dependencies: autoSuggestion?.dependencies, 
        actionParams: autoSuggestion?.actionParams,
        autoGet: autoSuggestion?.autoGet
    });

    const autoSuggestionItems = autoSuggestion?.items ?? autoSuggestionData;

    const doSearch = (v, eventType) => {
        isSearched.current = v;

        if(v !== "" && showId){
            const splitted = v.split("(ID=");
            if(splitted[1]){
                v = splitted[1].replace(")", "");
            }
        }

        const searchedItem = v !== "" ? autoSuggestionItems.find(item => (
            item.longId?.toString() === v ||
            item.name?.toLowerCase() === v.toLowerCase()
        )) || null : null

        onSearch?.(v, searchedItem, eventType);
        setTimeout(() => {
            loadFn?.(true);
        }, 20)
    }

    const handleChange = v => {
        if(v){
            setValue(v);
        } else {
            clear();
        }
        onChange?.(v);
    }

    const handleSearch = (v, eventType) => {
        setValue(v);
        if (eventType === 'select' || isSearched.current !== v) {
            doSearch(v, eventType);
        }
    }

    const clear = e => {
        e?.stopPropagation();
        handleSearch("", "clear")
    };

    const handleDebounceFn = v => {
        if(autoSuggestion?.autoGet === false){
            if(v?.length >= 3){
                getAutoSuggestionData({[autoSuggestion?.searchFieldName] : v})
            }
        }
    }

    const debounceSearch = useCallback(debounce(handleDebounceFn, 1000), []);

    /** Reset autoSuggestion data for autoGet=false */
    useEffect(() => {
        if(value === "" && autoSuggestion?.autoGet === false){
            resetAutoSuggestionData() 
        }
    }, [value])

    return (
        <div className="rt--table-search">
            {
                !isMobile() && <label className="rt--search-label">{placeholder}</label>
            }
            
            <AutoComplete
                items={autoSuggestionItems}
                getPopupContainer={
                    isMobile() ? trigger => trigger.parentNode : () => document.getElementsByClassName("rt--portal-layout")[0]
                }
                disableFiltering={autoSuggestion?.autoGet === false} // if autoGet is false, then data is filtered from backend
                onChange={handleChange}
                onSelect={v => handleSearch(v, "select")}
                value={value}
                showId={showId}
            >
                <Input
                    type='search'
                    placeholder={ isMobile() ? placeholder: t("backoffice.common.search") }
                    prefix={(<Icon name="search" />)}
                    suffix={value ? (<Icon name="close" onClick={clear} />) : undefined}
                    value={value}
                    onPressEnter={e => {
                        const inputValue = e?.target?.value ?? "";
                        if(inputValue && inputValue.length > 1){
                            handleSearch(inputValue, "enter");
                        }
                    }}
                    onChange={e => debounceSearch(e.target.value)}
                />
            </AutoComplete>
        </div>
    )
}

/** Search propTypes
    * PropTypes
*/
AutocompleteSearch.propTypes = searchType;

export default AutocompleteSearch;

