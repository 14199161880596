import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from "components/common/table";

import { tableColumnsCreator } from "utils/tableColumnsCreator";

import { getTableColumns } from './columns';
import { LEADERBOARD_DATA_TYPE, LEADERBOARD_TYPE } from '../constants';

import useFormat from 'hooks/useFormat';
import useAccess from 'core/hooks/useAccess';

import leaderType from "types/dashboard/leader.type";

import { useTranslation } from 'core/hooks/useTranslation';

const LeaderboardDesktopView = ({
    leaders,
    isLoading,
    type,
    currencyCode,
    dataType,
    countText
}) => {

    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const { hasAccess } = useAccess();

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                type,
                dataType,
                formatAmount,
                currencyCode,
                countText,
                t,
                hasAccess
            },
            t
        });
    }, [type, currencyCode, dataType, countText, t])

    return (
        <Table
            loading={isLoading}
            data={leaders}
            columns={mainTableColumns}
            disableFullView={true}
            noPagination={true}
        />
    );
}

/** LeaderboardDesktopView propTypes*/
LeaderboardDesktopView.propTypes = {
    /** Redux state property, top agents */
    leaders: PropTypes.arrayOf(leaderType),
    /** Redux state property, top agents loading state */
    isLoading: PropTypes.bool,
    /** Type */
    type: PropTypes.oneOf([
        LEADERBOARD_TYPE.FINANCIAL,
        LEADERBOARD_TYPE.OPERATIONAL,
    ]),
    /** Data Type */
    dataType: PropTypes.oneOf(Object.values(LEADERBOARD_DATA_TYPE)),
    /** Currency code */
    currencyCode: PropTypes.string,
    /** Count Column Title */
    countText: PropTypes.string
};

export default LeaderboardDesktopView;