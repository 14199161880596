import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Checkbox } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import TextAreaInput from 'components/common/textAreaInput';
import Input from 'components/common/input';

import { createProject, getProjects } from "store/actions/portal/projects/projects.action";
import { getPlatforms } from "store/actions/portal/platform/platform.action";
import { getSystemAvailableCurrencies } from "store/actions/portal/settings/systemCurrencies/systemCurrencies.action";

import { hasProjectEditPageAccess } from 'utils/pageAccess';

import { DESCRIPTION_REGEX, NAME_REGEX } from 'constants/regex.constants';

import ROUTES from "core/constants/routes/routes";

import platformType from 'types/platform/platform.type';
import currencyType from "types/currency/currency.type";

import { isMobile } from 'utils/common';

import { authStore, globalCompanyIdSelector, useAuthSelector } from 'core/stores/authStore';
import { companiesSelector, useGlobalSelector } from 'core/stores/globalStore';
import usePermissions from 'core/hooks/usePermission';

/** Project Creating Popup Component */
const ProjectCreateComponent = ({
    isSaving,
    createProject,
    getProjects,
    platforms,
    getPlatforms,
    systemAvailableCurrencies,
    getSystemAvailableCurrencies,
    isAvailableCurrenciesLoading,
    onClose
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);
    const companies = useGlobalSelector(companiesSelector);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const navigate = useNavigate();

    const [selectedPlatform, setSelectedPlatform] = useState(null);

    /** Is current compnay test */
    const isCurrentCompanyTest = companies.find(p => p.id === globalCompanyId)?.isTesting ?? false;

    /** Load system currencies */
    useEffect(() => {
        getSystemAvailableCurrencies();
    }, [])

    /** Load Platforms */
    useEffect(() => {
        getPlatforms();
    }, [])


    /** Fires when form submitted
       * @function
       * @memberOf ProjectCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {

                createProject(
                    {
                        ...data
                    },
                    record => {
                        if (hasProjectEditPageAccess(permissionUtils)) {
                            authStore.changeProject({
                                companyId: globalCompanyId,
                                projectId: record.id
                            }).then(() => {
                                navigate(`${ROUTES.PROJECTS_EDIT}/${record.id}?name=${record.name}`);
                            })
                        } else {
                            getProjects();
                            onClose();
                        }
                    },
                    globalCompanyId
                );
            }).catch(ex => {
                console.log(ex)
            })
    }

    const platform = platforms.find(p => p.id === selectedPlatform);

    const gateWays = platform ? platform.gateways : [];

    useEffect(() => {
        setFieldsValue({ gatewayId: undefined })
    }, [platform])

    return (
        <Modal
            title={t('backoffice.projects.createProject')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    name: "",
                    isTesting: isCurrentCompanyTest,
                    isCommissionEnabled: true,
                    currencyCodes: []
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                maxLength={30}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.projects.description')}
                            name="description"
                            rules={[
                                { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className="rt--general-form-item"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                        >
                            <TextAreaInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.description')}`}
                                maxLength={150}
                                rows={4} />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.currencies')} *`}
                            name="currencyCodes"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                        >
                            <Select
                                options={
                                    systemAvailableCurrencies
                                        .map(item => (
                                            { value: item.code.toUpperCase(), text: item.code.toUpperCase() + " - " + item.name }
                                        ))
                                }
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                loading={isAvailableCurrenciesLoading}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.currencies')}`}
                                search={true}
                                isMultiple={true}
                                maxMultipleSelect={10}
                                showSelectAllButton={false}
                            />
                        </Form.Item>
                    </Col>
                   
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.platform')} *`}
                            name="platformId"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                options={
                                    platforms.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.platform')}`}
                                onChange={e => setSelectedPlatform(e)}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.projects.gateway')} *`}
                            name="gatewayId"
                            rules={[
                                { required: Boolean(platform), whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            className={!platform ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={
                                    gateWays.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.gateway')}`}
                                disabled={!platform}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mb-8">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isCommissionEnabled"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.projects.isCommissionEnabled')}</span>
                        </div>
                    </Col>

                    <Col span={24}>
                        <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                            <Form.Item
                                className='rt--form-item-inline rt--form-item-without-margin'
                                name="isTesting"
                                valuePropName='checked'
                            >
                                <Checkbox />
                            </Form.Item>
                            <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.projects.isTestProject')}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** ProjectCreateComponent propTypes
    * PropTypes
*/
ProjectCreateComponent.propTypes = {
    /** Redux state property, is true when creating project request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to get projects */
    getProjects: PropTypes.func,
    /** Redux action to create project */
    createProject: PropTypes.func,
    /** Redux action to get platforms */
    getPlatforms: PropTypes.func,
    /** Redux state property, platforms */
    platforms: PropTypes.arrayOf(platformType),
    /** Redux action to get system available currencies */
    getSystemAvailableCurrencies: PropTypes.func,
    /** Redux state property, represents the array of system available currencies  */
    systemAvailableCurrencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, is true when loading available currencies */
    isAvailableCurrenciesLoading: PropTypes.bool,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createProject: (project, onSuccess, companyId) => {
            dispatch(createProject(project, onSuccess, companyId));
        },

        getPlatforms: () => {
            dispatch(getPlatforms())
        },

        getProjects: () => {
            dispatch(getProjects());
        },

        getSystemAvailableCurrencies: () => {
            dispatch(getSystemAvailableCurrencies())
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        platforms: state.platforms.platforms,
        isAvailableCurrenciesLoading: state.systemCurrencies.isAvailableLoading,
        systemAvailableCurrencies: state.systemCurrencies.systemAvailableCurrencies
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreateComponent)