import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { message } from "antd";

import { isResponseSuccess } from "utils/request";

import {
    SET_INVOICES_ACTION_BEFORE,
    SET_INVOICES_ACTION_FINISH,
    SET_INVOICES_SAVE_ACTION_BEFORE,
    SET_INVOICES_SAVE_ACTION_FINISH,
    SET_INVOICES_AVAILABLE_PAYMENT_METHODS,
    SET_INVOICES_PAYMENT_TRANSLATIONS,
    SET_INVOICE_GENERATION_TYPE
} from "../../../../actionTypes";

import { getPendingInvoices } from "./pending.action";
import { getInvoicesHistory } from "./history.action";
import { getInvoicesTotals } from "./totals.action";

import { INVOICE_HISTORY_TYPE } from "constants/invoice.constants";

export const setInvoicesActionBefore = () => ({
    type: SET_INVOICES_ACTION_BEFORE,
});

export const setInvoicesActionFinished = () => ({
    type: SET_INVOICES_ACTION_FINISH
});

const setInvoicesSaveActionBefore = () => ({
    type: SET_INVOICES_SAVE_ACTION_BEFORE,
});

const setInvoicesSaveActionFinished = () => ({
    type: SET_INVOICES_SAVE_ACTION_FINISH
});

const setAvailablePayments = paymentMethods => ({
    type: SET_INVOICES_AVAILABLE_PAYMENT_METHODS,
    payload: { paymentMethods }
});

const setPaymentTranslations = (translations, paymentId) => ({
    type: SET_INVOICES_PAYMENT_TRANSLATIONS,
    payload: { translations, paymentId }
});

const setProjectInvoiceGenerationType = invoiceGenerationType => ({
    type: SET_INVOICE_GENERATION_TYPE,
    payload: { invoiceGenerationType }
});

export const createInvoice = (invoice, onSuccess) => {
    return (dispatch) => {
        dispatch(setInvoicesSaveActionBefore());
        const formData = new FormData();

        Object.keys(invoice).forEach((key) => {
            if(invoice[key]){
                formData.append(key, invoice[key]);
            }
        });

        return axios({
            url: ApiUrls.CREATE_INVOICE,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data }) => {
                dispatch(setInvoicesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getPendingInvoices());
                    dispatch(getInvoicesTotals(INVOICE_HISTORY_TYPE.PENDING));
                    onSuccess && onSuccess();

                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setInvoicesSaveActionFinished());
            });
    };
};

export const getAvailablePayments = (affiliateId, currencyCode, onSuccess) => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_INVOICE_AVAILABLE_PAYMENTS,
            method: Methods.GET,
            params: {
                affiliateId, currencyCode
            },
        })
            .then(({ data: { value: paymentMethods } }) => {
                dispatch(setAvailablePayments(paymentMethods));

                onSuccess && onSuccess(paymentMethods)
            })
            .catch((ex) => {
                console.log(ex)
            });
    }
}

export const getPaymentTranslations = paymentId => {
    return dispatch => {

        return axios({
            url: ApiUrls.GET_SYSTEM_PAYMENT_METHOD_FORM_TRANSLATIONS,
            method: Methods.GET,
            params: {
                id: paymentId
            },
        })
            .then(({ data }) => {
                dispatch(setPaymentTranslations(data.value.translations, paymentId));
            })
            .catch((ex) => {
                console.log(ex)
            });
    }
}

export const editInvoice = (invoice, onSuccess) => {
    return (dispatch) => {
        dispatch(setInvoicesSaveActionBefore());
        const formData = new FormData();

        Object.keys(invoice).forEach((key) => {
            if(invoice[key]){
                formData.append(key, invoice[key]);
            }
        });

        return axios({
            url: ApiUrls.SAVE_INVOICE_GENERAL_INFO,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data }) => {
                dispatch(setInvoicesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    dispatch(getPendingInvoices())
                    onSuccess && onSuccess();
                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setInvoicesSaveActionFinished());
            });
    };
};

export const rejectInvoice = (id, createdAt) => {
    return (dispatch, getState) => {
        dispatch(setInvoicesSaveActionBefore());

        return axios({
            url: ApiUrls.REJECT_INVOICE,
            method: Methods.POST,
            data: {
                id, createdAt
            }
        })
            .then(({ data }) => {
                dispatch(setInvoicesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    const isLastItemInPage = getState().invoices.pending.invoices.length === 1;
                    dispatch(getPendingInvoices(isLastItemInPage ? "first" : undefined));
                    dispatch(getInvoicesHistory());
                    dispatch(getInvoicesTotals(INVOICE_HISTORY_TYPE.PENDING));
                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setInvoicesSaveActionFinished());
            });
    };
};

export const approveInvoice = (data, onSuccess) => {
    return (dispatch, getState) => {
        dispatch(setInvoicesSaveActionBefore());

        return axios({
            url: ApiUrls.APPROVE_INVOICE,
            method: Methods.POST,
            data: {
                ...data
            }
        })
            .then(({ data }) => {
                dispatch(setInvoicesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    const isLastItemInPage = getState().invoices.pending.invoices.length === 1;
                    dispatch(getPendingInvoices(isLastItemInPage ? "first" : undefined));
                    dispatch(getInvoicesHistory());
                    dispatch(getInvoicesTotals(INVOICE_HISTORY_TYPE.PENDING));
                    dispatch(getInvoicesTotals(INVOICE_HISTORY_TYPE.HISTORY));
                    onSuccess && onSuccess();
                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setInvoicesSaveActionFinished());
            });
    };
};

export const exportInvoice = (id, createdAt, affiliateId) => {
    return (dispatch) => {
        dispatch(setInvoicesActionBefore());

        return axios({
            url: ApiUrls.EXPORT_INVOICE,
            method: Methods.GET,
            params: {
                id, createdAt
            },
            responseType: 'blob'
        })
            .then(({ data }) => {
                dispatch(setInvoicesActionFinished());
                const blob = new Blob([data], { type: "application/pdf" });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.target = "_blank";
                const fileName = `INV-${affiliateId}-${id}`;
                link.download = fileName + ".pdf";
                link.click();
            })
            .catch(() => {
                dispatch(setInvoicesActionFinished());
            });
    };
};

export const deleteInvoice = (id, createdAt) => {
    return (dispatch, getState) => {
        dispatch(setInvoicesSaveActionBefore());

        return axios({
            url: ApiUrls.DELETE_INVOICE,
            method: Methods.DELETE,
            data: {
                id, createdAt
            }
        })
            .then(({ data }) => {
                dispatch(setInvoicesSaveActionFinished());
                if (isResponseSuccess(data)) {
                    const isLastItemInPage = getState().invoices.pending.invoices.length === 1;
                    dispatch(getPendingInvoices(isLastItemInPage ? "first" : undefined));
                    dispatch(getInvoicesTotals(INVOICE_HISTORY_TYPE.PENDING));
                    dispatch(getInvoicesHistory());
                    message.success(data.message);
                }
            })
            .catch(() => {
                dispatch(setInvoicesSaveActionFinished());
            });
    };
};

export const getProjectInvoiceGenerationType = () => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_PROJECT_PAYOUT_SETTINGS,
            method: Methods.GET
        })
        .then(({data : {value: payoutSettings}}) => {
            dispatch(setProjectInvoiceGenerationType(payoutSettings.invoiceGenerationType))
        })
        .catch((ex) => {
           console.log(ex)
        })
    }
}