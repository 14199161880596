import React, { useState, memo } from "react";

import Modal from "core/ui-kit/modal";
import Form from "core/ui-kit/form";

import InvoiceCreateForm from "./form";

import { useTranslation } from "core/hooks/useTranslation";
import useFirstRender from "core/hooks/useFirstRender";

import { POPUP_SIZE } from "core/constants/common";

import showSuccess from "core/helpers/common/showSuccess";
import invoiceService from "core/services/apiServices/invoiceService";
import { FORM_FIELD_NAMES } from "./form/constants";


const InvoiceCreate = ({
    isVisible,
    onInvoiceCreate,
    onClose
}) => {

    const { t } = useTranslation();

    const [isSaving, setIsSaving] = useState(false);

    const [disabled, setDisabled] = useState(true)

    const [formInstance] = Form.useForm();

    const { resetFields } = formInstance;

    const handleFormFinish = formData => {
        const data = { ...formData };

        data[FORM_FIELD_NAMES.AMOUNTS].filter(item => item.currencyCode).forEach((item, index) => {
            data[`${FORM_FIELD_NAMES.AMOUNTS}[${index}][CurrencyCode]`] = item.currencyCode;
            data[`${FORM_FIELD_NAMES.AMOUNTS}[${index}][Amount]`] = item.amount;
        })

        delete data[FORM_FIELD_NAMES.AMOUNTS];
        setIsSaving(true);
        invoiceService.createInvoice(data)
            .then(({ message, value }) => {
                showSuccess(message);
                onInvoiceCreate(value);
            })
            .finally(() => setIsSaving(false))
    }

    const onOkButtonClick = () => {
        formInstance.submit()
    }

    useFirstRender({
        dependencies: [isVisible],
        afterFirstRenderCB: () => {
            if(!isVisible){
                resetFields();
            }
        }
    })

    return (
        <Modal 
            open={isVisible}
            title={t('backoffice.invoices.createInvoice')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            size={POPUP_SIZE.BIGEST}
            onCancel={onClose}
            onOk={onOkButtonClick}
            isLoading={isSaving}
            okButtonProps={{ disabled }}
        >
            <InvoiceCreateForm 
                onFinish={handleFormFinish}
                formInstance={formInstance}
                setDisabled={setDisabled}
            />
        </Modal>
    )
}

export default memo(InvoiceCreate);