import { useCallback } from 'react';

import useCurrentProject from 'core/hooks/useCurrentProject';

const useAccess = () => {

    const currentProject = useCurrentProject();

    const additionalAccesses = currentProject?.additionalAccesses ?? {}

    const hasAccess = useCallback(type => {

        return additionalAccesses[type] ?? false
    }, [additionalAccesses])

    return {
        hasAccess
    }
}

export default useAccess;