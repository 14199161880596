import React, {useEffect, useRef, useState} from 'react';
import Scroller from "./scroller";

// TODO: gap, children, padding

const Virtualized = ({
   className,
   data,
   itemHeight,
   renderItem,
   onScrollEnd,
}) => {
    const [startFrom, setStartFrom] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);

    const containerRef = useRef(null);

    const paddingStart = 2;
    const paddingEnd = 2;

    const contentHeight = data.length * itemHeight;

    const itemsInView = paddingStart + Math.ceil(clientHeight / itemHeight) + paddingEnd;
    const slicedData = data.slice(startFrom, startFrom + itemsInView);

    useEffect(() => {
        setClientHeight(containerRef.current.clientHeight);
    }, []);

    const handleScroll = ({target: {scrollTop}}) => {
        const start = Math.floor((scrollTop - paddingStart * itemHeight) / itemHeight);
        setStartFrom(start >= 0 ? start : 0);
    }

    return (
        <Scroller
            ref={containerRef}
            style={{overflow: 'auto', height: '100%'}}
            onScroll={handleScroll}
            onScrollEnd={onScrollEnd}
            offset={200}
        >
            <div
                className={className}
                style={{height: contentHeight, paddingTop: startFrom * itemHeight}}
            >
                {renderItem(slicedData)}
            </div>
        </Scroller>
    );
};

export default Virtualized;
