import React, { memo, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "core/hooks/useTranslation";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Spin } from "antd";

import PlayerCount from "../playerCount";

import { isMobile } from "utils/common";

import useOperationalPieChart from "../../hooks/useOperationalPieChart";

import { OPERATIONAL_ENTITY_TYPE } from "../../constants";

import { getOperationsPieChartOptions } from "../../helpers";

const getTitle = (entityType, t) => {
  return entityType === OPERATIONAL_ENTITY_TYPE.AFFILIATE
    ? t("backoffice.dashboard.totalAffiliateCount")
    : entityType === OPERATIONAL_ENTITY_TYPE.MEDIA
    ? t("backoffice.dashboard.totalMediaCount")
    : entityType === OPERATIONAL_ENTITY_TYPE.CAMPAIGN
    ? t("backoffice.dashboard.totalCampaignCount")
    : "";
};

const PieChart = ({
  entityType,
  globalProjectId,
  updateLastUpdatedDate,
  isActive,
  affiliateId,
}) => {
  const { t } = useTranslation();

  const [inactiveStates, setInactiveStates] = useState([]);

  const { chartData, isLoading } = useOperationalPieChart(
        entityType,
        globalProjectId,
        updateLastUpdatedDate,
        isActive,
        affiliateId
  );

  const chartOptions = useMemo(() => {
        return getOperationsPieChartOptions(
            chartData,
            entityType,
            inactiveStates,
            (name, isVisible) => {
                if (!isVisible) {
                    setInactiveStates((prevState) => prevState.filter((s) => s !== name));
                } else {
                    setInactiveStates((prevState) => prevState.concat([name]));
                }
            },
            t
        );
  }, [entityType, inactiveStates, chartData, t]);

  return (
        <div 
            className={
                "rt--chart-operational-pie rt--chart-section" + 
                (entityType === OPERATIONAL_ENTITY_TYPE.MEDIA ? ' rt--chart-operational-pie-media' : '') + 
                (entityType === OPERATIONAL_ENTITY_TYPE.CAMPAIGN ? ' rt--chart-operational-pie-campaign' : '')}
        >
            <div
                className={
                    "rt--flex rt--flex-col " +
                    (isMobile() ? " rt--pl-16 rt--pt-16" : "") +
                    `rt--chart-operational-pie-${entityType}`
                }
            >
                    <span
                        className={
                            "rt--chart-section-title rt--font-extra-bold " +
                            (isMobile() ? " rt--font-big " : " rt--font-big rt--mb-8")
                        }
                    >
                        {!isMobile() ? getTitle(entityType, t) : null}
                    </span>
            </div>
            <div
                className={
                    "rt--chart-operational-pie-block" +
                    (isMobile() ? " rt--flex rt--flex-col" : "")
                }
            >
                {
                    isMobile() ? (
                        <div className="rt--mb-16">
                            <span className="rt--chart-section-title rt--font-extra-bold rt--font-prebigest">
                                {getTitle(entityType, t)}
                            </span>
                        </div>
                    ) : null
                }

                {isLoading ? (
                    <Spin spinning={isLoading} />
                ) : entityType !== OPERATIONAL_ENTITY_TYPE.PLAYER ? (
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                ) : (
                    <PlayerCount count={chartData?.count ?? 0} entityType={entityType}/>
                )}
            </div>
        </div>
  );
};

PieChart.propTypes = {
    entityType: PropTypes.oneOf(Object.values(OPERATIONAL_ENTITY_TYPE)),
    globalProjectId: PropTypes.string,
    updateLastUpdatedDate: PropTypes.func,
    isActive: PropTypes.bool,
};

export default memo(PieChart);
