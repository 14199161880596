import WithCopyButton from "./withCopyButton";
import Flag from "./flag";
import DateTime from "./dateTime";
import Numeric from "./numeric";
import Amount from "./amount";
import List from "./list";
import Ellipsis from "./ellipsis";

const Cell = {};

Cell.WithCopyButton = WithCopyButton;
Cell.Flag = Flag;
Cell.DateTime = DateTime;
Cell.Numeric = Numeric;
Cell.Amount = Amount;
Cell.List = List;
Cell.Ellipsis = Ellipsis;

export default Cell;