const ROUTES = {
    ROOT: "/",
    
    // Login
    LOGIN: "/login",
    RESET_PASSWORD: "/reset",
    SET_PASSWORD: "/setPassword",
    FORGOT_PASSWORD: "/forgotPassword",
    REGISTER: "/register",
    ACTIVATE: "/activate",
    UNSUBSCRIBE: "/unsubscribe",

    // Profile
    PROFILE: "/profile",

    //Dashboard
    DASHBOARD_FINANCIAL: "/dashboard/financial",
    DASHBOARD_OPERATIONAL: "/dashboard/operational",
    DASHBOARD_MARKETING: "/dashboard/marketing",

    //Companies
    COMPANIES: "/companies",
    COMPANIES_EDIT: "/companies/edit",

    //Projects
    PROJECTS: "/projects",
    PROJECTS_EDIT: "/projects/edit",

    //My Account
    MY_ACCOUNT: "/myAccount",

    //Affiliates
    AFFILIATES: "/affiliates/accounts",
    AFFILIATES_EDIT: "/affiliates/accounts/edit",
    AFFILIATE_GROUPS: "/affiliates/groups",
    AFFILIATE_GROUPS_EDIT: "/affiliates/groups/edit",

    //Players
    PLAYERS: "/players",
    PLAYERS_BTAG_CHANGE_HISTORY: "/playerBtagChangeHistory",

    // Payment
    INVOICES: "/payment/invoices",
    BALANCE_HISTORY: "/payment/balanceHistory",
    CALCULATION_APPROVALS: "/portal/approveCommission",

    // Marketing
    CAMPAIGNS: "/marketings/campaigns",
    CAMPAIGNS_EDIT: "/marketings/campaigns/edit",
    MEDIA_TOOLS: "/marketings/medias",

    // Promo codes
    PROMO_CODES: "/marketings/promoCodes",

    // Reports
    MARKETING_REPORTS: "/reports/marketing",
    PERFORMANCE_REPORTS: "/reports/performance",
    COMMISSION_CALCULATIONS: "/reports/commissionCalculations",
    AGGREGATED_DATA: "/reports/aggregatedData",

    // User Management
    USERS: "/users",
    USERS_EDIT: "/users/edit",

    // Access Management
    ACCESS_MANAGERS: "/accessManagers",
    ACCESS_MANAGERS_EDIT: "/accessManagers/edit",

    // Affiliate Management
    AFFILIATE_MANAGERS: "/affiliateManagers",
    AFFILIATE_MANAGERS_EDIT: "/affiliateManagers/edit",

    //Additional Access
    ADDITIONAL_ACCESS: "/additionalAccess",

    // Permissions
    PERMISSIONS: "/permissions",

    // Permission Groups
    PERMISSION_GROUPS: "/permissionGroups",
    PERMISSION_GROUPS_EDIT: "/permissionGroups/edit",

    // Permission Requests
    PERMISSION_REQUESTS: "/permissionRequests",

    // Notifications
    SYSTEM_NOTIFICATIONS: "/systemNotifications",
    SYSTEM_NOTIFICATIONS_EDIT: "/systemNotifications/edit",

    // Newsletter
    NEWSLETTER: "/newsletter",

    // Postback
    POSTBACKS: "/postbacks",
    POSTBACKS_EDIT: "/postbacks/edit",
    POSTBACK_LOGS: "/postbackLogs",
   

    // CMS
    TRANSLATIONS_PROMO: "/cms/promoTranslations",
    TRANSLATIONS_BO: "/cms/BOTranslations",

    // Settings
    SETTINGS_CURRENCIES: "/settings/currencies",
    SETTINGS_LANGUAGES: "/settings/languages",
    SETTINGS_PAYMENTS: "/settings/payments",
    SETTINGS_PAYMENTS_EDIT: "/settings/payments/edit",

    // User Logs
    USER_LOGS: "/userLogs",

    // Developer Space
    DEVELOPER_ERRORS: "/developer/errors",
    DEVELOPER_REQUESTS: "/developer/requests",
    DEVELOPER_JOBS: "/developer/jobs",
    DEVELOPER_MONITORING: "/developer/monitoring",
    DEVELOPER_PLATFORM_TEST: "/developer/platformTest",
    DEVELOPER_GENERATIONS: "/developer/generations",
    DEVELOPER_POST_DEPLOYMENT_ACTIONS: "/developer/postDeploymentActions",
    DEVELOPER_DB_CONNECTIONS: "/developer/dbConnections",

    // Customize
    CUSTOMIZE: "/customize",
    PROMO_CUSTOMIZE: "/customize/promo",
    NEWSLETTER_CUSTOMIZE: "/customize/newsletter",

    // Guide
    GUIDE: "/guide",

}

export default ROUTES;