import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Form, Radio, Divider, Tooltip, Checkbox } from 'antd';

import { useTranslation } from "core/hooks/useTranslation";

import NumericInput from 'components/common/numericInput';
import NumericRanges from 'components/common/numericRanges';
import Icon from 'components/common/icon';
import Select from 'components/common/select';

import { COMMISSION_PLAN_STRUCTURE, CPA_PERIOD } from 'constants/commission.constants';
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

import { isMobile, numberTransform } from 'utils/common';

import usePermissions from 'core/hooks/usePermission';

/** Commission Plan Creating Popup CPA Component */
const CPA = ({
    formInstance,
    editMode
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [structure, setStructure] = useState(editMode?.structure ?? COMMISSION_PLAN_STRUCTURE.FLAT);

    const hasEditPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, editMode ? PERMISSION_ACTION.MODIFY : PERMISSION_ACTION.CREATE);

    const canEdit = hasEditPermission && (!editMode || !editMode.isAffiliate);

    return (
        <Row gutter={[16, 0]}>
            <Col span={24}>
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={
                                <div className='rt--flex rt--align-center'>
                                    <span>
                                        {
                                            `${t('backoffice.commissionplans.cpaPeriod')} *`
                                        }
                                    </span>
                                    <Tooltip
                                        title={t('backoffice.commissionplans.cpaPeriodTooltip')}
                                        trigger={["hover", "click"]}
                                        placement="top"
                                        enableMobile={true}
                                    >
                                        <Icon name="info" size={16} className="rt--ml-4" />
                                    </Tooltip>
                                </div>
                            } 
                            name="period"
                            className={Boolean(editMode) || !canEdit ? " rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={[
                                    { value: CPA_PERIOD.ALWAYS, text: t('backoffice.commissionplans.always') },
                                    { value: CPA_PERIOD._1W, text: t('backoffice.commissionplans._1w') },
                                    { value: CPA_PERIOD._2W, text: t('backoffice.commissionplans._2w') },
                                    { value: CPA_PERIOD._1M, text: t('backoffice.commissionplans._1m') },
                                    { value: CPA_PERIOD._2M, text: t('backoffice.commissionplans._2m') },
                                    { value: CPA_PERIOD._3M, text: t('backoffice.commissionplans._3m') },
                                    { value: CPA_PERIOD._6M, text: t('backoffice.commissionplans._6m') },
                                    { value: CPA_PERIOD._1Y, text: t('backoffice.commissionplans._1y') },
                                    { value: CPA_PERIOD._2Y, text: t('backoffice.commissionplans._2y') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.cpaPeriod')}`}
                                disabled={Boolean(editMode) || !canEdit}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.minDepositAmount')} *`}
                            name="minDepositAmount"
                            rules={[
                                { type: "number", message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                () => ({
                                    validator(rule, value) {
                                        if (value === "" || value === null || value === undefined) {
                                            return Promise.reject(t('backoffice.validation.fieldRequired'))
                                        }
                                        if (Number(value) < 0.000001) {
                                            return Promise.reject(t('backoffice.validation.mustBeMore').replace("%X%", 0.000001))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            validateFirst
                            className={'rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minDepositAmount')}`}
                        >
                            <NumericInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minDepositAmount')}`}
                                disabled={!canEdit}
                                maxLength={8}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={t('backoffice.commissionplans.minWagerAmount')}
                            name="minTurnoverAmount"
                            rules={[
                                { type: "number", message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                () => ({
                                    validator(rule, value) {
                                        if (value === "" || value === null || value === undefined) {
                                            return Promise.resolve()
                                        }
                                        if (Number(value) < 0) {
                                            return Promise.reject(t('backoffice.validation.mustBeMore').replace("%X%", 0))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            validateFirst
                            className={'rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minWagerAmount')}`}
                        >
                            <NumericInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.minWagerAmount')}`}
                                disabled={!canEdit}
                                maxLength={8}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
            
            <Col span={24}>
                <div className={"rt--flex-inline rt--align-center rt--form-item-checkbox " + (isMobile() ? "rt--mb-12" : "rt--mb-16")} >
                    <Form.Item
                        className={'rt--form-item-inline rt--form-item-without-margin' + (!canEdit ? " rt--form-item-disabled" : "")}
                        name="ftdOnly"
                        valuePropName='checked'
                    >
                        <Checkbox 
                            disabled={!canEdit} 
                            onChange={e => {
                                
                                if(!e.target.value && formInstance.getFieldValue("structure") === COMMISSION_PLAN_STRUCTURE.QFTD_TIERED){
                                    formInstance.setFieldsValue({
                                        "structure": COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED
                                    })
                                }
                            }}
                        />
                    </Form.Item>
                    <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.commissionplans.firstTimeDeposit')}</span>
                </div>
            </Col>
            <Col span={24}>
                <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                        return (
                            <Form.Item
                                label={t("backoffice.commissionplans.structure")}
                                name="structure"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                className={"rt--form-item-radio rt--form-item-without-margin" + (Boolean(editMode) || !canEdit ? " rt--form-item-disabled" : "")}
                            >
                                <Radio.Group
                                    options={[
                                        { label: t('backoffice.commissionplans.flat'), value: COMMISSION_PLAN_STRUCTURE.FLAT },
                                        { label: t('backoffice.commissionplans.playerTiered'), value: COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED },
                                    ].concat(
                                        getFieldValue("ftdOnly") === true ? [
                                            { label: t('backoffice.commissionplans.qftdTiered'), value: COMMISSION_PLAN_STRUCTURE.QFTD_TIERED },
                                        ] : []
                                    )}
                                    onChange={e => setStructure(e.target.value)}
                                    disabled={Boolean(editMode) || !canEdit}
                                />
                            </Form.Item>
                        )
                    }}
                </Form.Item>
            </Col>
            <Divider />
            {
                structure === COMMISSION_PLAN_STRUCTURE.FLAT ? (
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.amount')} *`}
                            name="amount"
                            rules={[
                                { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                () => ({
                                    validator(rule, value) {
                                        if (value === "" || value === null || value === undefined) {
                                            return Promise.reject(t('backoffice.validation.fieldRequired'))
                                        }
                                        if (Number(value) < 0.000001) {
                                            return Promise.reject(t('backoffice.validation.mustBeMore').replace("%X%", 0.000001))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            validateFirst
                            className={'rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.amount')}`}
                        >
                            <NumericInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.amount')}`}
                                disabled={!canEdit}
                                maxLength={8}
                            />
                        </Form.Item>
                    </Col>
                ) : structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED || structure === COMMISSION_PLAN_STRUCTURE.QFTD_TIERED ? (
                    <Col span={24}>
                        <NumericRanges
                            label={(
                                <div className="rt--flex rt--align-center">
                                    {t('backoffice.commissionplans.playerRanges')}
                                    <Tooltip
                                        title={t(`backoffice.commissionplans.playerRangesTooltip`)}
                                        trigger={["hover", "click"]}
                                        placement="bottomLeft"
                                        enableMobile={true}
                                    >
                                        <Icon name='info' size={14} className="rt--ml-6" />
                                    </Tooltip>
                                </div>
                            )}
                            formInstance={formInstance}
                            isPercent={false}
                            fieldName="range"
                            editable={canEdit}
                            disabled={!canEdit}
                            minRangesCount={2}
                            isInteger={true}
                        />
                    </Col>
                ) : null
            }
        </Row>
    )
}

/** CPA propTypes
    * PropTypes
*/
CPA.propTypes = {
    formInstance: PropTypes.object,
    editMode: PropTypes.shape({
        structure: PropTypes.oneOf(Object.values(COMMISSION_PLAN_STRUCTURE)),
        isAffiliate: PropTypes.bool
    })
}

export default CPA;