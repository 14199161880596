import React, { Fragment, memo, useMemo, useState } from "react";

import useCurrencyConversion from "hooks/useCurrencyConvertion";
import useFormat from "hooks/useFormat";

import Form from "core/ui-kit/form";
import { Row, Col } from "core/ui-kit/layout";
import Icon from "core/ui-kit/icon";
import Button from "core/ui-kit/button";

import useDevice from "core/hooks/useDevice";
import { useTranslation } from "core/hooks/useTranslation";
import { FORM_FIELD_NAMES } from "../constants";

import FormRulesBuilder from "core/helpers/common/formRulesBuilder";
import { useEffect } from "react";
import { Divider } from "antd";


const Wallets = ({
    availableWallets,
    onChange=Function.prototype,
    formInstance,
    currencyCode,
    affiliateId
}) => {

    const { t } = useTranslation();

    const { convertCurrency, getConversionRate } = useCurrencyConversion();

    const { formatAmount } = useFormat();

    const { isMobile } = useDevice();

    const [selected, setSelected] = useState([]);

    const [converted, setConverted] = useState({})

    const disabled = !affiliateId;

    const walletOptions = useMemo(() => {
        return Object.keys(availableWallets).map(currencyCode => ({ 
            value: currencyCode.toUpperCase(), 
            text: `${currencyCode.toUpperCase()} (${availableWallets[currencyCode]})`,
            disabled: availableWallets[currencyCode] <= 0 ||selected.includes(currencyCode)
        }))
    }, [availableWallets, selected])


    const handleWalletCurrencyChange = (value, index) => {

        const currentValues = formInstance.getFieldValue(FORM_FIELD_NAMES.AMOUNTS) || [];
        currentValues[index] = { ...currentValues[index], amount: String(availableWallets[value.toLowerCase()]) };

        setSelected(currentValues.filter(c => c.currencyCode !== null).map(c => c.currencyCode.toLowerCase()))

        formInstance.setFieldsValue({ [FORM_FIELD_NAMES.AMOUNTS] : currentValues });
        setTimeout(() => {
            const fieldPath = [FORM_FIELD_NAMES.AMOUNTS, index, "amount"]
            formInstance.validateFields([fieldPath]);
        }, 100)
        handleListChange()
    }

    const handleWalletAmoutChange = () => {
        handleListChange()
    }

    const handleFieldRemove = (field, index, remove) => {
        remove(field.name);
        const currentValues = formInstance.getFieldValue(FORM_FIELD_NAMES.AMOUNTS) || [];
        setSelected(currentValues.filter(c => c.currencyCode !== null).map(c => c.currencyCode.toLowerCase()))
        handleListChange();
    }

    const handleFieldAdd = (add) => {
        add({currencyCode : null, amount: ""});
        handleListChange()
    }


    const handleListChange = () => {
        const currentValues = formInstance.getFieldValue(FORM_FIELD_NAMES.AMOUNTS) || [];
        const converted = {};
        currentValues.forEach(item => {
            if(item.currencyCode){
                const itemCurrencyCode = item.currencyCode.toLowerCase();
                
                if(!currencyCode){
                    converted[itemCurrencyCode] = {
                        value: 0,
                        formated: "-",
                        rate: null
                    }
                } else if(item.amount === ""){
                    converted[itemCurrencyCode] = {
                        value: 0,
                        formated: "-",
                        rate: getConversionRate(itemCurrencyCode, currencyCode).toFixed(2)
                    }
                } else {
                    converted[itemCurrencyCode] = {
                        value: convertCurrency(Number(item.amount), item.currencyCode, currencyCode),
                        formated: formatAmount(convertCurrency(Number(item.amount), item.currencyCode, currencyCode), currencyCode),
                        rate: getConversionRate(itemCurrencyCode, currencyCode).toFixed(2)
                    }
                }
            }
        })

        setConverted(converted)

        onChange(converted)
    }

    const showLabel = index => isMobile || index === 0

    useEffect(() => {
        handleListChange()
    }, [currencyCode])

    useEffect(() => {
        setSelected([]);
        setConverted({})
        setTimeout(handleListChange, 0)
    }, [affiliateId])

    const renderWallets = (fields, { add, remove }) => {
        return (
            <div>
                {
                    fields.map((field, index) => (
                        <div>
                            <div className={isMobile ? "rt--flex" : ""} key={index}>
                                <Row gutter={[ 16, 0 ]}>
                                    <Col xs={24} xl={8}>
                                        <Form.Select
                                            label={ showLabel(index) && t("backoffice.invoices.selectedWallet")}
                                            name={[index, "currencyCode"]} 
                                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.invoices.wallet")}`}
                                            rules={
                                                new FormRulesBuilder(t).required({whitespace: false}).build()
                                            }
                                            onChange={value => handleWalletCurrencyChange(value, index)}
                                            options={walletOptions}
                                            disabled={disabled}
                                        />
                                    </Col>
                                    <Col xs={24} xl={8}>
                                        <Form.Number
                                            label={ showLabel(index) && t("backoffice.invoices.amount")}
                                            name={[index, "amount"]}
                                            placeholder={`${t("backoffice.common.enter")} ${t("backoffice.invoices.amount")}`}
                                            rules={
                                                new FormRulesBuilder(t).required({whitespace: true}).maxNumber(availableWallets[selected[index]]).build()
                                            }
                                            onChange={value => handleWalletAmoutChange(value, index)}
                                            disabled={disabled}
                                        />   
                                    </Col>
                                    <Col xs={isMobile ? 24 : 20} xl={7}>
                                        {
                                            !isMobile && (
                                                <div className='rt--invoice-convert-arrow'>
                                                    <Icon name="right" size={16} />
                                                </div>
                                            )
                                        }
                                        
                                        <Form.Input
                                            label={ showLabel(index) && t("backoffice.invoices.converted")}
                                            readOnly={true}
                                            value={converted[selected[index]]?.formated ?? "-"}
                                            className="rt--invoice-conversion"
                                            help={(
                                                <Fragment>
                                                    <i className="rt--font-small rt--text-light rt--pr-4">{t("backoffice.currencies.rate")}:</i>
                                                    <i className="rt--font-small rt--text-secondary">{converted[selected[index]]?.rate ?? "-"}</i>
                                                </Fragment>
                                            )}
                                        />
                                    </Col>
                                    {
                                        ( !isMobile && !disabled ) && (
                                            <Col xs={4} sm={4} xl={1}>
                                                {
                                                    fields.length > 1 && (
                                                        <Form.Item
                                                            label={showLabel(index) && " "}
                                                        >
                                                            <Icon className="rt--cursor-pointer" name="close" onClick={() => handleFieldRemove(field, index, remove)}/>
                                                        </Form.Item>   
                                                    )
                                                }
                                            </Col>
                                        )
                                    }
                                </Row>
                                
                                {
                                    (isMobile && !disabled) && (
                                        <div>
                                            {
                                                fields.length > 1 && (
                                                    <Icon className="rt--ml-8 rt--ns rt--invoice-convert-close" size={24} name="close" onClick={() => handleFieldRemove(field, index, remove)}/>  
                                                )
                                            }
                                        </div>
                                    )
                                }
                                
                            </div>
                            { isMobile && <Divider />}
                        </div>
                    ))
                }
                <Form.Item>
                    <Button
                        className="rt--button rt--button-secondary"
                        onClick={() => handleFieldAdd(add)}
                        disabled={fields.length === Object.keys(availableWallets).length || disabled}
                    >
                        { t("backoffice.invoices.addWallet") }
                    </Button>
                </Form.Item>
            </div>
        )
    }

    return (
        <Row gutter={[16, 0]}>
            <Col span={24}>
                <h4 className='rt--form-section-title rt--font-bold rt--font-biger rt--mb-16 rt--mt-8'>
                    { t('backoffice.invoices.wallets') }
                </h4>
            </Col>
            <Col span={24}>
                <Form.List
                    name={FORM_FIELD_NAMES.AMOUNTS}
                >
                    {
                        renderWallets
                    }
                </Form.List>    
            </Col>
        </Row>
    )
}

export default memo(Wallets);