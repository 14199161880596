import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate } from 'react-router-dom';

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';
import Input from 'components/common/input';

import RevShare from "../commissionForms/revShare";
import CPA from "../commissionForms/cpa";
import Fix from "../commissionForms/fix";

import { createCommissionPlan } from "store/actions/portal/commissionPlans/commissionPlans.action";
import { getProjectFeeSettings } from 'store/actions/portal/projects/feeSettings.action';

import useCurrencies from 'hooks/useCurrencies';

import { POPUP_SIZE } from 'constants/common.constants';
import { NAME_REGEX } from 'constants/regex.constants';
import { COMMISSION_PLAN_SOURCE, COMMISSION_PLAN_STRUCTURE, COMMISSION_PLAN_TYPE, CPA_PERIOD, INFINITY, MINUS_INFINITY } from 'constants/commission.constants';

import { isMobile } from 'utils/common';
import { addQueryStringParamsToUrl } from 'utils/queryString';

/** Commission Plan Creating Popup Component */
const CommissionPlanCreateComponent = ({
    isSaving,
    createCommissionPlan,
    onClose,
    getProjectFeeSettings,
    feeSettings,
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const [commissionType, setCommissionType] = useState(null);

    const [ currencies ] = useCurrencies();

    /** Reset values on commissin type change */
    useEffect(() => {
        if(commissionType === COMMISSION_PLAN_TYPE.REVSHARE){
            setFieldsValue({
                structure: COMMISSION_PLAN_STRUCTURE.FLAT,
                range: [{ from: MINUS_INFINITY, to: INFINITY, value: "" }],
                percent: "",
                products: []
            })
        } else if (commissionType === COMMISSION_PLAN_TYPE.CPA){
            setFieldsValue({
                structure: COMMISSION_PLAN_STRUCTURE.FLAT,
                range: [{ from: MINUS_INFINITY, to: INFINITY, value: "" }],
                minDepositAmount: "",
                minTurnoverAmount: "",
                period: CPA_PERIOD.ALWAYS,
                ftdOnly: false,
                amount: ""
            })
        } else if(commissionType === COMMISSION_PLAN_TYPE.FIX){
            setFieldsValue({
                amount: ""
            })
        }
    }, [commissionType])


    /** Fires when form submitted
       * @function
       * @memberOf CommissionPlanCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const commission = {
                    name: data.name,
                    currencyCode: data.currencyCode,
                    type: data.type
                }
                if(commission.type === COMMISSION_PLAN_TYPE.REVSHARE){
                    commission.revShareConfiguration = {};
                    commission.revShareConfiguration.source = data.source;
                    if(data.source !== COMMISSION_PLAN_SOURCE.NET_DEPOSIT){
                        commission.revShareConfiguration.products = data.products;
                    }
                    if(data.source === COMMISSION_PLAN_SOURCE.NGR || data.source === COMMISSION_PLAN_SOURCE.NET_DEPOSIT){
                        const feeType = data.feeType ?? [];
                        if(data.source === COMMISSION_PLAN_SOURCE.NGR){
                            commission.revShareConfiguration.applyOtherCosts = feeType.includes("applyOtherCosts");
                        }
                        commission.revShareConfiguration.applyDepositFee = feeType.includes("applyDepositFee");
                    }

                    commission.revShareConfiguration.structure = data.structure;

                    if(commission.revShareConfiguration.structure === COMMISSION_PLAN_STRUCTURE.FLAT){
                        commission.revShareConfiguration.percent = data.percent;
                    } else if(
                        commission.revShareConfiguration.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED ||
                        commission.revShareConfiguration.structure === COMMISSION_PLAN_STRUCTURE.PROGRESSIVE ||
                        commission.revShareConfiguration.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_LIFETIME
                    ){
                        commission.revShareConfiguration.range = data.range.map((r, i) => (
                            { 
                                ...r, 
                                to: r.to === INFINITY ? null : r.to, 
                                from: r.from === MINUS_INFINITY ? null : r.from 
                            }
                        ));
                    } 
                } else if(commission.type === COMMISSION_PLAN_TYPE.CPA){
                    commission.cpaConfiguration = {};
                    commission.cpaConfiguration.structure = data.structure;
                    commission.cpaConfiguration.minDepositAmount = data.minDepositAmount;
                    commission.cpaConfiguration.period = data.period;
                    commission.cpaConfiguration.ftdOnly = data.ftdOnly;
                    commission.cpaConfiguration.minTurnoverAmount = data.minTurnoverAmount;
                    if(commission.cpaConfiguration.structure === COMMISSION_PLAN_STRUCTURE.FLAT){
                        commission.cpaConfiguration.amount = data.amount;
                    } else if(
                        commission.cpaConfiguration.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED ||
                        commission.cpaConfiguration.structure === COMMISSION_PLAN_STRUCTURE.QFTD_TIERED
                    ){
                        commission.cpaConfiguration.range = data.range.map((r, i) => (
                            { 
                                ...r, 
                                to: r.to === INFINITY ? null : r.to, 
                                from: r.from === MINUS_INFINITY ? null : r.from 
                            }
                        ));
                    }
                } else if(commission.type === COMMISSION_PLAN_TYPE.FIX){
                    commission.fixedConfiguration = {};
                    commission.fixedConfiguration.amount = data.amount;
                }

                createCommissionPlan(commission,
                    record => {
                        navigate(
                            addQueryStringParamsToUrl(
                                pathname, 
                                search, 
                                hash, 
                                {
                                    commissionId: record.id,
                                    commissionName: record.name
                                }
                            )
                        )
                    });
            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.commissionplans.createCommission')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                maxLength={30}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.currency')} *`}
                            name="currencyCode"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    currencies.map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.commissionType')} *`}
                            name="type"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            className='rt--form-item-without-margin'
                        >
                            <Select
                                options={[
                                    { value: COMMISSION_PLAN_TYPE.REVSHARE, text: t('backoffice.commissionplans.revshare') },
                                    { value: COMMISSION_PLAN_TYPE.CPA, text: t('backoffice.commissionplans.cpa') },
                                    { value: COMMISSION_PLAN_TYPE.FIX, text: t('backoffice.commissionplans.fix') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.commissionType')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                onChange={e => setCommissionType(e)}
                            />
                        </Form.Item>
                        {
                            commissionType === COMMISSION_PLAN_TYPE.FIX && <small className="rt--text-light"><i>{ t("backoffice.commissionplans.fixCommissionHint") }</i></small>
                        }
                    </Col>
                </Row>
                <div className='rt--mt-16'>
                    {
                        commissionType === COMMISSION_PLAN_TYPE.REVSHARE ? (
                            <RevShare
                                formInstance={formInstance}
                                getProjectFeeSettings={getProjectFeeSettings}
                                feeSettings={feeSettings}
                            />
                        ) : commissionType === COMMISSION_PLAN_TYPE.CPA ? (
                            <CPA
                                formInstance={formInstance}
                            />
                        ) : commissionType === COMMISSION_PLAN_TYPE.FIX ? (
                            <Fix 
                            />
                        ) : null
                    }
                </div>
               
            </Form>
        </Modal>
    )
}

/** CommissionPlanCreateComponent propTypes
    * PropTypes
*/
CommissionPlanCreateComponent.propTypes = {
    /** Redux state property, is true when creating commission plan request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create commission plan */
    createCommissionPlan: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createCommissionPlan: (commissionPlan, onSuccess) => {
            dispatch(createCommissionPlan(commissionPlan, onSuccess));
        },
        getProjectFeeSettings: () => {
            dispatch(getProjectFeeSettings());
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.commissionPlans.isSaving,
        feeSettings: state.projects.edit.feeSettings,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanCreateComponent)