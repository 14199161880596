import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types";

import { POPUP_SIZE } from "core/constants/common";

import Responsive from "core/ui-kit/responsive";

import ModalMobile from "./mobile";
import ModalDesktop from "./desktop";

const Modal = ({
    open=false,
    children,
    title,
    isLoading,
    className,
    onOk,
    onCancel,
    okText,
    cancelText,
    size=POPUP_SIZE.SMALLEST,
    okButtonProps: argOkButtonProps,
    cancelButtonProps: argCancelButtonProps,
    maskClosable,
    closable = false,
    footer,
    ...rest
}) => {
    const okButtonProps = Object.assign({ hidden: false, disabled: false, className: ""}, argOkButtonProps);
    const cancelButtonProps = Object.assign({ hidden: false, disabled: false, className: ""}, argCancelButtonProps);

    const [visible, setVisible] = useState(open);

    useEffect(() => {
        setVisible(open)
    }, [open])

    const handleCancel = () => {
        onCancel();
        setVisible(false)
    }

    const closeOnNavigationChange = useCallback(() => {
        if(visible){
            handleCancel();
        }
    }, [visible])

    // hide when hash change
    useEffect(() => {
        addEventListener("hashchange", closeOnNavigationChange);

        return () => {
            removeEventListener("hashchange", closeOnNavigationChange);
        }
    }, [closeOnNavigationChange]);

    return (
		<Responsive
			mobile={
				<ModalMobile
                    visible={visible}
                    className={className}
                    title={title}
                    isLoading={isLoading}
                    footer={footer}
                    onOk={onOk}
                    onCancel={handleCancel}
                    okText={okText}
                    cancelText={cancelText}
                    maskClosable={maskClosable}
                    okButtonProps={okButtonProps}
                    cancelButtonProps={cancelButtonProps}
                    {...rest}
				>
					{children}
				</ModalMobile>
			}
			desktop={
				<ModalDesktop
                    visible={visible}
					className={className}
					title={title}
					isLoading={isLoading}
					footer={footer}
                    onOk={onOk}
                    onCancel={handleCancel}
                    okText={okText}
                    cancelText={cancelText}
					size={size}
                    closable={closable}
					okButtonProps={okButtonProps}
					cancelButtonProps={cancelButtonProps}
					{...rest}
				>
					{children}
				</ModalDesktop>
			}
		/>
	);
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    size: PropTypes.oneOf(Object.values(POPUP_SIZE)),
    okButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    cancelButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    maskClosable: PropTypes.bool,
    closable: PropTypes.bool,
    footer: PropTypes.node,
}

export default Modal;