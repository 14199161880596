import React from 'react';
import PropTypes from 'prop-types';

import useDate from "core/hooks/useDate";

const DateTimeColumn = ({ value }) => {

    const { dateService } = useDate();
    
    return (
        <>
            <span className="rt--title rt--font-normal rt--font-regular rt--table-col-date">
                {dateService.format(value, false) + " "}
            </span>
            <span className="rt--title rt--font-normal rt--font-regular rt--table-col-time">
                {dateService.format(value, true, false)}
            </span>
        </>
    );
}

/** DateTimeColumn propTypes
 * PropTypes
*/
DateTimeColumn.propTypes = {
    value: PropTypes.string,
}

export default DateTimeColumn;