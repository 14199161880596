//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    getCampaignCreatives,
    setCampaignCreativesFilters,
    setCampaignCreativesSorting,
    getCreativeHTML,
    deleteCampaignCreative
} from "store/actions/portal/marketingTools/campaigns/creatives.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import MediaToolCreateComponent from 'pages/marketingTools/mediaTools/createMedia/mediaTool-create-edit.component';
import Filters from './filters.component';
import CreativeCreateEditComponent from "./creative-create-edit.component";
import Lightbox from 'components/common/lightbox';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { makeImagePath } from 'utils/common';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import { getTableColumns } from "./columns";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { CREATIVE_TYPE, MEDIA_TYPE } from 'constants/campaign.constants';
import ApiUrls from 'constants/api.constants';
import { CREATIVES } from 'constants/pageName.constants';
//#endregion

//#region types
import creativeType from 'types/campaign/creative.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';
import { USER_ROLE } from 'core/constants/auth';

/** Campaign Edit Page Creatives Tab Component */
const CreativesComponent = ({
    creatives,
    getCampaignCreatives,
    setCampaignCreativesFilters,
    setCampaignCreativesSorting,
    deleteCampaignCreative,
    getCreativeHTML,
    htmls,
    isHtmlLoading,
    isLoading,
    sorting,
    filters,
    total
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const permissionUtils = usePermissions();

    const userRole = useAuthSelector(userRoleSelector);

    const [mediaCreatePopupData, setMediaCreatePopupData] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: CREATIVES });

    // State to show/hide creative add/edit popup
    const [createPopup, setCreatePopup] = useState(null);

    // State to show/hide thumbnail
    const [lightbox, setLightbox] = useState(null);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE, PERMISSION_ACTION.CREATE );
    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE, PERMISSION_ACTION.MODIFY );
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE, PERMISSION_ACTION.EXPORT );
    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE, PERMISSION_ACTION.DELETE );
    const hasMediaCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, PERMISSION_ACTION.CREATE );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCampaignCreativesFilters({
            ...filters,
            nameOrId: value
        })
    }

    const showCreatePopup = () => {
        setCreatePopup({});
    }

    const hideCreatePopup = () => {
        setCreatePopup(null);
    }

    const hideLightbox = () => {
        setLightbox(null)
    }

    const handleThumbnailClick = record => {
        const items = [];
        if (record.type === CREATIVE_TYPE.IMAGE) {
            items.push({
                src: makeImagePath(record.filePath),
                type: "image",
                name: record.name
            });
        } else {
            if (!htmls[record.id]) {
                getCreativeHTML(record.id, data => {
                    const items = [
                        {
                            type: "iframe",
                            name: record.name,
                            width: record.dimension?.split("x")?.[0],
                            height: record.dimension?.split("x")?.[1],
                            html: data
                        }
                    ]
                    setLightbox({ items })
                });
            } else {
                items.push({
                    type: "iframe",
                    name: record.name,
                    width: record.dimension?.split("x")?.[0],
                    height: record.dimension?.split("x")?.[1],
                    html: htmls[record.id]
                });
            }
        }

        setLightbox({ items })
    }

    const showMediaCreatePopup = (record) => {
        setMediaCreatePopupData({
            campaignId: searchParams.id,
            creativeId: record.id,
            type: MEDIA_TYPE.CREATIVE,
            creativeData: {
                name: record.name,
                id: record.id
            }
        })
    }

    const hideMediaCreatePopup = () => {
        setMediaCreatePopupData(null);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns: includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                onThumbnailClick: handleThumbnailClick,
                userRole,
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasMediaCreatePermission && userRole === USER_ROLE.AFFILIATE) {
        tableRowActions.push({
            title: t('backoffice.campaigns.createMedia'),
            icon: "close-tag",
            onClick: showMediaCreatePopup
        })
    }

    if (hasModifyPermission) {
        tableRowActions.push({
            title: t("backoffice.common.edit"),
            icon: "edit",
            onClick: record => setCreatePopup({
                creative: record
            }),
        })
    }

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.campaigns.doYouWanttoDeleteCreative"),
            confirmationTitle: t("backoffice.campaigns.deleteCreative"),
            icon: "trash",
            showConfirmation: true,
            disabled: record => record.usedCount > 0,
            onClick: record => deleteCampaignCreative(record.id, searchParams.id),
        })
    }


    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            placeholder: t("backoffice.campaigns.creativeNameOrId"),
            onSearch: handleSearchChange,
            loadFn: () => getCampaignCreatives(searchParams.id),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.CREATIVE,
                actionParams: {
                    campaignId: searchParams.id
                }
            }
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.campaigns.createCreative"),
            onClick: showCreatePopup,
            enabled: hasCreatePermission
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.campaigns.creatives"),
            columns: columnsForExport,
            url: `${ApiUrls.EXPORT_CAMPAIGN_CREATIVES}?campaignId=${searchParams.id}`,
            filters: filters,
        }
    }

    //#endregion

    // Close user add/edit popup, after save
    useEffect(() => {
        hideCreatePopup()
    }, [creatives])

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={creatives}
                loadFn={nextPage => getCampaignCreatives(searchParams.id, nextPage)}
                setSortingFn={setCampaignCreativesSorting}
                sorting={sorting}
                total={total}
                actions={tableRowActions}
                className="rt--table-large"
            />

            {createPopup &&
                <CreativeCreateEditComponent
                    onClose={hideCreatePopup}
                    editingCreative={createPopup.creative}
                />
            }

            {mediaCreatePopupData && (
                <MediaToolCreateComponent
                    onClose={hideMediaCreatePopup}
                    formInitialValues={mediaCreatePopupData}
                />
            )}

            {lightbox && (
                <Lightbox
                    onClose={hideLightbox}
                    items={lightbox.items}
                />
            )}
        </TabTableDashboardLayout>
    )
}

/** CreativesComponent propTypes
    * PropTypes
*/
CreativesComponent.propTypes = {
    /** Redux action to get campaign creatives */
    getCampaignCreatives: PropTypes.func,
    /** Redux state property, is true when acampaign creatives is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the users of current editing campaign creatives  */
    creatives: PropTypes.arrayOf(creativeType),
    /** Redux action to set campaign creatives sorting */
    setCampaignCreativesSorting: PropTypes.func,
    /** Redux action to set campaign creatives filters */
    setCampaignCreativesFilters: PropTypes.func,
    /** Redux action to delete campaign creative */
    deleteCampaignCreative: PropTypes.func,
    /** Redux state property, represents the sorting data of creatives of current editing campaign */
    sorting: sortingType,
    /** Redux state property, represents the filters data of creatives of current editing campaign */
    filters: PropTypes.object,
    /** Redux state property, represents the total numbers of creatives of current editing campaign */
    total: PropTypes.number,
    /** Redux state property, Creative htmls */
    htmls: PropTypes.objectOf(PropTypes.string),
    /** Redux action to get creative html */
    getCreativeHTML: PropTypes.func,
    /** Redux action is true when loading creative html */
    isHtmlLoading: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        getCampaignCreatives: (id, nextPage) => {
            dispatch(getCampaignCreatives(id, nextPage));
        },

        setCampaignCreativesSorting: sorting => {
            dispatch(setCampaignCreativesSorting(sorting))
        },

        setCampaignCreativesFilters: filters => {
            dispatch(setCampaignCreativesFilters(filters))
        },

        deleteCampaignCreative: (id, campaignId) => {
            dispatch(deleteCampaignCreative(id, campaignId))
        },

        getCreativeHTML: (id, onSuccess) => {
            dispatch(getCreativeHTML(id, onSuccess))
        },
    }
)

const mapStateToProps = state => {
    return {
        creatives: state.campaigns.edit.creatives.creatives,
        htmls: state.campaigns.edit.creatives.htmls,
        isHtmlLoading: state.campaigns.edit.creatives.isHtmlLoading,
        isLoading: state.campaigns.isLoading,
        sorting: state.campaigns.edit.creatives.sorting,
        filters: state.campaigns.edit.creatives.filters,
        total: state.campaigns.edit.creatives.total
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreativesComponent)