import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import {
    getAggregatedData,
    setAggregatedDataFilters
} from 'store/actions/portal/reports/aggregatedData/aggregatedData.action';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useCurrencies from 'hooks/useCurrencies';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import AutoComplete from "components/common/autoComplete";

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Aggregated Data Report Page Filters Component */
const Filters = ({
    setAggregatedDataFilters,
    getAggregatedData,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [ affiliateNames, getAffiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES, 
        autoGet: false
    });

    const [ playerNames, getPlayerNames, resetPlayerNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PLAYER, 
        autoGet: false
    });

    const [ currencies, getCurrencies ] = useCurrencies(false);

    const handlePlayersChange = v => {
        if(v?.length >= 3){
            getPlayerNames({"userNameOrId" : v})
        } else if(v?.length === 0){
            resetPlayerNames();
        }
    }

    return (
        <FiltersWrapper
            loadFn={getAggregatedData}
            setFiltersFn={setAggregatedDataFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            searchFieldName="date"
            datePickerFields={[
                { name: "date", time: true },
            ]}
            onInit={() => {
                getAffiliateNames();
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.player')}
                        name="userNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.player')}`}
                            items={playerNames}
                            disableFiltering={true}
                            changeHandler={handlePlayersChange}
                            valueMapper={(items, value) => {

                                const foundItem = items.find(item => item.longId?.toString() === value?.toString());

                                if (foundItem) {
                                    return foundItem.name;
                                }

                                return value;
                            }}

                            valueGetter={(items, value) => {
                                const searchedItem = value !== "" ? items.find(item => (
                                    item.longId?.toString() === value ||
                                    item.name?.toLowerCase() === value.toLowerCase()
                                )) || null : null;

                                return searchedItem?.longId ?? value;
                            }}
                        />
                    </Form.Item>
                </Col>
                
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.currency')}
                        name="currencyCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    currencies.map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.affiliateUserNameOrId')}
                        name="affiliateUserNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.affiliateUserNameOrId')}`}
                            items={affiliateNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get aggregated data */
    getAggregatedData: PropTypes.func,
    /** Redux action to set aggregated data filters */
    setAggregatedDataFilters: PropTypes.func,
    /** Redux state property, aggregated data filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getAggregatedData: () => {
            dispatch(getAggregatedData())
        },
        setAggregatedDataFilters: (filters, keepPage) => {
            dispatch(setAggregatedDataFilters(filters, keepPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.aggregatedData.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);