import React from 'react';

import isObject from 'core/helpers/typeChecks/isObject';

import DebugValueRow from '../debugValueRaw';

const ObjectViewer = ({name, value, canEdit}) => {

    const isPrimitive = !isObject(value);
    let properties = [];

    if (isPrimitive) {
        return <DebugValueRow value={value} />;
    }

    if (!isPrimitive) {
        properties = Object.keys(value);
    }

    return (
        <div
            className="rt--debug-object-viewer"
        >
            {properties.map((property, index) => (
                <DebugValueRow
                    key={index + property}
                    name={property}
                    path={[property]}
                    value={value[property]}
                    canEdit={canEdit}
                />
            ))}
        </div>
    );
};

export default ObjectViewer;
