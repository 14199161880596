import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

const notificationsService = {
    getNotifications(data, signal) {
        return httpService.get({
            url: API_URL.GET_NOTIFICATIONS,
            signal,
            queryData: { ...data }
        }).then(({ data: { value } }) => {
            return value;
        });
    },
    notificationsMarkAllAsRead(data, signal) {
        return httpService.post({
            url: API_URL.NOTIFICATIONS_MARK_ALL_AS_READ,
            signal,
            requestBody: { ...data }
        }).then(({ data: { value } }) => {
            return value;
        })
    },
    notificationsDeleteAll(data, signal) {
        return httpService.delete({
            url: API_URL.NOTIFICATIONS_DELETE_ALL,
            signal,
            requestBody: { ...data }
        })
    },
    removeNotification(data, signal) {
        return httpService.delete({
            url: API_URL.DELETE_NOTIFICATION,
            signal,
            requestBody: { ...data }
        })
    },
    notificationMarkAsRead(data, signal) {
        return httpService.post({
            url: API_URL.MARK_AS_READ_NOTIFICATION,
            signal,
            requestBody: { ...data }
        }).then(({ data: { value } }) => {
            return value;
        })
    },
}

export default notificationsService;
