import axios from "core/lib/axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import dateService from "core/services/dateService";

export const getOperationalPieChartData = ({ entityType, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_PIE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            entityType,
            affiliateId,
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalWidgetData = ({ dataType, timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_WIDGET,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalLineChartData = ({ dataType, timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId,
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getOperationalTopAffiliates = ({ dataType, timePeriod, from, to }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_OPERATIONS_TOP_AFFILIATES,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLiveBalances = ({ affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_WALLETS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            affiliateId
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialLineChartData = ({ dataType, timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCIAL_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialTopAffiliates = ({ dataType, currencyCode, timePeriod, from, to }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCIAL_TOP_AFFILIATES,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            currencyCode,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone()
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getFinancialTopPlayers = ({ dataType, currencyCode, timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_FINANCIAL_TOP_PLAYERS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            currencyCode,
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId: affiliateId,
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getMarketingTopCampaigns = ({ dataType, timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_MARKETING_TOP_CAMPAIGNS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getMarketingTopCountries = ({ dataType, timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_MARKETING_TOP_COUNTRIES,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            dataType, 
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getMarketingPieCharts = ({ timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_MARKETING_PIE_CHARTS,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId,
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}

export const getMarketingLineChartData = ({ timePeriod, from, to, affiliateId }) => {
    const abortController = new AbortController();

    const request = axios({
        url: ApiUrls.GET_DASHBOARD_MARKETING_LINE_CHART,
        method: Methods.GET,
        signal: abortController.signal,
        params: {
            timePeriod,
            from,
            to,
            timeZone: dateService.getTimeZone(),
            affiliateId,
        }
    })

    const cancelRequest = () => abortController.abort();

    return { request, cancelRequest };
}