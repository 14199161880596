import React, { useMemo, useState, useEffect, Fragment, useCallback } from "react";

import Form from "core/ui-kit/form";
import { Row, Col } from "core/ui-kit/layout";

import ImageUploader from 'components/common/imageUploader';
import Divider from "core/ui-kit/divider";
import Alert from "core/ui-kit/alert";

import Wallets from "./wallets";

import { useTranslation } from "core/hooks/useTranslation";
import useFormat from "hooks/useFormat";

import FormRulesBuilder from "core/helpers/common/formRulesBuilder";

import { currenciesSelector, useGlobalSelector, userInfoSelector } from "core/stores/globalStore";
import { useAuthSelector, userSelector } from "core/stores/authStore";

import flagsToBinary from "core/helpers/common/flagsToBinary";

import { USER_ROLE, USER_STATE } from "core/constants/auth";
import { FORM_FIELD_NAMES } from "./constants";

import autosuggestionService from "core/services/apiServices/autosuggestionService";
import invoiceService from "core/services/apiServices/invoiceService";

const InvoiceCreateForm = ({
    onFinish,
    formInstance,
    setDisabled
}) => {

    const { t, locale } = useTranslation();

    const languageCode = locale.toUpperCase();

    const currencies = useGlobalSelector(currenciesSelector);

    const { formatAmount } = useFormat();

    const { role, userName } = useAuthSelector(userSelector);
    const { id: userId } = useGlobalSelector(userInfoSelector);

    const isAffiliate = role === USER_ROLE.AFFILIATE;

    const [affiliatesAutosuggestion, setAffiliatesAutosuggestion] = useState([])

    const [ paymentMethods, setPaymentMethods ] = useState([])
    const [ availableWallets, setAvailableWallets ] = useState({})
    const [ paymentTranslations, setPaymentTranslations ] = useState([])

    const [ selectedAffiliateId, setSelectedAffiliateId ] = useState(isAffiliate ? userId : null);
    const [ selectedCurrencyCode, setSelectedCurrencyCode ] = useState(null);
    const [ selectedPaymentId, setSelectedPaymentId] = useState(null);

    const selectedPayment = useMemo(() => paymentMethods.find(payment => payment.id === selectedPaymentId), [selectedPaymentId, paymentMethods])

    const [total, setTotal] = useState(0)

    const isPaymentFieldDisabled = !selectedAffiliateId || !selectedCurrencyCode;

    const showWallets = selectedAffiliateId && selectedCurrencyCode;

    const [ noPaymentError, setNoPaymentError] = useState(false);
    const [ minThresholdPaymentError, setMinThresholdPaymentError] = useState(false);

    const hasError = noPaymentError || minThresholdPaymentError

    useEffect(() => {
        if(!isAffiliate){
            const availableStatus = [USER_STATE.ACTIVE, USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA, USER_STATE.EXPIRED, USER_STATE.BLOCKED, USER_STATE.DELETED]
            autosuggestionService.getAffiliates({
                state: flagsToBinary(availableStatus)
            })
                .then(value => setAffiliatesAutosuggestion(value))
        } else {
            setAffiliatesAutosuggestion([{
                id: userId,
                name: userName
            }])
        }
    }, [])

    useEffect(() => {
        if(!selectedAffiliateId || !selectedCurrencyCode) return;

        setSelectedPaymentId(null);
        formInstance.setFieldsValue({
            [FORM_FIELD_NAMES.PAYMENT_ID] : null
        })

        invoiceService.getAvailablePaymentMethods({
            currencyCode: selectedCurrencyCode,
            affiliateId: selectedAffiliateId
        }).then(value => {
            setPaymentMethods(value);
            setNoPaymentError(value.length === 0)
        })
    }, [selectedAffiliateId, selectedCurrencyCode])

    useEffect(() => {
        if(!selectedAffiliateId ) return;

        invoiceService.getAvailableWallets({
            id: selectedAffiliateId
        }).then(value => setAvailableWallets(value))

        formInstance.setFieldsValue({
            [FORM_FIELD_NAMES.AMOUNTS] : [{ currencyCode: null, amount: "" }],
            [FORM_FIELD_NAMES.CURRENCY_CODE]: null
        })

    }, [selectedAffiliateId])

    useEffect(() => {
        if(!selectedPayment) return;

        invoiceService.getPaymentTranslations({id: selectedPayment.id})
            .then(value => {
                setPaymentTranslations(value.translations)
            })
        
    }, [selectedPayment]);

    const affiliatesOptions = useMemo(() => {
        return affiliatesAutosuggestion.map(c => ({ value: c.id, text: c.name }))
    }, [affiliatesAutosuggestion])

    const currencyOptions = useMemo(() => {
        return currencies.map(currency => ({ value: currency.currencyCode, text: currency.currencyCode }))
    }, [t, currencies])

    const paymentMethodOptions = useMemo(() => {
        return paymentMethods.map(c => ({ value: c.id, text: c.name }))
    }, [paymentMethods])

    /** Mapped translations for table */
    const translations = useMemo(() => {
        const result = {};
        if (!paymentTranslations) return result;
        if(paymentTranslations.length === 0) return result;
        paymentTranslations.forEach(tr => {
            result[tr.key] = tr.translations?.find(t => t.languageCode === languageCode)?.text ?? tr.text
        })

        return result;
    }, [paymentTranslations, languageCode])

    const paymentRequisiteDetails = useMemo(() => {
        return selectedPayment?.paymentRequisiteDetails ?? {}
    }, [selectedPayment])

    const calculateTotal = useCallback(converted => {
        const values = Object.values(converted).map(v => v.value)
        setTotal(values.reduce((partialSum, a) => partialSum + a, 0))
    }, [setTotal])

    const calculatedCommission = useMemo(() => {
        if(!selectedPayment) return 0;
        if(selectedPayment.paymentCommissionPercent === 0) return 0;
        return formatAmount(total * selectedPayment.paymentCommissionPercent/100, selectedCurrencyCode)
    }, [total, selectedPayment, selectedCurrencyCode, formatAmount])

    useEffect(() => {
        if(!selectedPayment || total === 0) return setMinThresholdPaymentError(false)
        setMinThresholdPaymentError(selectedPayment.minThreshold > total);
    }, [total, selectedPayment])

    useEffect(() => {
        if(!selectedAffiliateId || !selectedCurrencyCode || !selectedPayment || hasError){
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [selectedAffiliateId, selectedCurrencyCode, selectedPayment, hasError])

    return (
        <Form 
            form={formInstance}
            onFinish={onFinish}
            initialValues={{
                [FORM_FIELD_NAMES.AFFILIATE_ID]: isAffiliate ? userId: undefined,
                [FORM_FIELD_NAMES.CURRENCY_CODE]: null,
                [FORM_FIELD_NAMES.PAYMENT_ID]: null,
                [FORM_FIELD_NAMES.AMOUNTS] : [
                    { currencyCode: null, amount: "" }
                ],
                [FORM_FIELD_NAMES.NOTES]: ""
            }}
        >
            <Row gutter={[16, 0]}>
                {
                    hasError && (
                        <Col span={24}>
                            <Alert 
                                type="warning"
                                message={noPaymentError ? t("backoffice.common.warning") : t("backoffice.projects.minThreshold")}
                                description={
                                    noPaymentError ? t("backoffice.invoices.noPaymentMethod") : t('backoffice.invoices.minThresholdValidationMessage')
                                }
                                className="rt--mb-16"
                            />
                        </Col>
                    )
                }
                
                {
                    !isAffiliate && (
                        <Col xs={24} xl={8}>
                            <Form.Select 
                                name={FORM_FIELD_NAMES.AFFILIATE_ID} 
                                label={t("backoffice.invoices.affiliate")}
                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.invoices.affiliate")}`}
                                rules={
                                    new FormRulesBuilder(t).required({whitespace: false}).build()
                                }
                                options={affiliatesOptions}
                                search={true}
                                onChange={setSelectedAffiliateId}
                            />
                        </Col>
                    )
                }
                
                <Col xs={24} xl={8}>
                    <Form.Select
                        name={FORM_FIELD_NAMES.CURRENCY_CODE}
                        label={t('backoffice.invoices.invoiceCurrency')}
                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.invoiceCurrency')}`} 
                        rules={
                            new FormRulesBuilder(t).required({whitespace: false}).build()
                        }
                        options={currencyOptions}
                        search={true}
                        onChange={setSelectedCurrencyCode}
                    />
                </Col>

                <Col xs={24} xl={8}>
                    <Form.Select
                        name={FORM_FIELD_NAMES.PAYMENT_ID}
                        label={t('backoffice.invoices.paymentMethod')}
                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.paymentMethod')}`} 
                        rules={
                            new FormRulesBuilder(t).required({whitespace: false}).build()
                        }
                        options={paymentMethodOptions}
                        search={true}
                        disabled={isPaymentFieldDisabled}
                        onChange={setSelectedPaymentId}
                    />
                </Col>
            </Row>
            {
                showWallets && (
                    <Wallets 
                        availableWallets={availableWallets}
                        formInstance={formInstance}
                        currencyCode={selectedCurrencyCode}
                        onChange={calculateTotal}
                        affiliateId={selectedAffiliateId}
                    />
                )
            }

            {
                Object.keys(paymentRequisiteDetails).length > 0 ? (
                    <Col span={24}>
                        <Form.Item>
                            <div className='rt--info rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12'>
                                {
                                    Object.keys(paymentRequisiteDetails).map(detail => (
                                        <div className='rt--flex rt--align-center rt--mb-4' key={detail}>
                                            <span className='rt--text-light rt--font-normal rt--font-bold'>
                                                {translations[detail.toLowerCase()]}:
                                            </span>
                                            <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4 rt--pr-4'>
                                                {paymentRequisiteDetails[detail]}
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                        </Form.Item>
                    </Col>
                ) : null
            }

            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Textarea
                        label={t('backoffice.invoices.note')}
                        name={FORM_FIELD_NAMES.NOTES}
                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.note')}`}
                        rules={
                            new FormRulesBuilder(t).minCharactersCount(2).maxCharactersCount(150).build()
                        }
                    />
                </Col>
                <Col span={24}>
                    <Form.Item
                        label={`${t('backoffice.invoices.screenshot')}`}
                        name={FORM_FIELD_NAMES.FILE}
                    >
                        <ImageUploader
                            size={1024 * 1024 * 3}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {
                selectedCurrencyCode && (
                    <Fragment>
                        <Divider />
                        <Row gutter={[16, 0]}>
                            <Col span={24}>
                                <div className="rt--flex rt--justify-between rt--align-center rt--invoice-total">
                                    <div className="rt--flex rt--flex-col">
                                        <span className='rt--text-light rt--font-big rt--font-regular'>
                                            {t("backoffice.invoices.totalInvoiceAmount")}
                                        </span>
                                        {
                                            selectedPayment && (
                                                <small className='rt--text-light rt--font-small rt--font-regular rt--pt-2'>
                                                    {
                                                        `${t("backoffice.invoices.transactionCommission")}: ${calculatedCommission} ${selectedCurrencyCode} (${selectedPayment.paymentCommissionPercent}%)`
                                                    }
                                                </small>
                                            )
                                        }
                                    </div>
                                    <div className="rt--invoice-total-num">
                                        <span className='rt--title rt--font-big rt--font-bold'>
                                            {`${formatAmount(total, selectedCurrencyCode)} ${selectedCurrencyCode}`}
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                )
            }
            
        </Form>
    )
}

export default InvoiceCreateForm;