import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { connect } from 'react-redux';

import { Row, Col, Form, DatePicker } from "antd";

import Table from "components/common/table";
import Icon from 'components/common/icon';

import { getNewsletterAvailableAffiliates } from 'store/actions/portal/communications/newsletter/affiliates.action';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isMobile } from 'utils/common';

import useDate from "core/hooks/useDate";

import { getTableColumns } from '../../editNewsletter/sections/affiliates/columns';

import affiliateType from 'types/affiliate/generalInfo.type';

/** Newsletter Creating Popup Preview Section Component */
const NewsletterCreatePreviewComponent = ({
    formInstance,
    getNewsletterAvailableAffiliates,
    availableAffiliates,
    isLoading,
    filters
}) => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    useEffect(() => {
        getNewsletterAvailableAffiliates(filters);
    }, [])

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: getTableColumns,
            additionalProps: {
                t
            },
            t 
        })
    }, [t])

    return (
        <div>
            {
                isMobile() && (
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-biger'
                    >
                        {
                            t('backoffice.communication.preview')
                        }
                    </h4>
                )
            }
            <Form
                className="rt--form"
                colon={false}
                requiredMark={false}
                layout="vertical"
                form={formInstance}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} lg={8}>
                        <Form.Item
                            label={t('backoffice.communication.scheduleDate')}
                            name="sendDate"
                        >
                            <DatePicker
                                className="rt--datepicker"
                                dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup'
                                format={`${dateService.getFormat(true)}`}
                                showTime={{ format: dateService.getFormat(true, true) }}
                                disabledDate={d => dateService.isBefore(d, dateService.getNow(true))}
                                showToday={false}
                                showNow={false}
                                allowClear={true}
                                placeholder={t('backoffice.common.selectDate')}
                                suffixIcon={<Icon name='date' />}
                                getPopupContainer={isMobile() ? '' : () => document.getElementsByClassName("rt--portal-layout")[0]}
                                inputReadOnly={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={availableAffiliates}
                noPagination={true}
                disableFullView={true}
            />
        </div>
    )
}

/** NewsletterCreatePreviewComponent propTypes
    * PropTypes
*/
NewsletterCreatePreviewComponent.propTypes = {
    /** Form Instance */
    formInstance: PropTypes.object,
    /** Affilaites Filters */
    filters: PropTypes.object,
    /** Redux action to get newsletter available affilaites */
    getNewsletterAvailableAffiliates: PropTypes.func,
    /** Redux state property, available affiliates */
    availableAffiliates: PropTypes.arrayOf(affiliateType),
    /** Redux state property, is true when loading available affilaites */
    isLoading: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletterAvailableAffiliates: filters => {
            dispatch(getNewsletterAvailableAffiliates(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        availableAffiliates: state.newsletter.affiliates.availableAffiliates,
        isLoading: state.newsletter.affiliates.isLoading,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NewsletterCreatePreviewComponent);