import React, { useMemo, memo } from "react";

import { useTranslation } from "core/hooks/useTranslation";
import useDate from "core/hooks/useDate";
import useDevice from "core/hooks/useDevice";
import usePermissions from "core/hooks/usePermission";

import { Row, Col } from "core/ui-kit/layout";
import Table from "core/ui-kit/table";
import Button from "core/ui-kit/button";
import Icon from "core/ui-kit/icon";
import Status from "core/ui-kit/status";

import Ranges from "../ranges";

import { COMMISSION_TYPE, COMMISSION_TYPE_TRANSLATIONS, CPA_PERIOD_TRANSLATIONS } from "core/constants/commission/type";
import { COMMISSION_SOURCE, COMMISSION_SOURCE_TRANSLATIONS } from "core/constants/commission/source";
import { PROJECT_PRODUCT_TYPE_TRANSLATIONS } from "core/constants/project/productType";
import { COMMISSION_STRUCTURE, COMMISSION_STRUCTURE_TRANSLATIONS } from "core/constants/commission/structure";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";
import { COMMISSION_POPUP_MODE } from "../constants";

import classNames from "core/helpers/common/classNames";

const Details = ({
    commission,
    mode,
    editable,
    onDeleteButtonClick
}) => {

    const { t } = useTranslation();

    const { isMobile } = useDevice();

    const permissionUtils = usePermissions();

    const { dateService } = useDate();

    const canDelete = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, PERMISSION_ACTION.DELETE );

    const columns = useMemo(() => {
        return [
            {
                title: t("backoffice.common.min"),
                dataIndex: "from",
                render: value => <Table.Cell.Numeric value={value} />,
                mobileConfigs: {
                    order: 1
                },
            },

            {
                title: t("backoffice.common.max"),
                dataIndex: "to",
                render: value => value ? <Table.Cell.Numeric value={value} /> : "∞",
                mobileConfigs: {
                    order: 2
                },
            },

            {
                title: commission?.type === COMMISSION_TYPE.REVSHARE ? t("backoffice.common.percent") : t("backoffice.common.amount"),
                dataIndex: "value",
                render: value => commission?.type === COMMISSION_TYPE.REVSHARE ? `${value}%` : value,
                mobileConfigs: {
                    order: 3
                },
            }
        ]
    }, [t, commission])

    const fields = useMemo(() => {
        const fields = [];

        if(!commission) return fields;

        if(mode === COMMISSION_POPUP_MODE.VIEW){
            fields.push({
                title: t("backoffice.commissionplans.currency"),
                value: commission.currencyCode
            });

            fields.push({
                title: t("backoffice.commissionplans.commissionType"),
                value: t(COMMISSION_TYPE_TRANSLATIONS[commission.type])
            })
        } else {
            fields.push({
                title: t("backoffice.common.id"),
                value: commission.longId
            });
        }

        if(commission.type === COMMISSION_TYPE.REVSHARE){
            const source = commission.revShareConfiguration?.source;
            const products = commission.revShareConfiguration?.products;
            const structure = commission.revShareConfiguration?.structure;
            const percent = commission.revShareConfiguration?.percent;

            fields.push({
                title: t("backoffice.commissionplans.source"),
                value: t(COMMISSION_SOURCE_TRANSLATIONS[source])
            });

            if(source !== COMMISSION_SOURCE.NET_DEPOSIT){
                fields.push({
                    title: t("backoffice.projects.product"),
                    value: (
                        <div className="rt--flex rt--align-center">
                            {
                                products ? products.map( v => (
                                    <div key={v} className="rt--mr-12">
                                        <Status
                                            color="blue" 
                                            text={t(PROJECT_PRODUCT_TYPE_TRANSLATIONS[v])}
                                        />
                                    </div>
                                ) ) : "-"
                            }
                        </div>
                    )
                });
            }
            
            fields.push({
                title: t("backoffice.commissionplans.structure"),
                value: t(COMMISSION_STRUCTURE_TRANSLATIONS[structure])
            });

            if(structure === COMMISSION_STRUCTURE.FLAT){
                fields.push({
                    title: t("backoffice.commissionplans.percent"),
                    value: percent + " %"
                });
            }

        } else if(commission.type === COMMISSION_TYPE.CPA){
            const structure = commission.cpaConfiguration?.structure;
            const minDepositAmount = commission.cpaConfiguration?.minDepositAmount;
            const minTurnoverAmount = commission.cpaConfiguration?.minTurnoverAmount;
            const period = commission.cpaConfiguration?.period;
            const amount = commission.cpaConfiguration?.amount;
            const ftdOnly = commission.cpaConfiguration?.ftdOnly;

            fields.push({
                title: t("backoffice.commissionplans.cpaPeriod"),
                value: t(CPA_PERIOD_TRANSLATIONS[period])
            });

            fields.push({
                title: t("backoffice.commissionplans.minDepositAmount"),
                value: minDepositAmount
            });
            fields.push({
                title: t("backoffice.commissionplans.minWagerAmount"),
                value: minTurnoverAmount
            });
            fields.push({
                title: t("backoffice.commissionplans.structure"),
                value: t(COMMISSION_STRUCTURE_TRANSLATIONS[structure])
            });

            if(structure === COMMISSION_STRUCTURE.FLAT){
                fields.push({
                    title: t("backoffice.commissionplans.amount"),
                    value: amount
                });
            }

            fields.push({
                title: t("backoffice.commissionplans.firstTimeDeposit"),
                value: ftdOnly? t("backoffice.common.yes") : t("backoffice.common.no")
            });

        } else if(commission.type === COMMISSION_TYPE.FIX){
            const amount = commission.fixedConfiguration?.amount;
            fields.push({
                title: t("backoffice.commissionplans.amount"),
                value: amount
            });
        }

        return fields;
    }, [commission, t, mode])

    if(!commission) return null;

    const ranges = commission.type === COMMISSION_TYPE.REVSHARE ? (
        commission.revShareConfiguration?.range ?? []
    ) : commission.type === COMMISSION_TYPE.CPA ? (
        commission.cpaConfiguration?.range ?? []
    ) : [];

    const showDeleteButton = mode === COMMISSION_POPUP_MODE.VIEW && editable && canDelete;

    const colSpan = isMobile ? {span: 12} : { span: 8 }

    return (
        <div className="rt--commissions-details rt--mt-16 rt--pt-16 rt--pl-16 rt--pr-16">
            <div className="rt--flex rt--justify-between rt--mb-6">
                <b className="rt--font-bold rt--font-big">{commission.name}</b>
                {
                    showDeleteButton && (
                        <Button
                            icon={<Icon name="trash" />}
                            type="primary"
                            className={
                                classNames(
                                    "rt--button rt--button-danger rt--pt-4 rt--pb-4",
                                    isMobile ? "rt--pl-4 rt--pr-4" : "rt--pl-16 rt--pr-16"
                                )
                            }
                            onClick={onDeleteButtonClick}
                        >
                            { !isMobile ? t("backoffice.common.remove") : " " }
                        </Button>
                    )
                }
            </div>
            {
                mode === COMMISSION_POPUP_MODE.VIEW && (
                    <div className="rt--commissions-details-section rt--pb-8 rt--pt-16">
                        <Row gutter={[16, 0]}>
                            <Col {...colSpan} className="rt--mb-16">
                                <div className="rt--commissions-details-title rt--pb-4">
                                    <span className="rt--font-normal rt--font-regular">{t("backoffice.commissionplans.startDateTime")}</span>
                                </div>
                                <div className="rt--commissions-details-value">
                                    <span className="rt--font-normal rt--font-regular">{dateService.format(commission.startDate)}</span>
                                </div>
                            </Col>
                            <Col {...colSpan} className="rt--mb-16">
                                <div className="rt--commissions-details-title rt--pb-4">
                                    <span className="rt--font-normal rt--font-regular">{t("backoffice.commissionplans.nextCalculationTime")}</span>
                                </div>
                                <div className="rt--commissions-details-value">
                                    <span className="rt--font-normal rt--font-regular">{dateService.format(commission.nextCalculationTime)}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
            <div className="rt--commissions-details-section rt--pb-8 rt--mt-16">
                <Row gutter={[16, 0]}>
                    {
                        fields.map(field => (
                            <Col {...colSpan} className="rt--mb-16" key={field.title}>
                                <div className="rt--commissions-details-title rt--pb-4">
                                    <span className="rt--font-normal rt--font-regular">{field.title}</span>
                                </div>
                                <div className="rt--commissions-details-value">
                                    <span className="rt--font-normal rt--font-regular">
                                        { field.value }
                                    </span>
                                </div>
                            </Col>
                        ))
                    }

                    {
                        ( commission.type === COMMISSION_TYPE.REVSHARE && (
                            commission.revShareConfiguration?.source === COMMISSION_SOURCE.NGR ||
                            commission.revShareConfiguration?.source === COMMISSION_SOURCE.NET_DEPOSIT
                        ) ) && (
                            <Col span={24} className="rt--mb-16">
                                <div className="rt--commissions-details-title rt--pb-4">
                                    <span className="rt--font-normal rt--font-regular">{t('backoffice.commissionplans.feeInfo')}</span>
                                </div>
                                <div className="rt--commissions-details-value">
                                    <ul className="rt--pl-24">
                                        {
                                            commission.revShareConfiguration?.source === COMMISSION_SOURCE.NGR && (
                                                <li>
                                                    <span className="rt--font-normal rt--font-regular">
                                                        {`${t('backoffice.commissionplans.playerBalanceAdjustment')} - ${commission.revShareConfiguration?.applyOtherCosts ? t("backoffice.common.yes") : t("backoffice.common.no")}`}
                                                    </span>
                                                </li>
                                            )
                                        }
                                        
                                        <li>
                                            <span className="rt--font-normal rt--font-regular">
                                                {`${t('backoffice.commissionplans.depositFee')} - ${commission.revShareConfiguration?.applyDepositFee ? t("backoffice.common.yes") : t("backoffice.common.no")}`}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </div>

            <Ranges commission={commission} />
        </div>
    )
}

export default memo(Details);