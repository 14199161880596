//#region react
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getInvoicesHistory,
    setInvoicesHistorySorting,
    setInvoicesHistoryFilters
} from 'store/actions/portal/payment/invoices/history.action';
import {
    exportInvoice
} from "store/actions/portal/payment/invoices/invoices.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import InvoicesTotals from "../totals";
import InvoiceDetails from '../invoiceDetails';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isMobile } from 'utils/common';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import { INVOICE_HISTORY_TYPE, INVOICE_STATE } from 'constants/invoice.constants';
import ApiUrls from 'constants/api.constants';
import { SEARCH_TYPE } from 'components/common/search/constants';
import { INVOICES_HISTORY } from 'constants/pageName.constants';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { getTableColumns } from './columns';
import ROUTES from 'core/constants/routes/routes';
//#endregion

//#region types
import invoiceType from 'types/payment/invoice.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

/** Invoices History Component */
const InvoicesHistoryComponent = ({
    invoices,
    sorting,
    filters,
    total,
    isLoading,
    getInvoicesHistory,
    setInvoicesHistorySorting,
    setInvoicesHistoryFilters,
    exportInvoice
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: INVOICES_HISTORY });

    const { formatAmount } = useFormat();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_INVOICES, PERMISSION_ACTION.EXPORT )
    const hasAffiliateGeneralInfoPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, PERMISSION_ACTION.VIEW );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setInvoicesHistoryFilters({
            ...filters,
            id: value
        })
    }

    const handleDateSearchChange = value => {
        setInvoicesHistoryFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    const handleAffiliateClick = useCallback(record => {
        navigate({
            pathname: `${ROUTES.AFFILIATES_EDIT}/${record.affiliateUserName}`,
            search: `name=${record.affiliateUserName}&longId=${record.affiliateId}&fromUsername=true`
        })
    }, [navigate])

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                formatAmount,
                isAffiliateUserNameClickable: hasAffiliateGeneralInfoPermission,
                onAffiliateClick: handleAffiliateClick 
            },
            t
        })
    }, [includedColumns, t, hasAffiliateGeneralInfoPermission, handleAffiliateClick]);

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDataExportPermission) {
        tableRowActions.push({
            title: t('backoffice.invoices.downloadPDF'),
            icon: "file",
            onClick: record => exportInvoice(record.id, record.createdAt, record.affiliateId),
            disabled: record => record.state !== INVOICE_STATE.APPROVED
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            type: SEARCH_TYPE.NUMERIC,
            onSearch: handleSearchChange,
            loadFn: getInvoicesHistory,
            placeholder: t("backoffice.invoices.invoiceId"),
            maxLength: 12,
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.invoices.createdDate'),
            showTime: true,
            allowClear: false,
            loadFn: getInvoicesHistory,
            value: [filters.from, filters.to]
        },
    }

    if (isMobile()) {
        headerPartsData.content = <InvoicesTotals type={INVOICE_HISTORY_TYPE.HISTORY} />
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.invoices.history"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_INVOICES_HISTORY,
            filters: filters
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={
                !isMobile() && (
                    <InvoicesTotals type={INVOICE_HISTORY_TYPE.HISTORY} />
                )
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={invoices}
                loadFn={getInvoicesHistory}
                sorting={sorting}
                setSortingFn={setInvoicesHistorySorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                expandable={{
                    title: t("backoffice.invoices.invoiceDetails"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    details: record => ({
                        data: record.invoiceRequisiteDetails,
                        content: (
                            <InvoiceDetails
                                details={
                                    [
                                        ...record.invoiceRequisiteDetails,
                                        { key: "attachmentPath", value: record.attachmentPath }
                                    ]
                                }
                                paymentId={record.paymentId}
                                walletDetails={record.invoiceWalletDetails}
                                currencyCode={record.currencyCode}
                            />
                        ),
                    }),
                }}
            />
        </TabTableDashboardLayout>
    )
}

/** InvoicesHistoryComponent propTypes
    * PropTypes
*/
InvoicesHistoryComponent.propTypes = {
    /** Redux state property, represents the array of invoices history */
    invoices: PropTypes.arrayOf(invoiceType),
    /** Redux state property, invoices history sorting details */
    sorting: sortingType,
    /** Redux state property, invoices history filters */
    filters: PropTypes.object,
    /** Redux state property, invoices history total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading invoices history */
    isLoading: PropTypes.bool,
    /** Redux action to get invoices history */
    getInvoicesHistory: PropTypes.func,
    /** Redux action to set invoices history sorting details */
    setInvoicesHistorySorting: PropTypes.func,
    /** Redux action to set invoices history filters */
    setInvoicesHistoryFilters: PropTypes.func,
    /** Redux action to export invoice */
    exportInvoice: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getInvoicesHistory: nextPage => {
            dispatch(getInvoicesHistory(nextPage))
        },
        setInvoicesHistorySorting: sorting => {
            dispatch(setInvoicesHistorySorting(sorting));
        },
        setInvoicesHistoryFilters: filters => {
            dispatch(setInvoicesHistoryFilters(filters));
        },

        exportInvoice: (id, createdAt, affiliateId) => {
            dispatch(exportInvoice(id, createdAt, affiliateId));
        },
    }
)

const mapStateToProps = state => {
    return {
        invoices: state.invoices.history.invoices,
        total: state.invoices.history.total,
        sorting: state.invoices.history.sorting,
        filters: state.invoices.history.filters,
        isLoading: state.invoices.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesHistoryComponent)