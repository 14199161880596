import React, { Fragment } from "react";

import { useTranslation } from "core/hooks/useTranslation";

import { isMobile } from "utils/common";
import useFormat from "hooks/useFormat";

const Convertions = ({
    walletDetails,
    currencyCode
}) => {

    const { t } = useTranslation();

    const { formatAmount } = useFormat()

    const showWalletDetails = walletDetails.length > 1 || (walletDetails.length === 1 && walletDetails[0].currencyCode !== currencyCode);

    const renderForDesktop = () => {
        return (
            <div className="rt--flex rt--invoice-details-list">
                <div className="rt--flex rt--flex-col rt--pr-20">
                    <b className="rt--title rt--font-normal rt--font-regular rt--mt-6">{t("backoffice.invoices.selectedWallet")}:</b>
                </div>
                <div className="rt--flex rt--flex-col rt--pr-20">
                    {
                        walletDetails.map(wallet => (
                            <b className="rt--title rt--font-normal rt--font-bold rt--mt-6" key={wallet.currencyCode}>{wallet.currencyCode}</b>
                        ))
                    }
                </div>
                <div className="rt--flex rt--flex-col rt--pr-20">
                    {
                        walletDetails.map(wallet => (
                            <b className="rt--title rt--font-normal rt--font-regular rt--mt-6" key={wallet.currencyCode}>{`${t("backoffice.currencies.rate")}: ${(wallet.currencyRate/wallet.systemRate).toFixed(2)}`} </b>
                        ))
                    }
                </div>
                <div className="rt--flex rt--flex-col rt--pr-20">
                    {
                        walletDetails.map(wallet => (
                            <b className="rt--title rt--font-normal rt--font-regular rt--mt-6" key={wallet.currencyCode}>{`${t("backoffice.invoices.convertedAmount")}: ${formatAmount(wallet.convertedAmount, currencyCode)}`}</b>
                        ))
                    }
                </div>
                <div className="rt--flex rt--flex-col rt--pr-20">
                    {
                        walletDetails.map(wallet => (
                            <b className="rt--title rt--font-normal rt--font-regular rt--mt-6" key={wallet.currencyCode}>
                                {
                                    `${t("backoffice.invoices.originalAmount")}: `
                                }
                                <span className="rt--success-text">{wallet.amount}</span>
                            </b>
                        ))
                    }
                </div>
            </div>
        )
    }

    const renderForMobile = () => {
        return (
            <div className="rt--invoice-details-list">
                {
                    walletDetails.map(wallet => (
                        <Fragment key={wallet.currencyCode}>
                            <div className="rt--invoice-details-list-item rt--flex">
                                <b className="rt--title rt--font-big rt--font-regular">{t("backoffice.invoices.selectedWallet")}:</b>
                                <span className="rt--title rt--font-big rt--font-medium">{wallet.currencyCode}</span>
                            </div>
                            <div className="rt--invoice-details-list-item rt--flex">
                                <b className="rt--title rt--font-big rt--font-regular">{t("backoffice.currencies.rate")}:</b>
                                <span className="rt--title rt--font-big rt--font-medium">{(wallet.currencyRate/wallet.systemRate).toFixed(2)}</span>
                            </div>
                            <div className="rt--invoice-details-list-item rt--flex">
                                <b className="rt--title rt--font-big rt--font-regular">{t("backoffice.invoices.convertedAmount")}:</b>
                                <span className="rt--title rt--font-big rt--font-medium">{formatAmount(wallet.convertedAmount, currencyCode)}</span>
                            </div>
                            <div className="rt--invoice-details-list-item rt--flex">
                                <b className="rt--title rt--font-big rt--font-regular">{t("backoffice.invoices.originalAmount")}:</b>
                                <span className="rt--title rt--font-big rt--font-medium rt--success-text">{wallet.amount}</span>
                            </div>
                        </Fragment>
                    ))
                }
            </div>
        )
    }

    if(!showWalletDetails) {
        return null;
    }

    return (
        <div>
            <b className="rt--title rt--font-bold rt--font-normal rt--invoice-details-title">
                {
                    t("backoffice.invoices.converted")
                }
            </b>
            {
                isMobile() ? renderForMobile() : renderForDesktop()
            }
        </div>
    )
}

export default Convertions;