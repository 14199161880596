import Hotjar from '@hotjar/browser';

class HotjarService{
    static _isInitied = false;

    static _version = "6"

    static init(){
        HotjarService._isInitied = Hotjar.init(import.meta.env.SYSTEM_HOTJAR_ID, HotjarService._version, {
            debug: false
        });
    }

    static identify(userId, userInfo) {
        if (HotjarService._isInitied) {
            Hotjar.identify(userId, userInfo);
        }
    }
}

export default HotjarService;