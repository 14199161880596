import { OPERATIONAL_ENTITY_TYPE } from "pages/dashboard/constants";

export const PLAYER_COUNT_TEXT_MAPPING = {
    [OPERATIONAL_ENTITY_TYPE.PLAYER] : "backoffice.dashboard.totalPlayerCount",
    [OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT] : "backoffice.dashboard.activeForDeposit",
    [OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_BET] : "backoffice.dashboard.activeForBet"
}

export const PLAYER_COUNT_DESCRIPTION_MAPPING = {
    [OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT] : "backoffice.dashboard.activeForDepositDescription",
    [OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_BET] : "backoffice.dashboard.activeForBetDescription"
}

export const PLAYER_COUNT_ICON_MAPPING = {
    [OPERATIONAL_ENTITY_TYPE.PLAYER] : "playerCount",
    [OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT] : "deposit",
    [OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_BET] : "ticket"
}