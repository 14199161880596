import React from "react";

export const getTableColumns = additionalProps => {

    const {
        formatAmount,
        isAffiliateUserNameClickable,
        onAffiliateClick
    } = additionalProps;

    return [
        {
            title: "backoffice.invoices.invoiceId",
            dataIndex: "id",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.invoices.affiliateId",
            dataIndex: "affiliateId",
            copy: true
        },
        {
            title: "backoffice.invoices.username",
            dataIndex: "affiliateUserName",
            sorting: true,
            alwaysVisible: true,
            mobileLevel: 2,
            render: (value, record) => {
                if (isAffiliateUserNameClickable) {
                    return (
                        <span className="rt--link" onClick={() => onAffiliateClick(record)}>{value}</span>
                    )
                }

                return value;
            }
        },
        {
            title: "backoffice.invoices.amount",
            dataIndex: "amount",
            sorting: true,
            mobileLevel: 3,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title: "backoffice.invoices.currency",
            dataIndex: "currencyCode",
            sorting: true,
            mobileLevel: 4
        },
        {
            title: "backoffice.invoices.paymentMethod",
            dataIndex: "paymentName",
            sorting: true,
            mobileLevel: 5
        },
        {
            title: "backoffice.invoices.commissionAmount",
            dataIndex: "paymentCommissionAmount",
            mobileLevel: 6,
            render: (value, record) => formatAmount(value, record.currencyCode)
        },
        {
            title:"backoffice.invoices.createdDate",
            dataIndex: "createdAt",
            isDateTime: true,
            sorter: true
        },
        {
            title:"backoffice.invoices.createdBy",
            dataIndex: "createdBy",
            sorter: true
        }
    ]
}