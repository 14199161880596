import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

const TINYMCE_SCRIPT_URL = `${import.meta.env.SYSTEM_CDN_URL}/system/static/js/tinymce.min.js`

const RichEditor = ({
    onChange,
    height = 500,
    initialContent,
    enforceCharacterLimit = false, // Default to false
    ...rest
}) => {

    const editorRef = useRef(null);

    const [characterCount, setCharacterCount] = useState(initialContent ? initialContent.length : 0);

    useEffect(() => {
        setTimeout(() => {
            setCharacterCount(editorRef.current?.getContent({ format: 'text' }).length)
        }, 100)
    }, [initialContent]); // Empty dependency array ensures the effect runs only once, when the component mounts

    const handleEditorChange = (content, editor) => {
        const currentLength = editor.getContent({ format: 'text' }).length;
        setCharacterCount(currentLength);
        console.log(`Character count: ${currentLength}`);
        onChange(content, editor);
    };

    return (
        <div>
            <Editor
                tinymceScriptSrc={TINYMCE_SCRIPT_URL}
                onEditorChange={handleEditorChange}
                init={{
                    height: height,
                    menubar: false,
                    branding: false,
                    target_list: false,
                    default_link_target: "_blank",
                    link_title: false,
                    relative_urls: false,
                    max_chars_indicator: ".maxCharsSpan",
                    elementpath: false,
                    directionality: 'auto',
                    document_base_url: "/",
                    max_chars: enforceCharacterLimit == true ? "1000" : undefined,
                    setup: enforceCharacterLimit ? (ed) => {
                        editorRef.current = ed;
                        ed.on('KeyDown', (evt) => {
                            const currentLength = ed.getContent({ format: 'text' }).length;
                            const maxLength = parseInt(ed.getParam('max_chars'));
                            if (currentLength >= maxLength && evt.keyCode !== 8 && evt.keyCode !== 46) {
                                evt.preventDefault();
                                evt.stopPropagation();
                                return false;
                            }
                        });

                        ed.on('Paste', (evt) => {
                            const pastedText = (evt.clipboardData || window.clipboardData).getData('text');
                            const currentLength = ed.getContent({ format: 'text' }).length;
                            const maxLength = parseInt(ed.getParam('max_chars'));
                            const totalLength = currentLength + pastedText.length;

                            if (totalLength > maxLength) {
                                evt.preventDefault();
                                evt.stopPropagation();
                                return false;
                            }
                        });
                    } : undefined,
                    toolbar: 'undo redo | formatselect | fontsize bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | forecolor backcolor'
                }}
                initialValue={initialContent}
                {...rest}
            />
            {enforceCharacterLimit && <div className='rt--editor-count'>{characterCount} / 1000</div>}
        </div>
    );
};

RichEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    height: PropTypes.number,
    initialContent: PropTypes.string,
    enforceCharacterLimit: PropTypes.bool
};

export default RichEditor;
