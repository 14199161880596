
import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { Collapse } from "antd";

import Table from 'components/common/table';
import Icon from 'components/common/icon';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { getTableColumns } from './columns';

import useFormat from "hooks/useFormat"
import useAccess from "core/hooks/useAccess";

const TotalsComponent = ({
    totals,
    currencyCode
}) => {

    const { t } = useTranslation();

    const { formatAmount, formatCurrencyWithSymbol } = useFormat();

    const { hasAccess } = useAccess()

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                formatAmount,
                formatCurrencyWithSymbol,
                currencyCode,
                hasAccess,
                t
            },
            t
        });
    }, [currencyCode, hasAccess, t])

    const columns = useMemo(() => {
        return mainTableColumns.map((c, index) => ({
            ...c,
            mobileLevel: index < 6 ? index + 1 : undefined
        }))
    }, [mainTableColumns])


    if (Object.keys(totals).length === 0 || columns.length === 0) {
        return <Fragment />
    }

    return (
        <Collapse
            className="rt--collapse rt--collapse-totals"
        >
            <Collapse.Panel
                showArrow={false}
                header={
                    <div className="rt--flex rt--width-full rt--align-center rt--justify-between">
                        <div className="rt--flex rt--align-center rt--justify-between rt--collapse-header">
                            <Icon name="down" className='rt--mr-2' />

                            <span className="rt--title rt--font-normal rt--font-bold rt--pl-4 rt--pr-4">
                                {t('backoffice.common.total')}
                            </span>
                        </div>
                    </div>
                }
                key="*"
            >
                <Table
                    columns={columns}
                    data={[{...totals, id: "id"}]}
                    disableFullView={columns.length < 6}
                    noPagination={true}
                />
            </Collapse.Panel>
        </Collapse>
    );
}

/** TotalsComponent propTypes
 * PropTypes
*/
TotalsComponent.propTypes = {
    /** Report totals */
    totals: PropTypes.object,
    /** CurrencyCode */
    currencyCode: PropTypes.string
};

export default TotalsComponent;