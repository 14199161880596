import { INPUT_REGEX, PROMO_SECTION_TYPE, SECTION_NAMES } from "constants/promo.constants";

export const getTableColumns = (additionalProps) => {
    const {
        savePromoSectionTranslations,
        resetPromoSectionTranslations,
    } = additionalProps;

    return [
        {
            title: "backoffice.promo.title",
            dataIndex: "title",
            editFieldClassName: "rt--table-col-editable-width-200",
            editable: true,
            maxLength: 30,
            regex: INPUT_REGEX,
            enableReset: (record) => record.titleIsOverwritten,
            onReset: (record) => {
                resetPromoSectionTranslations(
                    SECTION_NAMES.COMMISSION,
                    PROMO_SECTION_TYPE.COMMISSION,
                    record.titleTranslationKey
                );
            },
            onEdit: (value, record) => {
                savePromoSectionTranslations(
                    SECTION_NAMES.COMMISSION,
                    PROMO_SECTION_TYPE.COMMISSION,
                    record.titleTranslationKey,
                    value,
                );
            }
        },
        {
            title: "backoffice.promo.description",
            dataIndex: "description",
            editFieldClassName: "rt--table-col-editable-width-200",
            editable: true,
            maxLength: 1000,
            regex: INPUT_REGEX,
            enableReset: (record) => record.descriptionIsOverwritten,
            onReset: (record) => {
                resetPromoSectionTranslations(
                    SECTION_NAMES.COMMISSION,
                    PROMO_SECTION_TYPE.COMMISSION,
                    record.descriptionTranslationKey
                );
            },
            onEdit: (value, record) => {
                savePromoSectionTranslations(
                    SECTION_NAMES.COMMISSION,
                    PROMO_SECTION_TYPE.COMMISSION,
                    record.descriptionTranslationKey,
                    value,
                );
            }
        },
        {
            title: "backoffice.promo.subDescription",
            dataIndex: "subDescription",
            editFieldClassName: "rt--table-col-editable-width-200",
            editable: true,
            maxLength: 300,
            regex: INPUT_REGEX,
            enableReset: (record) => record.subDescriptionIsOverwritten,
            onReset: (record) => {
                resetPromoSectionTranslations(
                    SECTION_NAMES.COMMISSION,
                    PROMO_SECTION_TYPE.COMMISSION,
                    record.subDescriptionTranslationKey
                );
            },
            onEdit: (value, record) => {
                savePromoSectionTranslations(
                    SECTION_NAMES.COMMISSION,
                    PROMO_SECTION_TYPE.COMMISSION,
                    record.subDescriptionTranslationKey,
                    value,
                );
            }
        },
    ]
}