import React from "react";
import PropTypes from 'prop-types';

import RequisiteDetails from "./requisiteDetails";
import Convertions from "./convertions"

/** Invoice Details Component */
const InvoiceDetails = ({
    details,
    walletDetails,
    currencyCode,
    paymentId
}) => {

    return (
        <div className="rt--invoice-details">
            <RequisiteDetails 
                details={details}
                paymentId={paymentId}
            />
            <Convertions 
                walletDetails={walletDetails}
                currencyCode={currencyCode}
            />
        </div>
    );
};

/** InvoiceDetails propTypes
 * PropTypes
*/
InvoiceDetails.propTypes = {
    /** Details */
    details: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
    })),
    /** Payment Id */
    paymentId: PropTypes.string
};

export default InvoiceDetails
