import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin } from 'antd';

import {
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
} from 'store/actions/portal/promoCustomize/sections.action';

import InputWithAdditionalActions from 'components/common/InputWithAdditionalActions';

import {
    PROMO_SECTION_TYPE,
    SECTION_NAMES,
} from 'constants/promo.constants';

const FORM_FIELD_NAMES = {
    TITLE: "Title",
    DESCRIPTION: "Description",
    KEYWORDS: "Keywords",
}

const SEO = ({
    isLoading,
    isSaving,
    getPromoSectionTranslations,
    savePromoSectionTranslations,
    resetPromoSectionTranslations,
    translations,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { setFieldsValue } = formInstance;

    // Get SEO Translations
    useEffect(() => {
        getPromoSectionTranslations(
            SECTION_NAMES.SEO,
            PROMO_SECTION_TYPE.SEO,
        );
    }, [customizeLanguage]);

    // Set Fields Value When Translations is Loaded
    useEffect(() => {
        setFieldsValue({
            [FORM_FIELD_NAMES.TITLE]: translations[FORM_FIELD_NAMES.TITLE]?.translation,
            [FORM_FIELD_NAMES.DESCRIPTION]: translations[FORM_FIELD_NAMES.DESCRIPTION]?.translation,
            [FORM_FIELD_NAMES.KEYWORDS]: translations[FORM_FIELD_NAMES.KEYWORDS]?.translation,
        })
    }, [translations]);

    return (
        <Spin spinning={isLoading || isSaving}>
            <div>
                <Form
                    className="rt--form"
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        [FORM_FIELD_NAMES.TITLE]: translations[FORM_FIELD_NAMES.TITLE]?.translation,
                        [FORM_FIELD_NAMES.DESCRIPTION]: translations[FORM_FIELD_NAMES.DESCRIPTION]?.translation,
                        [FORM_FIELD_NAMES.KEYWORDS]: translations[FORM_FIELD_NAMES.KEYWORDS]?.translation,
                    }}
                    onValuesChange={(_, formValues) => {
                        // sendToPromoIframe(PROMO_UPDATE_TYPE.FONT_FAMILY, { fontFamily: formValues['fontFamily'] });
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.TITLE}
                                label={`${t('backoffice.promo.title')} *`}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 100, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.title')}`}
                                    isOverwritten={translations[FORM_FIELD_NAMES.TITLE]?.isOverwritten}
                                    initialValue={translations[FORM_FIELD_NAMES.TITLE]?.translation}
                                    maxLength={100}
                                    onConfirm={(value) => {
                                        savePromoSectionTranslations(
                                            SECTION_NAMES.SEO,
                                            PROMO_SECTION_TYPE.SEO,
                                            FORM_FIELD_NAMES.TITLE,
                                            value
                                        )
                                    }}
                                    onReset={() => {
                                        resetPromoSectionTranslations(
                                            SECTION_NAMES.SEO,
                                            PROMO_SECTION_TYPE.SEO,
                                            FORM_FIELD_NAMES.TITLE,
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.DESCRIPTION}
                                label={`${t('backoffice.promo.description')} *`}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 300, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    type='textArea'
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.description')}`}
                                    isOverwritten={translations[FORM_FIELD_NAMES.DESCRIPTION]?.isOverwritten}
                                    maxLength={300}
                                    rows={4}
                                    resizable={false}
                                    initialValue={translations[FORM_FIELD_NAMES.DESCRIPTION]?.translation}
                                    onConfirm={(value) => {
                                        savePromoSectionTranslations(
                                            SECTION_NAMES.SEO,
                                            PROMO_SECTION_TYPE.SEO,
                                            FORM_FIELD_NAMES.DESCRIPTION,
                                            value
                                        )
                                    }}
                                    onReset={() => {
                                        resetPromoSectionTranslations(
                                            SECTION_NAMES.SEO,
                                            PROMO_SECTION_TYPE.SEO,
                                            FORM_FIELD_NAMES.DESCRIPTION,
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={`${t('backoffice.promo.keywords')} *`}
                                name={FORM_FIELD_NAMES.KEYWORDS}
                                className='rt--form-item-without-margin rt--general-form-item'
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 300, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                            >
                                <InputWithAdditionalActions
                                    type="textArea"
                                    maxLength={300}
                                    rows={4}
                                    resizable={false}
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.promo.buttonText')}`}
                                    isOverwritten={translations[FORM_FIELD_NAMES.KEYWORDS]?.isOverwritten}
                                    initialValue={translations[FORM_FIELD_NAMES.KEYWORDS]?.translation}
                                    onConfirm={(value) => {
                                        savePromoSectionTranslations(
                                            SECTION_NAMES.SEO,
                                            PROMO_SECTION_TYPE.SEO,
                                            FORM_FIELD_NAMES.KEYWORDS,
                                            value
                                        )
                                    }}
                                    onReset={() => {
                                        resetPromoSectionTranslations(
                                            SECTION_NAMES.SEO,
                                            PROMO_SECTION_TYPE.SEO,
                                            FORM_FIELD_NAMES.KEYWORDS,
                                        )
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Spin >
    );
}

/** SEO propTypes
 * PropTypes
*/
SEO.propTypes = {
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when promo saving */
    isSaving: PropTypes.bool,
    /** Redux action to get promo section translations */
    getPromoSectionTranslations: PropTypes.func,
    /** Redux action to save promo section translations */
    savePromoSectionTranslations: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to reset promo section translations */
    resetPromoSectionTranslations: PropTypes.func,
    /** Redux state property, translations data */
    translations: PropTypes.objectOf(PropTypes.shape({
        isOverwritten: PropTypes.bool,
        key: PropTypes.string,
        translation: PropTypes.string,
    }))
};

const mapDispatchToProps = dispatch => ({
    getPromoSectionTranslations: (sectionName, sectionType) => {
        dispatch(getPromoSectionTranslations(sectionName, sectionType))
    },
    savePromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(savePromoSectionTranslations(sectionName, sectionType, key, text))
    },
    resetPromoSectionTranslations: (sectionName, sectionType, key, text) => {
        dispatch(resetPromoSectionTranslations(sectionName, sectionType, key, text))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.promoCustomize.customizeLanguage,
        isLoading: state.promoCustomize.isLoading,
        isSaving: state.promoCustomize.isSaving,
        translations: state.promoCustomize.sections[PROMO_SECTION_TYPE.SEO].translations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SEO);