import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from 'react-router-dom';

import { Form, Col, Row, Radio } from 'antd';

import Modal from "components/common/modal";
import Select from 'components/common/select';

import { getAvailableAffiliates, resetAvailableAffiliates } from "store/actions/portal/affiliates/groups/groups.action";
import {
    getAffiliateGroupFilter,
    saveAffiliateGroupFilter,
    saveAffiliateGroupMembers,
} from "store/actions/portal/affiliates/groups/members.action";

import { POPUP_SIZE } from 'constants/common.constants';
import { AFFILIATE_GROUP_TYPE, TARGET_MARKET, AFFILIATE_GROUP_OPERATION_TYPE } from 'constants/affiliate.constants';
import { USER_STATE } from 'constants/user.constants';

import affiliateType from "types/affiliate/generalInfo.type";

import countries from 'core/systemData/countries';

/** Affiliate Group Members Modify Popup Component */
const MembersModifyComponent = ({
    isSaving,
    availableAffiliates,
    getAvailableAffiliates,
    resetAvailableAffiliates,
    getAffiliateGroupFilter,
    saveAffiliateGroupFilter,
    saveAffiliateGroupMembers,
    groupFilter,
    total,
    groupType,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue, setFieldsValue } = formInstance;

    const searchParams = useParams();

    const [operationType, setOperationType] = useState(AFFILIATE_GROUP_OPERATION_TYPE.ADD);
    const [isFormTouched, setIsFormTouched] = useState(groupType !== AFFILIATE_GROUP_TYPE.DYNAMIC);

    /** Fires when form submitted
       * @function
       * @memberOf MembersModifyComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                if(groupType === AFFILIATE_GROUP_TYPE.DYNAMIC){
                    saveAffiliateGroupFilter(searchParams.id, data.filter, onClose);
                } else if(groupType === AFFILIATE_GROUP_TYPE.STATIC){
                    saveAffiliateGroupMembers(searchParams.id, availableAffiliates.map(a => a.id), operationType, onClose);
                }
            }).catch(Function.prototype)
    }

    /** Fires when form submitted
       * @function
       * @memberOf MembersModifyComponent
    */
    const handleFilterChange = () => {
        const filters = getFieldValue("filter") || {};
        if(groupType === AFFILIATE_GROUP_TYPE.STATIC){
            filters.operationType = operationType;
            filters.groupId = searchParams.id;
        }
        getAvailableAffiliates(filters);
    }

    /** Reset available affiliates, on component unmount */
    useEffect(() => {
        return () => {
            resetAvailableAffiliates()
        }
    }, [])

    /** Get Filters for dynamic group */
    useEffect(() => {
        if(groupType === AFFILIATE_GROUP_TYPE.DYNAMIC){
            getAffiliateGroupFilter(searchParams.id)
        }
    }, [])

    /** Set filter default values */
    useEffect(() => {
        if(groupType === AFFILIATE_GROUP_TYPE.DYNAMIC){
            setFieldsValue({
                filter: groupFilter
            });

            getAvailableAffiliates(groupFilter);
        }
    }, [groupFilter])


    useEffect(() => {
        if(groupType === AFFILIATE_GROUP_TYPE.STATIC){
            handleFilterChange();
        }
    }, [operationType])

    return (
        <Modal
            title={t('backoffice.affiliates.modifyMembers')}
            cancelText={t('backoffice.common.cancel')}
            okText={
                groupType == AFFILIATE_GROUP_TYPE.DYNAMIC ? t('backoffice.common.save') :
                    operationType === AFFILIATE_GROUP_OPERATION_TYPE.ADD ? t('backoffice.common.add') : t('backoffice.common.remove')
            }
            onOk={handleForm}
            okButtonClassname={operationType === AFFILIATE_GROUP_OPERATION_TYPE.REMOVE ? "rt--button rt--button-inline rt--button-danger" : null}
            disableOkButton={ 
                !isFormTouched ||
                (groupType === AFFILIATE_GROUP_TYPE.STATIC && availableAffiliates.length === 0)
            }
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                onValuesChange={ groupType == AFFILIATE_GROUP_TYPE.DYNAMIC ?
                    (_, formValues) => {
                        setIsFormTouched(
                            groupFilter?.state !== formValues.filter?.state ||
                            groupFilter?.country !== formValues.filter?.country ||
                            groupFilter?.targetMarket !== formValues.filter?.targetMarket
                        )
                    } : undefined
                }
            >
                {
                    groupType === AFFILIATE_GROUP_TYPE.STATIC && (
                        <Row gutter={[16, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    className="rt--form-item-radio"
                                >
                                    <Radio.Group
                                        options={[
                                            { label: t('backoffice.common.add'), value: AFFILIATE_GROUP_OPERATION_TYPE.ADD },
                                            { label: t('backoffice.common.remove'), value: AFFILIATE_GROUP_OPERATION_TYPE.REMOVE },
                                        ]}
                                        onChange={e => setOperationType(e.target.value)}
                                        value={operationType}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }

                <Row gutter={[16, 0]}>
                    <Col span={24} lg={12}>
                        <Form.Item
                            label={t('backoffice.common.status')}
                            name={["filter", "state"]}
                        >
                            <Select
                                options={[
                                    { value: null, text: t('backoffice.common.none') },
                                    { value: USER_STATE.IN_PROGRESS, text: t('backoffice.affiliates.pending') },
                                    { value: USER_STATE.ACTIVE, text: t('backoffice.affiliates.approved') },
                                    { value: USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                    { value: USER_STATE.DECLINED, text: t('backoffice.common.declined') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                onChange={handleFilterChange}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={12}>
                        <Form.Item
                            label={t('backoffice.affiliates.country')}
                            name={["filter", "country"]}
                        >
                            <Select
                                options={
                                    [
                                        { value: null, text: t('backoffice.common.none') },
                                    ].concat(countries.map(c => ({
                                        value: c.iso2,
                                        text: t(`backoffice.countries.${c.iso2}`)
                                    })))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.country')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                onChange={handleFilterChange}
                                search={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={12}>
                        <Form.Item
                            label={t('backoffice.affiliates.targetMarket')}
                            name={["filter", "targetMarket"]}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                options={[
                                    { value: null, text: t('backoffice.common.none') },
                                    { value: TARGET_MARKET.EUROPE, text: t(`backoffice.affiliates.europe`) },
                                    { value: TARGET_MARKET.ASIA, text: t(`backoffice.affiliates.asia`) },
                                    { value: TARGET_MARKET.AFRICA, text: t(`backoffice.affiliates.africa`) },
                                    { value: TARGET_MARKET.NORTH_AMERICA, text: t(`backoffice.affiliates.northAmerica`) },
                                    { value: TARGET_MARKET.CENTRAL_AND_SOUTH_AMERICA, text: t(`backoffice.affiliates.centralAndSouthAmerica`) },
                                    { value: TARGET_MARKET.MIDDLE_EAST_NORTH_AFRICA, text: t(`backoffice.affiliates.middleEastNorthAfrica`) }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.targetMarket')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                onChange={handleFilterChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <div className='rt--info rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12 rt--mt-16'>
                            <div className="rt--flex rt--justify-end rt--align-center">
                                <span className='rt--pl-4 rt--pr-4 rt--text-light rt--font-small rt--font-regular'>{t('backoffice.affiliates.totalNumberOfAffiliates')}:</span>
                                <b className='rt--font-small rt--font-bold'>
                                    {
                                        groupType === AFFILIATE_GROUP_TYPE.STATIC ? total : availableAffiliates.length
                                    }
                                </b>
                            </div>
                            {
                                groupType === AFFILIATE_GROUP_TYPE.STATIC && (
                                    <div className="rt--flex rt--justify-end rt--align-center">
                                        <span className='rt--pl-4 rt--pr-4 rt--text-light rt--font-small rt--font-regular'>
                                            {
                                                operationType === AFFILIATE_GROUP_OPERATION_TYPE.ADD ? t('backoffice.affiliates.addedMembers') : t('backoffice.affiliates.removedMembers')
                                            }:
                                        </span>
                                        <b className={'rt--font-small rt--font-bold ' + (operationType === AFFILIATE_GROUP_OPERATION_TYPE.ADD ? "rt--success-text" : "rt--error-text")}>{availableAffiliates.length}</b>
                                    </div>
                                )
                            }

                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** MembersModifyComponent propTypes
    * PropTypes
*/
MembersModifyComponent.propTypes = {
    /** Redux state property, is true when modify members request is in process */
    isSaving: PropTypes.bool,
    /** Redux state property, available affiliates */
    availableAffiliates: PropTypes.arrayOf(affiliateType),
    /** Redux action to get available affiliates */
    getAvailableAffiliates: PropTypes.func,
    /** Redux action to reset available affiliates */
    resetAvailableAffiliates: PropTypes.func,
    /** Redux action to get affiliate group filter */
    getAffiliateGroupFilter: PropTypes.func,
    /** Redux action to save affiliate group filter */
    saveAffiliateGroupFilter: PropTypes.func,
    /** Redux action to save affiliate group members */
    saveAffiliateGroupMembers: PropTypes.func,
    /** Redux state property, current editing dynamic group filters */
    groupFilter: PropTypes.object,
    /** Total */
    total: PropTypes.number,
    /** Group Type */
    groupType: PropTypes.oneOf(Object.values(AFFILIATE_GROUP_TYPE)),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAvailableAffiliates: filters => {
            dispatch(getAvailableAffiliates(filters));
        },

        resetAvailableAffiliates: () => {
            dispatch(resetAvailableAffiliates());
        },

        getAffiliateGroupFilter: id => {
            dispatch(getAffiliateGroupFilter(id));
        },

        saveAffiliateGroupFilter: (id, filter, onSuccess) => {
            dispatch(saveAffiliateGroupFilter(id, filter, onSuccess));
        },

        saveAffiliateGroupMembers: (id, affiliates, operationType, onSuccess) => {
            dispatch(saveAffiliateGroupMembers(id, affiliates, operationType, onSuccess));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.affiliateGroups.isSaving,
        availableAffiliates: state.affiliateGroups.availableAffiliates,
        groupFilter: state.affiliateGroups.edit.members.groupFilter
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersModifyComponent)