import React from "react";

import Icon from "core/ui-kit/icon";

import { useTranslation } from "core/hooks/useTranslation";
import { PLAYER_COUNT_ICON_MAPPING, PLAYER_COUNT_TEXT_MAPPING, PLAYER_COUNT_DESCRIPTION_MAPPING } from "./constants";

const PlayerCount = ({ 
    count,
    entityType 
}) => {

    const { t } = useTranslation();

    return (

        <div className="rt--chart-operational-pie-block-total rt--flex rt--align-center">
            <div className="rt--flex rt--flex-col rt--pl-24 rt--pr-24">
                <span className="rt--chart-operational-pie-block-total-title rt--font-bold rt--font-normal">
                    { t(PLAYER_COUNT_TEXT_MAPPING[entityType]) }
                </span>
                <span className="rt--chart-operational-pie-block-total-count rt--mt-4">
                    { count }
                </span>
                {
                    PLAYER_COUNT_DESCRIPTION_MAPPING[entityType] && (
                        <span className="rt--chart-operational-pie-block-total-description rt--mt-6">
                            { t(PLAYER_COUNT_DESCRIPTION_MAPPING[entityType]) }
                        </span>
                    )
                }
                
            </div>
            <div className="rt--chart-operational-pie-block-total-count-icon rt--flex rt--align-center rt--justify-center" data-type={entityType}>
                <Icon name={PLAYER_COUNT_ICON_MAPPING[entityType]}/>
            </div>
        </div>

    );
};

export default PlayerCount;
