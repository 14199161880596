import React, { memo } from "react";
import PropTypes from "prop-types";

import { Spin } from "antd";

import PlayerCount from "../playerCount";

import { isMobile } from "utils/common";

import useOperationalWidget from "pages/dashboard/hooks/useOperationalWidget";

import { OPERATIONAL_ENTITY_TYPE } from "../../constants";

const OperationalWidget = ({
  dataType,
  updateLastUpdatedDate,
  isActive,
  filters
}) => {
  const { chartData, isLoading } = useOperationalWidget(
        dataType,
        filters.period,
        updateLastUpdatedDate,
        isActive,
        filters.affiliateId,
  );
  return (
        <div className="rt--chart-operational-pie rt--chart-section">
            <div
                className={
                    "rt--chart-operational-pie-block" +
                    (isMobile() ? " rt--flex rt--flex-col" : "")
                }
            >
                {
                    isLoading ? (
                        <Spin spinning={isLoading} />
                    ) : (
                        <PlayerCount count={chartData?.count ?? 0} entityType={dataType} />
                    )
                }
            </div>
        </div>
  );
};

OperationalWidget.propTypes = {
    dataType: PropTypes.oneOf(Object.values(OPERATIONAL_ENTITY_TYPE)),
    updateLastUpdatedDate: PropTypes.func,
    isActive: PropTypes.bool,
    filters: PropTypes.object
};

export default memo(OperationalWidget);
