import BaseStore from "core/lib/baseStore";
import initialState from "core/stores/notificationsStore/initialState";
import notificationsService from "core/services/apiServices/notificationsService";
import {notificationState} from "core/layouts/main/portal/header/notifications/constants";

class NotificationsStore extends BaseStore {
    constructor () {
        super(initialState);
    }

    mergeNotifications(existing, newItems) {
        const existingIds = new Set(existing.map((item) => item.id));
        const filteredNewItems = newItems.filter((item) => !existingIds.has(item.id));
        return [...existing, ...filteredNewItems];
    }

    getNotifications(params) {
        return notificationsService
            .getNotifications(params)
            .then((response) => {
                const {
                    unreadCount: totalUnreadNotificationCount,
                    data: notifications,
                    total
                } = response;

                this.state.notifications = this.mergeNotifications(this.state.notifications, notifications);
                this.state.unreadTotal = totalUnreadNotificationCount;
                this.state.total = total;
            });
    }

    getUnreadNotifications(params) {
        return notificationsService
            .getNotifications({
                ...params,
                state: notificationState.unreaded,
            })
            .then((response) => {
                const {
                    unreadCount: totalUnreadNotificationCount,
                    data: unreadNotifications,
                } = response;

                this.state.unreadNotifications = this.mergeNotifications(this.state.unreadNotifications, unreadNotifications);
                this.state.unreadTotal = totalUnreadNotificationCount;
            });
    }

    unShiftNotification(notification) {
        this.state.notifications = [notification, ...this.state.notifications];
        this.state.unreadNotifications = [notification, ...this.state.unreadNotifications];
        this.state.unreadTotal = ++this.state.unreadTotal;
        this.state.total = ++this.state.total;
        if (this.state.isSoundEnabled) {
            this.playSound();
        }
    }

    toggleSound() {
        this.state.isSoundEnabled = !this.state.isSoundEnabled;
    }

    playSound() {
        this.state.playSound = true;
    }

    stopSound() {
        this.state.playSound = false;
    }

    reset() {
        this.state = initialState;
    }

    resetUnread() {
        this.state.unreadNotifications = [];
    }

    resetAll() {
        this.state.notifications = [];
    }

    markAllAsRead() {
        this.state.unreadTotal = 0;
        this.state.unreadNotifications = [];
        const arr = [...this.state.notifications];
        this.state.notifications = arr.map((item) => {
            item.state = notificationState.readed;
            return item;
        })
    }

    destroyAll() {
        this.state.notifications = [];
        this.state.total = 0;
    }

    destroyUnread() {
        this.state.unreadNotifications = [];
    }

    removeNotification(id, type, state) {
        if (type === 'all') {
            const arr = [...this.state.notifications];
            this.state.notifications = [...arr.filter((item) => item.id !== id)];
            this.state.total = this.state.total - 1;
        }
        if (type === 'unread') {
            const arr = [...this.state.unreadNotifications];
            this.state.unreadNotifications = [...arr.filter((item) => item.id !== id)];
        }
        if (state === notificationState.unreaded) {
            this.state.unreadTotal = this.state.unreadTotal - 1;
        }
    }

    markAsRead(id, type) {
        if (type === 'all') {
            const arr = [...this.state.notifications];
            this.state.notifications = arr.map((item) => {
                if (item.id === id) {
                    item.state = notificationState.readed;
                }
                return item;
            });
        }
        if (type === 'unread') {
            const arr = [...this.state.unreadNotifications];
            this.state.unreadNotifications = arr.filter((item) => item.id !== id);
        }
        this.state.unreadTotal = this.state.unreadTotal - 1;
    }
}

export default NotificationsStore;