import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useTranslation } from "core/hooks/useTranslation";
import useAccess from 'core/hooks/useAccess';

import { Row, Col, Form } from 'antd';

import {
    getPlayerReport,
    setPlayerReportFilters
} from 'store/actions/portal/reports/performance/playerReport.action';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useCurrencies from 'hooks/useCurrencies';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import Input from "components/common/input";
import AutoComplete from "components/common/autoComplete";

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { USER_ROLE } from 'constants/user.constants';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { ADDITIONAL_ACCESS_TYPE } from 'core/constants/additionalAccess';
import NumericInput from 'components/common/numericInput';

/** Player Report Page Filters Component */
const Filters = ({
    setPlayerReportFilters,
    getPlayerReport,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const role = useAuthSelector(userRoleSelector);

    const { hasAccess } = useAccess()

    const isAffiliate = role === USER_ROLE.AFFILIATE;

    const [ brands, getBrands ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BRAND, 
        autoGet: false
    });

    const [ affiliateNames, getAffiliateNames ] = !isAffiliate ? useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES, 
        autoGet: false
    }) : [ [] ];

    const [ playerNames, getPlayerNames, resetPlayerNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PLAYER, 
        autoGet: false
    });

    const [ currencies, getCurrencies ] = useCurrencies(false);

    const handlePlayersChange = v => {
        if(v?.length >= 3){
            getPlayerNames({"userNameOrId" : v})
        } else if(v?.length === 0){
            resetPlayerNames();
        }
    }

    return (
        <FiltersWrapper
            loadFn={getPlayerReport}
            setFiltersFn={setPlayerReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName={["date", "externalId"]}
            onInit={() => {
                getBrands();
                getAffiliateNames?.();
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>

                <Col span={24}>
                    {
                        hasAccess(ADDITIONAL_ACCESS_TYPE.PLAYER_USERNAME) ? (
                            <Form.Item
                                label={t(`backoffice.reports.playerUsername`)}
                                name="userNameOrId"
                            >
                                <AutoComplete
                                    placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.player')}`}
                                    items={playerNames}
                                    disableFiltering={true}
                                    changeHandler={handlePlayersChange}
                                    valueMapper={(items, value) => {

                                        const foundItem = items.find(item => item.longId?.toString() === value?.toString());

                                        if (foundItem) {
                                            return foundItem.name;
                                        }

                                        return value;
                                    }}

                                    valueGetter={(items, value) => {
                                        const searchedItem = value !== "" ? items.find(item => (
                                            item.longId?.toString() === value ||
                                            item.name?.toLowerCase() === value.toLowerCase()
                                        )) || null : null;

                                        return searchedItem?.longId ?? value;
                                    }}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                label={t(`backoffice.reports.playerId`)}
                                name="userNameOrId"
                            >
                                <NumericInput 
                                    placeholder={`${t("backoffice.common.enter")} ${t('backoffice.reports.playerId')}`}
                                    maxLength={12}
                                />
                            </Form.Item>
                        )
                    }
                    
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.btag')}
                        name="btag"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.btag')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.reports.btag')}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.currency')}
                        name="currencyCode"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    currencies.map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.reports.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.reports.brand')}
                        name="brandId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    brands.map(brand => ({
                                        value: brand.id,
                                        text: brand.name
                                    }))
                                )
                            }
                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.brand')}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                {
                    !isAffiliate && (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.reports.affiliateUsername')}
                                name="affiliateId"
                            >
                                <Select
                                    options={
                                        [
                                            { value: "", text: t('backoffice.common.all') }
                                        ].concat(
                                            affiliateNames.map(affiliate => ({
                                                value: affiliate.id,
                                                text: affiliate.name
                                            }))
                                        )
                                    }
                                    placeholder={`${t("backoffice.common.select")} ${t('backoffice.reports.affiliateUsername')}`}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get player report */
    getPlayerReport: PropTypes.func,
    /** Redux action to set player report filters */
    setPlayerReportFilters: PropTypes.func,
    /** Redux state property, player report filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerReport: () => {
            dispatch(getPlayerReport())
        },
        setPlayerReportFilters: (filters, keepPage) => {
            dispatch(setPlayerReportFilters(filters, keepPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.performanceReports.playerReport.filters,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);