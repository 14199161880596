import React from "react";

import Icon from "core/ui-kit/icon";

const Alert = ({
    type,
    message,
    description,
    className=""
}) => {
    return (
        <div className={`rt--alert rt--alert-${type} rt--pl-12 rt--pr-12 rt--pt-8 rt--pb-8 rt--flex rt--justify-between ${className}`}>
            <div className="rt--alert-icon">
                <Icon name={type} size={18} />
            </div>
            <div className="rt--alert-content rt--ml-8 rt--flex-equal">
                <div className="rt--alert-title rt--pb-6">{message}</div>
                <div className="rt--alert-description">{description}</div>
            </div>
        </div>
    )
}

export default Alert;