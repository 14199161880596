export const LINE_CHART_DEFAULT_CONFIGS = {
    chart: {
        type: 'areaspline',
        events: {
            load: function() {
              var chart = this,
                yAxis = chart.yAxis[0],
                yExtremes = yAxis.getExtremes(),
                newMin = yExtremes.dataMin - 0,
                newMax = yExtremes.dataMax + 50;
  
               yAxis.setExtremes(newMin, newMax, true, true);
            }
        }
    },

    boost: {
        useGPUTranslations: true,
        seriesThreshold: 1,
    },

    accessibility: {
        enabled: false
    },

    exporting: {
        enabled: false
    },
    
    credits: {
        enabled: false
    },

    legend: {
        enabled: true,
    },

    title: {
        text: '',
    },

    plotOptions: {
        series: {
            events: {
                legendItemClick: function (e) {
                    if (this.chart.series.length === 1) {
                        e.preventDefault();
                    } else {
                       return true
                    }
                }
            },
            pointPlacement: "on",
            states: {
                hover: {
                    halo: {
                        size: 0
                    }
                }
            },
        }
    },
}

export const LINE_CHART_AVAILABLE_COLOR = {
    WHITE: "rgba(255, 255, 255, 1)",
    BLUE: "rgba(23, 150, 236, 1)",
    LIGHT_GREEN: "rgba(86, 239, 195, 1)",
    GREEN: "rgba(1, 184, 148, 1)",
    GRAY: "rgba(160, 163, 189, 1)",
    PURPLE: "rgba(161, 100, 247, 1)",
    MAGENTA: "rgba(255, 0, 110, 1)",
    YELLOW: "rgba(255, 190, 12, 1)",
    ORANGE: "rgba(255, 154, 33, 1)",
    LIGHT_GREY: "rgba(238, 238, 238, 1)",
}

export const MARKET_SYMBOL_TYPES = {
    CIRCLE: "circle",
}
