import React, { useEffect } from 'react';
import NoNotificationsWidget from "../NoNotificationsWidget";
import Virtualized from "core/ui-kit/virtualized";
import NotificationItem from "../notificationItem";
import Spin from "core/ui-kit/spin";
import {minimumItemsCount, NOTIFICATIONS_TABS} from "../../constants";
import {notificationsStore} from "core/stores/notificationsStore";

const NotificationList = ({
    notifications = [],
    onLoadMore,
    type,
    onInitialLoad,
    totalCount,
    onDestroy,
    isLoading,
    removeNotification,
    markAsRead,
    globalProjectId,
    globalCompanyId,
    closeNotifications,
    operationalLoading,
}) => {
    const itemHeight = 145;
    const renderContent = (slicedNotifications) => {
        if (slicedNotifications?.length > 0 ) {
            return slicedNotifications.map(n => ({...n})).map((notification, _index) => {
                const {
                    action,
                    companyId,
                    id,
                    message,
                    projectId,
                    state,
                    time,
                    userId,
                    additionalData,
                } = notification;
                return (
                    <NotificationItem
                        key={id}
                        id={id}
                        height={itemHeight}
                        message={message}
                        action={action}
                        time={time}
                        removeNotification={removeNotification}
                        markAsRead={markAsRead}
                        type={type}
                        state={state}
                        companyId={companyId}
                        projectId={projectId}
                        userId={userId}
                        globalProjectId={globalProjectId}
                        globalCompanyId={globalCompanyId}
                        closeNotifications={closeNotifications}
                        affiliateId={additionalData?.affiliateId}
                        campaignId={additionalData?.campaignId}
                        operationalLoading={operationalLoading}
                    />
                )
            });
        }
    }

    useEffect(() => {
        if(notifications.length < totalCount &&  notifications.length < minimumItemsCount) {
            onInitialLoad();
        }
    }, [totalCount, notifications.length]);

    useEffect(() => {
        if (type === NOTIFICATIONS_TABS.UNREAD) {
            notificationsStore.resetUnread();
        } else {
            notificationsStore.resetAll();
        }
        onInitialLoad();
        return () => {
            onDestroy(type);
        }
    }, []);

    return (
        <div className="rt--notifications-content">
            {
                notifications.length === 0 ? (
                    <>
                        {
                            isLoading ? (
                                <div className="rt-notifications-loading-wrapper">
                                    <Spin />
                                </div>
                            ) : (
                                <NoNotificationsWidget />
                            )
                        }
                    </>
                ) : (
                    <Virtualized
                        itemHeight={itemHeight}
                        data={notifications}
                        renderItem={slice => renderContent(slice)}
                        onScrollEnd={onLoadMore}
                    />
                )
            }
        </div>
    )
}

export default NotificationList;
