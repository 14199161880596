import React, { memo, useState, useMemo } from "react";
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";
import { Tabs as AntTabs } from 'antd';

import LineChart from "components/common/lineChart";

import { OPERATIONAL_ENTITY_TYPE, LINE_CHART_CONFIGS_BY_ENTITY, LINE_CHART_ENTITY_TYPE } from "../../constants";
import { USER_ROLE } from "constants/user.constants";
import { ADDITIONAL_ACCESS_TYPE } from "core/constants/additionalAccess";

import { isMobile } from "utils/common";
import {
    getLineChartData,
    getOperationsLineChartTooltip
} from "../../helpers";
import { constructLineChartConfigs } from "utils/lineChart";
import {
    getCheckPointsTooltipConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";

import useOperationalLineChart from "../../hooks/useOperationalLineChart";
import useDate from "core/hooks/useDate";
import useAccess from "core/hooks/useAccess";

import { useAuthSelector, userRoleSelector } from "core/stores/authStore";
import OperationalWidget from "../operationalWidget";

const OperationalLineChart = ({
    updateLastUpdatedDate,
    filters
}) => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector);

    const { dateService } = useDate();
    const { hasAccess } = useAccess();

    const [entityType, setEntityType] = useState(OPERATIONAL_ENTITY_TYPE.PLAYER)

    const { chartData, isLoading } = useOperationalLineChart(
        entityType,
        filters.period,
        updateLastUpdatedDate,
        entityType !== OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT,
        filters?.affiliateId,
    );

    /** Chart Data */
    const configs = useMemo(() => {
        if(entityType == OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT) return {};

        const valuePropNames = [];
        if(entityType === OPERATIONAL_ENTITY_TYPE.PLAYER){
            valuePropNames.push(LINE_CHART_ENTITY_TYPE.REGISTRATIONS_COUNT)
        } else {
            valuePropNames.push(LINE_CHART_ENTITY_TYPE.CPA_COUNT);
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.FTD_COUNT)){
                valuePropNames.push(LINE_CHART_ENTITY_TYPE.FTD_COUNT)
            }
        }


        const charts = valuePropNames.map(valuePropName => (
            getLineChartData(chartData || [], filters.period, valuePropName, dateService, t)
        ))

        // Define names based on entityType
        const lineNames = [];
        if(entityType === OPERATIONAL_ENTITY_TYPE.PLAYER){
            lineNames.push(`<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.registration")}</span>`)
        } else {
            lineNames.push(`<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.qualifiedDepositors")}</span>`);
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.FTD_COUNT)){
                lineNames.push(`<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.ftds")}</span>`)
            }
        }

        const categories = charts[0].categories;

        return constructLineChartConfigs({
            xAxisConfigs: getXAxisConfigs({
                checkPoints: categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    getOperationsLineChartTooltip({ 
                        element, 
                        chartData: charts, 
                        period: filters.period, 
                        entityType, 
                        dateService,
                        t 
                    })
                )
            }),
            lines: charts.map((chart, index) => (
                getMainLineConfigs({
                    ...LINE_CHART_CONFIGS_BY_ENTITY[valuePropNames[index]],
                    data: chart.values,
                    name: lineNames[index]
                }) 
            )),
            height: !isMobile() ? 323 : undefined 
        })
    }, [chartData, filters.period, entityType, dateService, t])

    return (
        <div className={"rt--chart-section rt--pb-24 rt--pt-24 " + (isMobile() ? 'rt--pl-6 rt--pr-6' : 'rt--pl-24 rt--pr-24')}>
            <AntTabs
                animated={false}
                defaultActiveKey={OPERATIONAL_ENTITY_TYPE.PLAYER}
                onChange={value => setEntityType(Number(value))}
                destroyInactiveTabPane={true}
                className='rt--tabs rt--chart-tab'
            >
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.registration")}</span>}
                    key={OPERATIONAL_ENTITY_TYPE.PLAYER}
                >
                    <LineChart
                        configs={configs}
                        hideChart={chartData.length === 0}
                        isLoading={isLoading}
                    />
                </AntTabs.TabPane>

                {
                    userRole !== USER_ROLE.AFFILIATE && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.depositCount")}</span>}
                            key={OPERATIONAL_ENTITY_TYPE.DEPOSIT_COUNT}
                        >
                            <LineChart
                                configs={configs}
                                hideChart={chartData.length === 0}
                                isLoading={isLoading}
                            />
                        </AntTabs.TabPane>
                    )
                }

                {
                    isMobile() && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.activePlayers")}</span>}
                            key={OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT}
                        >
                           <div className='rt--pt-20 rt--pb-20 rt--pl-10 rt--pr-10'>
                                <div className='rt--mb-16'>
                                    <OperationalWidget
                                        dataType={OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        filters={filters}
                                    />
                                </div>
                                <div>
                                    <OperationalWidget
                                        dataType={OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_BET}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        filters={filters}
                                    />
                                </div>
                            </div>
                        </AntTabs.TabPane>
                    )
                }
                
            </AntTabs>
        </div>
    )
}

OperationalLineChart.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
    filters: PropTypes.object
};

export default memo(OperationalLineChart);