import React, {useEffect, useMemo, useState} from 'react';
import Select from 'components/common/select';
import {useTranslation} from 'core/hooks/useTranslation';
import autosuggestionService from 'core/services/apiServices/autosuggestionService';
import {globalProjectIdSelector, useAuthSelector} from "core/stores/authStore";

const FilterByAffiliate = ({
minWidth = '151px',
value = '',
onChange,
}) => {
    const { t } = useTranslation();
    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [affiliatesAutosuggestion, setAffiliatesAutosuggestion] = useState([]);

    const affiliatesOptions = useMemo(() => {
        return affiliatesAutosuggestion.map((o) => {
            return { text: o.name, value: o.id }
        });
    }, [affiliatesAutosuggestion]);

    useEffect(() => {
        autosuggestionService.getAffiliates().then((value) => setAffiliatesAutosuggestion(value));
    }, [globalProjectId]);

    return (
        <div>
            <Select
                style={{ minWidth }}
                value={value}
                options={[
                    {value: '', text: `${t('backoffice.common.all')} ${t('backoffice.common.affiliates')}`},
                    ...affiliatesOptions
                ]}
                onChange={onChange}
                search
            />
        </div>
    )
}

export default FilterByAffiliate;
