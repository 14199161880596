import React, { useRef, useCallback, useState } from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";

import General from "./tabs/general";
import TopCountries from './tabs/topCountries';
import TopCampaigns from './tabs/topCampaigns';
import LastUpdated from '../components/lastUpdated';

import { getHashValue, isMobile, updateLocationHash } from 'utils/common';

import { MARKETING_TAB_NAMES } from '../constants';

import useDate from "core/hooks/useDate";


const getActiveTab = () => {
    const tabKey = getHashValue("tab");

    return tabKey ? tabKey : MARKETING_TAB_NAMES.GENERAL
}

const MarketingDashboard = () => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);

    const activeTabRef = useRef(getActiveTab());

    const handleTabChange = (tab) => {
        updateLocationHash("tab=" + tab);
    }

    const updateLastUpdatedDate = useCallback((newDate) => {
        setLastUpdatedDate((prevState) => {
            if (lastUpdatedDate === null || dateService.isAfter(newDate, lastUpdatedDate)) {
                return newDate;
            }

            return prevState;
        })
    }, [])


    return (
        <TabDashboardLayout
            header={
                isMobile() ? { 
                    extra: (
                        <LastUpdated lastUpdatedDate={lastUpdatedDate} hasFilter={true}/>
                    ) 
                } : undefined
            }
            breadcrumbs={{
                items: [{ title: t('backoffice.menu.marketing') }]
            }}
        >
            <AntTabs
                animated={false}
                defaultActiveKey={activeTabRef.current}
                destroyInactiveTabPane={true}
                onChange={handleTabChange}
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    !isMobile() ? <LastUpdated lastUpdatedDate={lastUpdatedDate} /> : undefined
                }
            >
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.general")}</span>}
                    key={MARKETING_TAB_NAMES.GENERAL}
                >
                    <General updateLastUpdatedDate={updateLastUpdatedDate} />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.top10Country")}</span>}
                    key={MARKETING_TAB_NAMES.TOP_COUNTRY}
                >
                    <TopCountries updateLastUpdatedDate={updateLastUpdatedDate} />
                </AntTabs.TabPane>
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.top10Campaign")}</span>}
                    key={MARKETING_TAB_NAMES.TOP_CAMPAIGN}
                >
                    <TopCampaigns updateLastUpdatedDate={updateLastUpdatedDate} />
                </AntTabs.TabPane>
            </AntTabs>
        </TabDashboardLayout>
    )
}

export default MarketingDashboard;