import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

export const getProjects = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PROJECTS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getCompanies = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_COMPANIES,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getUsers = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_USERS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getAccessManagers = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_ACCESS_MANAGERS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getPlayers = async (params = {}) => {
	const data = {
		state: params.state,
        userNameOrId: params.userNameOrId
	}

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PLAYERS,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getPermissionGroups = async type => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PERMISSION_GROUPS,
        method: Methods.GET,
        params: { type }
    })

    return response?.data?.value;
};

export const getControllers = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_CONTROLLERS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getCommissionPlans = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_COMMISSION_PLANS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getPermissionGroupAvailableUsers = async groupId => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PERMISSION_GROUP_AVAILABLE_USERS,
        method: Methods.GET,
        params: { groupId }
    })

    return response?.data?.value;
};

export const getSystemPaymentsAvailableLabels = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PAYMENT_LABELS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getSystemPaymentsAvailableMethods = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_SYSTEM_PAYMENT_METHODS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getProjectPaymentsAvailableMethods = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PROJECT_PAYMENT_METHODS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getAffiliateManagers = async (params = {}) => {
	const data = {
		state: params.state
	}

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_AFFILIATE_MANAGERS,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getAffiliates = async (params = {}) => {
	const data = {
		state: params.state,
        parentId: params.parentId
	}

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_AFFILIATES,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getCampaigns = async (params = {}) => {
    const data = {
        affiliateId: params.affiliateId
	}

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_CAMPAIGNS,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getBrands = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_BRANDS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getCreatives = async (params = {}) => {
	const data = {
        campaignId: params.campaignId
	}

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_CREATIVES,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getMedias = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_MEDIAS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getTrafficSources = async (params = {})  => {
    const data = {
        affiliateId: params.affiliateId
	}
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_TRAFFIC_SOURCES,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getNewsletters = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_NEWSLETTERS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getAffiliateGroups = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_AFFILIATE_GROUPS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getAffiliateGroupMembers = async groupId => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_AFFILIATE_GROUP_MEMBERS,
        method: Methods.GET,
        params: { groupId }
    })

    return response?.data?.value;
}