import { useEffect, useState } from "react";

import useDate from "core/hooks/useDate";

import { getOperationalWidgetData } from "../api";

import { makeTimePeriodCustomRangeOutputValue } from "../helpers";

const useOperationalWidget = (dataType, period, updateLastUpdatedDate, isActive, affiliateId) => {

    const { dateService } = useDate();

    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: {
            count: 0,
            data: {},
        },
    })

    useEffect(() => {
        if(isActive === false){
            return
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getOperationalWidgetData({
            dataType,
            timePeriod: period?.period,
            ...makeTimePeriodCustomRangeOutputValue(period?.from, period?.to, dateService),
            affiliateId,
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;

                updateLastUpdatedDate(payload.lastUpdatedAt);
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                        chartData: {
                            data: payload.data,
                            count: payload.count,
                        }
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [dataType, period, updateLastUpdatedDate, isActive, affiliateId])

    return chartState;

}

export default useOperationalWidget;