import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form } from 'antd';

import {
    createMedia,
    saveMedia
} from 'store/actions/portal/marketingTools/mediaTools/mediaTools.actions';
import {
    getCreativeHTML
} from 'store/actions/portal/marketingTools/campaigns/creatives.action';

import LightboxComponent from 'components/common/lightbox';
import Modal from "components/common/modal";
import MediaCode from '../mediaCode';
import MediaCreateForm from './mediaEditCreationForm';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { makeImagePath } from 'utils/common';

import { POPUP_SIZE } from "constants/common.constants";
import { MEDIA_TYPE } from 'constants/campaign.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { USER_ROLE } from 'core/constants/auth';

const CREATION_STEP = {
    CREATION: "creation",
    RESULT: "result",
}

/** User Creating Popup Component */
const MediaToolCreateComponent = ({
    onClose,
    formInitialValues = {
        trafficSources: []
    },
    isEdit=false,
    createMedia,
    saveMedia,
    isSaving,
    creationResult,
    htmls,
    getCreativeHTML,
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const userRole = useAuthSelector(userRoleSelector);


    const [selectedCampaignId, setSelectedCampaignId] = useState(formInitialValues.campaignId ?? null);
    const [selectedCreativeData, setSelectedCreativeData] = useState(formInitialValues.creativeData ?? null);
    const [ selectedAffiliateId, setSelectedAffiliateId ] = useState(formInitialValues.affiliateId ?? null)


    const [creationStep, setCreationStep] = useState(CREATION_STEP.CREATION);

    const [isFormTouched, setIsFormTouched] = useState(!isEdit);

    // State to show/hide thumbnail
    const [lightbox, setLightbox] = useState(null);

    const hideLightbox = () => {
        setLightbox(false)
    }

    const showLightbox = () => {
        setLightbox(true)
    }

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [ campaigns ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CAMPAIGN,
        dependencies: [globalProjectId, selectedAffiliateId],
        actionParams: { affiliateId: selectedAffiliateId },
        disable: selectedAffiliateId === null && userRole !== USER_ROLE.AFFILIATE
    });

    const [ affiliates ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES,
        dependencies: [globalProjectId]
    });

    const [ creatives ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CREATIVE,
        dependencies: [globalProjectId, selectedCampaignId],
        actionParams: { campaignId: selectedCampaignId },
        disable: selectedCampaignId === null
    })

    const [ trafficSources ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.TRAFFIC_SOURCES,
        dependencies: [selectedAffiliateId],
        actionParams: { affiliateId: selectedAffiliateId },
        disable: selectedAffiliateId === null && userRole !== USER_ROLE.AFFILIATE 
    });

    const handleCampaignChange = (campaignId) => {
        setSelectedCampaignId(campaignId);

        setFieldsValue({"creative": null})
        setSelectedCreativeData(null)
    }

    const handleAffiliateChange = affiliateId => {
        setSelectedAffiliateId(affiliateId);
        setSelectedCampaignId(null);
        setFieldsValue({"campaignId": null})
        setFieldsValue({"trafficSources": []})
        setSelectedCreativeData(null)
    }

    const handleCreativeChange = (creativeData) => {
        setSelectedCreativeData(creativeData);
    }

    const handleForm = () => {
        validateFields()
            .then((formData) => {
                
                if(!isEdit){
                    const requestBody = {
                        ...formData
                    }
                    if (formData.type === MEDIA_TYPE.CREATIVE) {
                        requestBody.creativeId = selectedCreativeData.id
                    }
                    createMedia(
                        requestBody,
                        () => setCreationStep(CREATION_STEP.RESULT)
                    )
                } else {
                    const requestBody = {
                        name: formData.name,
                        traficSources: formData.trafficSources?.filter(Boolean),
                        id: formInitialValues.id
                    }
                    saveMedia(
                        requestBody,
                        onClose
                    )
                }

                
            }).catch(ex => {
                console.log(ex)
            })
    }

    const getModalProps = () => {
        let props = {};

        if (creationStep === CREATION_STEP.CREATION) {
            props = {
                title: isEdit? t('backoffice.campaigns.editMedia') : t('backoffice.campaigns.createMedia'),
                okText: isEdit? t('backoffice.common.save') : t('backoffice.common.create'),
                cancelText: t('backoffice.common.cancel'),
                onOk: handleForm,
                disableOkButton: !isFormTouched,
                onCancel: onClose,
                size: POPUP_SIZE.BIG,
                isLoading: isSaving,
            }
        } else {
            props = {
                title: (
                    <div className='rt--flex rt--align-center rt--justify-center'>
                        <span className='rt--title rt--font-bold rt--font-big rt--pl-4'>
                            {t("backoffice.campaigns.mediaSuccessfullyCreated")}
                        </span>
                    </div>
                ),
                size: POPUP_SIZE.BIGER,
                className: "rt--modal-success",
                cancelText: t('backoffice.common.close'),
                hideOkButton: true,
                onCancel: onClose,
            }
        }

        return props;
    }

    useEffect(() => {
        validateFields()
    }, [selectedCreativeData]);

    return (
        <>
            <Modal
                {...getModalProps()}
            >
                {
                    creationStep === CREATION_STEP.CREATION
                        ? (
                            <MediaCreateForm
                                formInstance={formInstance}
                                userRole={userRole}
                                onCampaignChange={handleCampaignChange}
                                onAffiliateChange={handleAffiliateChange}
                                selectedCampaignId={selectedCampaignId}
                                selectedAffiliateId={selectedAffiliateId}
                                selectedCreativeData={selectedCreativeData}
                                showLightbox={showLightbox}
                                campaigns={campaigns}
                                affiliates={affiliates}
                                formInitialValues={formInitialValues}
                                creatives={creatives}
                                trafficSources={trafficSources}
                                onValuesChange={isEdit ?
                                    (_, formValues) => setIsFormTouched(
                                        formInitialValues?.name !== formValues.name ||
                                        formInitialValues?.trafficSources !== formValues.trafficSources
                                    ) : undefined
                                }
                            />
                        ) : (
                            <MediaCode code={creationResult} />
                        )
                }
            </Modal>

            {lightbox && (
                <LightboxComponent
                    onClose={hideLightbox}
                    showThumbnails={true}
                    showExtraFooter={true}
                    onSelect={handleCreativeChange}
                    items={creatives.map(creative => ({
                        src: creative.filePath ? makeImagePath(creative.filePath) : `${import.meta.env.SYSTEM_CDN_URL}/system/static/images/html.png`,
                        name: creative.name,
                        id: creative.id,
                        type: creative.filePath === null ? "iframe" : "image",
                        width: creative.width,
                        height: creative.height,
                        html: htmls[creative.id],
                        loadHTML: (slideData) => getCreativeHTML(slideData.id)
                    }))}
                />
            )}
        </>
    )
}

/** MediaToolCreateComponent propTypes
    * PropTypes
*/
MediaToolCreateComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Is edit mode */
    isEdit: PropTypes.bool,
    /** Redux action to create media  */
    createMedia: PropTypes.func,
    /** Redux action to save media  */
    saveMedia: PropTypes.func,
    /** Redux state property, is true when saving media tools */
    isSaving: PropTypes.bool,
    /** Redux state property, represents creation result */
    creationResult: PropTypes.object,
    /** Redux action to get creative html */
    getCreativeHTML: PropTypes.func,
    /** Redux state property, Creative htmls */
    htmls: PropTypes.objectOf(PropTypes.string),
    /** Form Initial Values */
    formInitialValues: PropTypes.object

}

const mapDispatchToProps = dispatch => ({
    createMedia: (requestBody, onSuccess) => {
        dispatch(createMedia(requestBody, onSuccess));
    },
    saveMedia: (requestBody, onSuccess) => {
        dispatch(saveMedia(requestBody, onSuccess));
    },
    getCreativeHTML: (id) => {
        dispatch(getCreativeHTML(id))
    },
})

const mapStateToProps = state => {
    return {
        isSaving: state.mediaTools.isSaving,
        creationResult: state.mediaTools.creationResult,
        htmls: state.campaigns.edit.creatives.htmls,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaToolCreateComponent)