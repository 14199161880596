import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';

import { Form, Col, Row, Switch } from 'antd';

import Modal from "components/common/modal";
import Input from 'components/common/input';
import Select from 'components/common/select';
import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';

import { createAffiliateGroup, getAvailableAffiliates, resetAvailableAffiliates } from "store/actions/portal/affiliates/groups/groups.action";

import { isMobile } from 'utils/common';

import ROUTES from "core/constants/routes/routes";
import { POPUP_SIZE } from 'constants/common.constants';
import { NAME_REGEX } from 'constants/regex.constants';
import { AFFILIATE_GROUP_TYPE, TARGET_MARKET } from 'constants/affiliate.constants';
import { USER_STATE } from 'constants/user.constants';

import affiliateType from "types/affiliate/generalInfo.type";

import countries from 'core/systemData/countries';

/** Affiliate Group Creating Popup Component */
const GroupCreateComponent = ({
    isSaving,
    createAffiliateGroup,
    availableAffiliates,
    getAvailableAffiliates,
    resetAvailableAffiliates,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();
    const { validateFields, getFieldValue } = formInstance;
    const navigate = useNavigate();


    /** Fires when form submitted
       * @function
       * @memberOf GroupCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    name: data.name,
                    groupType: data.groupType,
                    editableByOtherUsers: data.editableByOtherUsers,
                    visibleToAssignedAffiliates: data.visibleToAssignedAffiliates
                }

                if (data.groupType === AFFILIATE_GROUP_TYPE.DYNAMIC) {
                    d.filter = data.filter;
                } else if (data.groupType === AFFILIATE_GROUP_TYPE.STATIC) {
                    d.affiliates = availableAffiliates.map(a => a.id)
                }

                createAffiliateGroup(
                    d,
                    ({ id, name, groupType }) => {
                        navigate(`${ROUTES.AFFILIATE_GROUPS_EDIT}/${id}?name=${name}&groupType=${groupType}`);
                    });
            }).catch(() => { })
    }

    /** Fires when form submitted
       * @function
       * @memberOf GroupCreateComponent
    */
    const handleFilterChange = () => {
        const filters = getFieldValue("filter");
        getAvailableAffiliates(filters);
    }

    /** Get available affiliates on component mount, and reset on unmount */
    useEffect(() => {
        handleFilterChange();
        return () => {
            resetAvailableAffiliates()
        }
    }, [])

    return (
        <Modal
            title={t('backoffice.affiliates.createGroup')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIG}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    editableByOtherUsers: true,
                    filter: {
                        state: null,
                        country: null,
                        targetMarket: null
                    }
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} lg={12}>
                        <Form.Item
                            label={`${t('backoffice.affiliates.name')} *`}
                            name="name"
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            validateFirst
                            className='rt--general-form-item'
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.name')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.name')}`}
                                maxLength={30}
                                autoFocus={!isMobile()}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={12}>
                        <Form.Item
                            label={(
                                <div className='rt--flex rt--align-center'>
                                    <span title={t('backoffice.affiliates.groupType')} >{`${t('backoffice.affiliates.groupType')} *`} </span>
                                    <Tooltip
                                        title={t('backoffice.affiliates.groupTypeTooltip')}
                                        trigger={["hover", "click"]}
                                        placement="top"
                                        enableMobile={true}
                                    >
                                        <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                                            <Icon name="info" size={18} />
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                            name="groupType"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                            data-placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.groupType')}`}
                        >
                            <Select
                                options={[
                                    { value: AFFILIATE_GROUP_TYPE.STATIC, text: t('backoffice.affiliates.static') },
                                    { value: AFFILIATE_GROUP_TYPE.DYNAMIC, text: t('backoffice.affiliates.dynamic') }
                                ]}
                                placeholder={`${t("backoffice.common.select")} ${t('backoffice.affiliates.groupType')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                            <Form.Item
                                name="visibleToAssignedAffiliates"
                                valuePropName="checked"
                                className='rt--form-item-without-margin'
                            >
                                <Switch />
                            </Form.Item>
                            <label
                                className='rt--pl-8 rt--switcher-label rt--flex rt--align-center'
                            >
                                <span className='rt--title rt--font-regular rt--font-normal'>{t('backoffice.affiliates.groupVisibleToAffiliates')}</span>
                                <Tooltip
                                    title={t('backoffice.affiliates.groupVisibleToAffiliatesTooltip')}
                                    trigger={["hover", "click"]}
                                    placement="top"
                                    enableMobile={true}
                                >
                                    <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                                        <Icon name="info" size={18} />
                                    </div>
                                </Tooltip>
                            </label>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                            <Form.Item
                                name="editableByOtherUsers"
                                valuePropName="checked"
                                className='rt--form-item-without-margin'
                            >
                                <Switch />
                            </Form.Item>
                            <label
                                className='rt--pl-8 rt--switcher-label rt--flex rt--align-center'
                            >
                                <span className='rt--title rt--font-regular rt--font-normal'>{t('backoffice.affiliates.editableByOtherUsers')}</span>
                                <Tooltip
                                    title={t('backoffice.affiliates.editableByOtherUsersTooltip')}
                                    trigger={["hover", "click"]}
                                    placement="top"
                                    enableMobile={true}
                                >
                                    <div className="rt--ml-4 rt--flex rt--align-center rt--cursor-pointer">
                                        <Icon name="info" size={18} />
                                    </div>
                                </Tooltip>
                            </label>
                        </div>
                    </Col>
                </Row>
                <h4
                    className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-biger'
                >
                    {
                        t('backoffice.affiliates.selectAffiliate')
                    }
                </h4>
                <Row gutter={[16, 0]}>
                    <Col span={24} lg={12}>
                        <Form.Item
                            label={t('backoffice.common.status')}
                            name={["filter", "state"]}
                        >
                            <Select
                                options={[
                                    { value: null, text: t('backoffice.common.none') },
                                    { value: USER_STATE.IN_PROGRESS, text: t('backoffice.affiliates.pending') },
                                    { value: USER_STATE.ACTIVE, text: t('backoffice.affiliates.approved') },
                                    { value: USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                    { value: USER_STATE.DECLINED, text: t('backoffice.common.declined') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                onChange={handleFilterChange}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={12}>
                        <Form.Item
                            label={t('backoffice.affiliates.country')}
                            name={["filter", "country"]}
                        >
                            <Select
                                options={
                                    [
                                        { value: null, text: t('backoffice.common.none') },
                                    ].concat(countries.map(c => ({
                                        value: c.iso2,
                                        text: t(`backoffice.countries.${c.iso2}`)
                                    })))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.country')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                onChange={handleFilterChange}
                                search={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} lg={12}>
                        <Form.Item
                            label={t('backoffice.affiliates.targetMarket')}
                            name={["filter", "targetMarket"]}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                options={[
                                    { value: null, text: t('backoffice.common.none') },
                                    { value: TARGET_MARKET.EUROPE, text: t(`backoffice.affiliates.europe`) },
                                    { value: TARGET_MARKET.ASIA, text: t(`backoffice.affiliates.asia`) },
                                    { value: TARGET_MARKET.AFRICA, text: t(`backoffice.affiliates.africa`) },
                                    { value: TARGET_MARKET.NORTH_AMERICA, text: t(`backoffice.affiliates.northAmerica`) },
                                    { value: TARGET_MARKET.CENTRAL_AND_SOUTH_AMERICA, text: t(`backoffice.affiliates.centralAndSouthAmerica`) },
                                    { value: TARGET_MARKET.MIDDLE_EAST_NORTH_AFRICA, text: t(`backoffice.affiliates.middleEastNorthAfrica`) }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.targetMarket')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                onChange={handleFilterChange}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <div className='rt--info rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12 rt--mt-16 rt--flex rt--justify-end rt--align-center'>
                            <span className='rt--pl-4 rt--pr-4 rt--text-light rt--font-small rt--font-regular'>{t('backoffice.affiliates.totalNumberOfAffiliates')}:</span>
                            <b className='rt--font-small rt--font-bold'>{availableAffiliates.length}</b>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

/** GroupCreateComponent propTypes
    * PropTypes
*/
GroupCreateComponent.propTypes = {
    /** Redux state property, is true when creating group request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create affiliate group */
    createAffiliateGroup: PropTypes.func,
    /** Redux state property, available affiliates */
    availableAffiliates: PropTypes.arrayOf(affiliateType),
    /** Redux action to get available affiliates */
    getAvailableAffiliates: PropTypes.func,
    /** Redux action to reset available affiliates */
    resetAvailableAffiliates: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createAffiliateGroup: (group, onSuccess) => {
            dispatch(createAffiliateGroup(group, onSuccess));
        },

        getAvailableAffiliates: filters => {
            dispatch(getAvailableAffiliates(filters));
        },

        resetAvailableAffiliates: () => {
            dispatch(resetAvailableAffiliates());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.affiliateGroups.isSaving,
        availableAffiliates: state.affiliateGroups.availableAffiliates
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreateComponent)