import React, { memo, useMemo } from "react";
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import LineChart from "components/common/lineChart";

import { LINE_CHART_CONFIGS_BY_ENTITY, LINE_CHART_ENTITY_TYPE } from "../../constants";

import { isMobile } from "utils/common";
import {
    getLineChartData,
    getMarketingLineChartTooltip
} from "../../helpers";
import { constructLineChartConfigs } from "utils/lineChart";
import {
    getCheckPointsTooltipConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";

import useMarketingLineChart from "../../hooks/useMarketingLineChart";
import useDate from "core/hooks/useDate";

const MarketingLineChart = ({
    updateLastUpdatedDate,
    filters
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const { chartData, isLoading } = useMarketingLineChart(
        filters.period,
        updateLastUpdatedDate,
        undefined,
        filters.affiliateId,
    );

    /** Chart Data */
    const configs = useMemo(() => {
        const valuePropNames = [
            LINE_CHART_ENTITY_TYPE.VIEWS_COUNT,
            LINE_CHART_ENTITY_TYPE.VALID_CLICKS_COUNT,
            LINE_CHART_ENTITY_TYPE.INVALID_CLICKS_COUNT
        ];

        const charts = valuePropNames.map(valuePropName => (
            getLineChartData(chartData || [], filters.period, valuePropName, dateService, t)
        ))

        // Define names based on entityType
        const lineNames = [
            `<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.view")}</span>`,
            `<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.validClick")}</span>`,
            `<span class='rt--highchart-legend-title'>${t("backoffice.dashboard.invalidClick")}</span>`
        ];

        const categories = charts[0].categories;

        return constructLineChartConfigs({
            xAxisConfigs: getXAxisConfigs({
                checkPoints: categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    getMarketingLineChartTooltip({ 
                        element, 
                        chartData: charts, 
                        period: filters.period, 
                        dateService,
                        t 
                    })
                )
            }),
            lines: charts.map((chart, index) => (
                getMainLineConfigs({
                    ...LINE_CHART_CONFIGS_BY_ENTITY[valuePropNames[index]],
                    data: chart.values,
                    name: lineNames[index]
                }) 
            )), 
        })
    }, [chartData, filters.period, dateService, t])

    return (
        <div className={"rt--chart-section rt--pb-24 rt--pt-24 " + (isMobile() ? 'rt--pl-6 rt--pr-6' : 'rt--pl-24 rt--pr-24')}>
            <div className='rt--chart-section-header rt--mb-16 rt--flex rt--justify-between rt--align-center'>
                <span className='rt--chart-section-title rt--font-extra-bold rt--font-big'>{t("backoffice.dashboard.marketingPerformance")}</span>
            </div>
            <LineChart
                configs={configs}
                hideChart={chartData.length === 0}
                isLoading={isLoading}
            />
        </div>
    )
}

MarketingLineChart.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
    filters: PropTypes.object
};

export default memo(MarketingLineChart);