export const COMMISSION_TYPE = {
    REVSHARE: 1,
    CPA: 2,
    FIX: 4
}

export const COMMISSION_TYPE_TRANSLATIONS = {
    [COMMISSION_TYPE.REVSHARE]: "backoffice.commissionplans.revshare",
    [COMMISSION_TYPE.CPA]: "backoffice.commissionplans.cpa",
    [COMMISSION_TYPE.FIX]: "backoffice.commissionplans.fix",
}

export const CPA_PERIOD = {
    _1W: 1 << 0,
    _2W: 1 << 1,
    _1M: 1 << 2,
    _2M: 1 << 3,
    _3M: 1 << 4,
    _6M: 1 << 5,
    _1Y: 1 << 6,
    _2Y: 1 << 7,
    ALWAYS: 1 << 8
}

export const CPA_PERIOD_TRANSLATIONS = {
    [CPA_PERIOD._1W]: "backoffice.commissionplans._1w",
    [CPA_PERIOD._2W]: "backoffice.commissionplans._2w",
    [CPA_PERIOD._1M]: "backoffice.commissionplans._1m",
    [CPA_PERIOD._2M]: "backoffice.commissionplans._2m",
    [CPA_PERIOD._3M]: "backoffice.commissionplans._3m",
    [CPA_PERIOD._6M]: "backoffice.commissionplans._6m",
    [CPA_PERIOD._1Y]: "backoffice.commissionplans._1y",
    [CPA_PERIOD._2Y]: "backoffice.commissionplans._2y",
    [CPA_PERIOD.ALWAYS]: "backoffice.commissionplans.always",
}