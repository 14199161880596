import React from 'react';
import emptyWidget from  'assets/media/no-notifications-widget.svg';
import {useTranslation} from "core/hooks/useTranslation";

const NoNotificationsWidget = () => {
    const { t } = useTranslation();
    return (
        <div className="rt--no-notification">
            <div className="no-ntf-content">
                <img src={emptyWidget} alt="empty widget" />
                <span className="rt--mt-16">
                    {t('backoffice.notifications.noNotifications')}
                </span>
                <span className='rt--text-light rt--font-regular rt--mt-4'>
                    {t('backoffice.notifications.noNotificationsMessage')}
                </span>
            </div>
        </div>
    )
}

export default NoNotificationsWidget;
