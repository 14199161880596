import ROUTES from "core/constants/routes/routes";

import BalanceHistoryComponent from "pages/payment/balanceHistory/balanceHistory-component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import isCommissionEnabled from "core/helpers/project/isCommissionEnabled";

const BalanceHistoryPageRoute = {
	path: ROUTES.BALANCE_HISTORY,
	element: BalanceHistoryComponent, 
    permission: { resource: PERMISSION_RESOURCE.PAYMENTS_WALLETS_HISTORY, action: PERMISSION_ACTION.VIEW },
    isDisabled: ( projectId, user, userInfo ) => !isCommissionEnabled(projectId, user, userInfo)
};

export default BalanceHistoryPageRoute;
