import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import useAccess from "core/hooks/useAccess";

import { Row, Col } from 'antd';

import TabTableDashboardLayout from 'components/layouts/tab/table';
import LiveBalances from '../../../components/liveBalances';
import FinancialLineChart from '../../../components/financialLineChart';

import Filters from "../../../components/filters";

import { TIME_PERIOD } from '../../../constants';

import useFirstRender from 'hooks/useFirstRender';
import useCurrentProject from 'core/hooks/useCurrentProject';

import { isMobile } from 'utils/common';

import {globalProjectIdSelector, useAuthSelector, userRoleSelector, userSelector} from 'core/stores/authStore';
import { currenciesSelector, currencySelector, useGlobalSelector } from 'core/stores/globalStore';
import { ADDITIONAL_ACCESS_TYPE } from 'core/constants/additionalAccess';
import FilterByAffiliate from "pages/dashboard/components/filterByAffiliate/index.jsx";
import {USER_ROLE} from "constants/user.constants.js";

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        },
        affiliateId: '',
    }
)

const General = ({
    updateLastUpdatedDate
}) => {

    const [ filters, setFilters ] = useState(getDefaultFilters());

    const { hasAccess } = useAccess();

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const { allowImpactOnCPA } = useAuthSelector(userSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const currencyCode = useGlobalSelector(currencySelector);
    const currencies = useGlobalSelector(currenciesSelector);

    const currentProject = useCurrentProject();

    const showFilterByAffiliate = userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.AFFILIATE_MANAGER;

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })

    const hasFinancialLineChartAccess = [
        ADDITIONAL_ACCESS_TYPE.GGR, 
        ADDITIONAL_ACCESS_TYPE.NGR, 
        ADDITIONAL_ACCESS_TYPE.BET_AMOUNT, 
        ADDITIONAL_ACCESS_TYPE.DEPOSIT_AMOUNT, 
        ADDITIONAL_ACCESS_TYPE.FTD_AMOUNT, 
        ADDITIONAL_ACCESS_TYPE.NET_DEPOSIT_AMOUNT
    ].some(access => hasAccess(access))

    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ?
                    <Filters filters={filters} updateFilters={updateFilters} showAffiliateFilter={showFilterByAffiliate} />
                    : undefined
            }}
        >
            {
                !isMobile() && (
                    <div className='rt--flex rt--pb-24 rt--justify-end'>
                        {
                            showFilterByAffiliate && (
                                <FilterByAffiliate
                                    onChange={(affiliateId) => updateFilters({affiliateId: affiliateId})}
                                    value={filters?.affiliateId}
                                />
                            )
                        }
                    </div>
                )
            }

            {
                currentProject.commissionEnabled && (
                    <div className='rt--mb-24'>
                        <LiveBalances
                            globalProjectId={globalProjectId}
                            currencyCode={currencyCode}
                            allowImpactOnCPA={allowImpactOnCPA}
                            filters={filters}
                        />
                    </div>
                )
            }


            {
                hasFinancialLineChartAccess && (
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <FinancialLineChart
                                updateLastUpdatedDate={updateLastUpdatedDate}
                                filters={filters}
                                updateFilters={updateFilters}
                                currencyCode={currencyCode}
                                currencies={currencies}
                            />
                        </Col>
                    </Row>
                )
            }


        </TabTableDashboardLayout>
    )
}

General.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default General;