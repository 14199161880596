import { lazy } from 'react';

import Spin from 'core/ui-kit/spin';

import withSuspenseHoc from 'core/HOCs/withSuspense';

import ROUTES from 'core/constants/routes/routes';

const PromoCodesContainer = withSuspenseHoc(lazy(() => import('./components/promoCodesContainer')), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

import { USER_ROLE } from 'constants/user.constants';

const PromoCodesPageRoute = {
    path: ROUTES.PROMO_CODES,
    element: PromoCodesContainer,
    permission: { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_PROMO_CODES, action: PERMISSION_ACTION.VIEW },
    role: [USER_ROLE.AFFILIATE, USER_ROLE.AFFILIATE_MANAGER, USER_ROLE.ADMIN]
};

export default PromoCodesPageRoute;