import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";

import {
    getPendingInvoices,
    setPendingInvoicesFilters
} from 'store/actions/portal/payment/invoices/pending.action';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { USER_ROLE, USER_STATE } from 'core/constants/auth';
import flagsToBinary from 'core/helpers/common/flagsToBinary';

const Filters = ({
    setPendingInvoicesFilters,
    getPendingInvoices,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const role = useAuthSelector(userRoleSelector);

    const isAffiliate = role === USER_ROLE.AFFILIATE;
    const availableStatus = [USER_STATE.ACTIVE, USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA, USER_STATE.EXPIRED, USER_STATE.BLOCKED, USER_STATE.DELETED]

    const [ paymentMethods, getPaymentMethods ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PROJECT_PAYMENT_METHODS,
        autoGet: false
    })

    const [ affiliateNames, getAffiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES, 
        autoGet: false,
        actionParams: { state: flagsToBinary(availableStatus) }
    });

    const affiliateOptions = useMemo( () => {
        return [
            { value: "", text: t('backoffice.common.all') }
        ].concat(
            affiliateNames
                .map(item => (
                    { value: item.id, text: item.name }
                ))
        )
    }, [affiliateNames, t])

    return (
        <FiltersWrapper
            loadFn={getPendingInvoices}
            setFiltersFn={setPendingInvoicesFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName={["id", "date"]}
            onInit={() => {
                getPaymentMethods();
                if(!isAffiliate){
                    getAffiliateNames();
                }
            }}
        >
            <Row gutter={[16, 0]}>
                {
                    !isAffiliate && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.invoices.affiliate')}
                                name="affiliateNameOrId"
                            >
                                <Select
                                    options={affiliateOptions}
                                    placeholder={`${t("backoffice.common.select")} ${t('backoffice.invoices.affiliate')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    search={true}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.invoices.paymentMethod')}
                        name="paymentId"
                        className='rt--form-item-without-margin'
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    paymentMethods.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.paymentMethod')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get pending invoices */
    getPendingInvoices: PropTypes.func,
    /** Redux action to set pending invoices filters */
    setPendingInvoicesFilters: PropTypes.func,
    /** Redux state property, pending invoices filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getPendingInvoices: () => {
            dispatch(getPendingInvoices())
        },
        setPendingInvoicesFilters: filters => {
            dispatch(setPendingInvoicesFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.invoices.pending.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);