export const INVOICE_GENERATION_TYPE = {
    BY_COMPANY: 1,
    BY_AFFILIATE: 2
}

export const INVOICE_GENERATION_METHOD = {
    MANUAL: 1,
    AUTO: 2
}

export const INVOICE_GENERATION_PERIOD = {
    WEEK: 1,
    TWO_WEEK: 2,
    MONTH: 4
}

export const COMMISSION_PLAN_CALCULATION_PERIOD = {
    HOUR: 1,
    DAY: 2,
    WEEK: 4,
    TWO_WEEK: 8,
    THREE_WEEK: 16,
    MONTH: 32
}

export const COMMISSION_PLAN_TYPE = {
    REVSHARE: 1,
    CPA: 2,
    FIX: 4
}

export const COMMISSION_PLAN_STATE = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const COMMISSION_PLAN_SOURCE = {
    GGR: 1,
    NGR: 2,
    TURNOVER: 4,
    NET_DEPOSIT: 8
}

export const COMMISSION_PLAN_STRUCTURE = {
    FLAT: 1,
    PLAYER_TIERED: 2,
    PROGRESSIVE: 4,
    PLAYER_LIFETIME: 8,
    QFTD_TIERED: 16,
}

export const COMMISSION_CALCULATION_STATUS = {
    APPROVED: 1,
    PENDING: 2
}

export const COMMISSION_PLAN_SOURCE_TYPE = {
    AMOUNT: 1,
    PERCENT: 2
}

export const INFINITY = "∞";
export const MINUS_INFINITY = 0;


export const COMMISSION_PLAN_SOURCE_DIRECTION = {
    PLAYERS: 1
}

export const COMMISSION_PLAN_RANGE_TYPE = {
    DEFAULT: 1,
    MIX: 2,
    SPORT_LIVE_SINGLE: 4,
    SPORT_LIVE_EXPRESS: 8,
    SPORT_PREMATCH_SINGLE: 16,
    SPORT_PREMATCH_EXPRESS: 32
}



export const COMMISSION_PLAN_CALCULATE_BY = {
    BET_COUNT: 1,
    ODD_RANGE: 2,
    DEFAULT_SOURCE: 3
}

export const COMMISSION_SETTINGS_TYPE = {
    GENERAL: 1,
    SPORT_PRE_MATCH: 2,
    SPORT_LIVE: 3
}

export const COMMISSION_INCOME_TYPE = {
    DIRECT: 1,
    NETWORK: 2,
    CARRY_OVER: 4
}

export const COMMISSION_PLAN_TURNOVER_MAX_PERCENT = 7;

export const CPA_PERIOD = {
    _1W: 1 << 0,
    _2W: 1 << 1,
    _1M: 1 << 2,
    _2M: 1 << 3,
    _3M: 1 << 4,
    _6M: 1 << 5,
    _1Y: 1 << 6,
    _2Y: 1 << 7,
    ALWAYS: 1 << 8
}