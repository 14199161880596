import ROUTES from "core/constants/routes/routes";

import InvoicesComponent from "pages/payment/invoices/invoices.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import isCommissionEnabled from "core/helpers/project/isCommissionEnabled";

const InvoicesPageRoute = {
	path: ROUTES.INVOICES,
	element: InvoicesComponent, 
    permission: { resource: PERMISSION_RESOURCE.PAYMENTS_INVOICES, action: PERMISSION_ACTION.VIEW },
    isDisabled: ( projectId, user, userInfo ) => !isCommissionEnabled(projectId, user, userInfo)
};

export default InvoicesPageRoute;
