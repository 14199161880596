export const ADDITIONAL_ACCESS_TYPE = {
    CLICK_SOURCE: 1,
    DEPOSIT_COUNT: 2,
    DEPOSIT_AMOUNT: 3,
    WITHDRAW_COUNT: 4,
    WITHDRAW_AMOUNT: 5,
    NET_DEPOSIT_AMOUNT: 6,
    FTD_AMOUNT: 7,
    FTD_COUNT: 8,
    ADJUSTMENTS: 9,
    BET_AMOUNT: 10,
    BET_COUNT: 11,
    GGR: 12,
    NGR: 13,
    BONUS_WON_AMOUNT: 14,
    WON_AMOUNT: 15,
    SPORT_BET_AMOUNT: 16,
    SPORT_BET_COUNT: 17,
    SPORT_GGR: 18,
    SPORT_NGR: 19,
    SPORT_BONUS_WON_AMOUNT: 20,
    SPORT_WON_AMOUNT: 21,
    SPORT_ADMIN_FEE: 22,
    CASINO_BET_AMOUNT: 23,
    CASINO_BET_COUNT: 24,
    CASINO_GGR: 25,
    CASINO_NGR: 26,
    CASINO_BONUS_WON_AMOUNT: 27,
    CASINO_WON_AMOUNT: 28,
    CASINO_ADMIN_FEE: 29,
    DEPOSIT_FEE: 30,
    CASINO_SOURCE: 31,
    SPORT_SOURCE: 32,
    CASINO_COMMISSIONS: 33,
    SPORT_COMMISSIONS: 34,
    PLAYER_USERNAME: 35
}