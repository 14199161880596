import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import Input from 'components/common/input';

import { setErrorsFilters, getErrors } from "store/actions/portal/developer/errors/errors.action";

import { MONTHS, API_TYPE } from "constants/common.constants"
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useDate from "core/hooks/useDate";

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Errors Page Filters Component */

const Filters = ({
    setErrorsFilters,
    getErrors,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const { dateService } = useDate();

    const YEARS = Array(dateService.getCurrentYMD().year - 2020 + 1).fill().map((_, idx) => 2020 + idx);

    const [ userNames, getUserNames ] = useAutosuggestion({ 
        type: AUTOSUGGESTION_TYPE.USER, 
        autoGet: false
    });

    const [ controllerNames, getControllerNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CONTROLLER, 
        autoGet: false
    });

    return (
        <FiltersWrapper
            loadFn={getErrors}
            setFiltersFn={setErrorsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            onInit={() => {
                getUserNames();
                getControllerNames();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.userNameOrId')}
                        name="userId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.userNameOrId')}`}
                            items={userNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.controller')}
                        name="controller"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.controller')}`}
                            items={controllerNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.action')}
                        name="action"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.action')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.action')}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.apiType')}
                        name="apiType"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') },
                                    { value: API_TYPE.BACKOFFICE, text: t('backoffice.errorlogs.backoffice') },
                                    { value: API_TYPE.CHAT, text: t('backoffice.errorlogs.chat') },
                                    { value: API_TYPE.JOB, text: t('backoffice.errorlogs.job') },
                                    { value: API_TYPE.INTEGRATION, text: t('backoffice.errorlogs.integration') }
                                ]
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.errorlogs.apiType")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.month')}
                        name="month"
                    >
                        <Select
                            options={
                                Object.values(MONTHS).map(m => (
                                    { value: m, text: t('backoffice.months.month' + m) }
                                ))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.month")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.year')}
                        name="year"
                    >
                        <Select
                            options={
                                YEARS.map(y => (
                                    { value: y, text: y }
                                ))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.year")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get errors */
    getErrors: PropTypes.func,
    /** Redux action to set errors filters */
    setErrorsFilters: PropTypes.func,
    /** Redux state property, errors filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getErrors: () => {
            dispatch(getErrors())
        },
        setErrorsFilters: filters => {
            dispatch(setErrorsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.errors.filters,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);