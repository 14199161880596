import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";
import useAutosuggestion from 'hooks/useAutosuggestion';

import { Form, Row, Col, Spin, message } from 'antd';

import Select from 'components/common/select/index';
import Input from 'components/common/input';
import ImageUploader from "components/common/imageUploader";
import FormBuilder from 'components/common/formBuilder';
import TabFormDashboardLayout from "components/layouts/tab/form";

import SystemPaymentMethodFormTranslations from "./systemPaymentMethodFormTranslations";

import { getSystemPaymentGeneralInfo, saveSystemPaymentGeneralInfo } from "store/actions/portal/settings/systemPayments/general.action";
import { getSystemAvailableLanguages } from "store/actions/portal/common/common.action";

import systemPaymentType from "types/systemPayments/systemPayment.type";

import { makeImagePath, classNames, getAPIUrls } from "utils/common";
import { isFormChanged } from "utils/form";

import ApiUrls from 'constants/api.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { NAME_REGEX } from "constants/regex.constants";
import { LABEL_INPUT_REGEX } from "constants/systemPayments.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import usePermissions from 'core/hooks/usePermission';

const GENERAL_INFO_FIELDS_NAMES = {
	NAME: "name",
	LABELS: "labels"
}

/** System Payments Page General Info Tab Component */
const SystemPaymentGeneralInfo = ({
	getSystemPaymentGeneralInfo,
	saveSystemPaymentGeneralInfo,
	isSaving,
	isLoading,
	generalInfo,
	getSystemAvailableLanguages,
    systemAvailableLanguages,
	onTabChange
}) => {
	const { t, locale } = useTranslation();

    const permissionUtils = usePermissions();

	const searchParams = useParams();
	const [ availableLabels ] = useAutosuggestion({
		type: AUTOSUGGESTION_TYPE.PAYMENT_LABELS
	});
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	const formBuilderRef = useRef(null);
	const [isFirstFormTouched, setIsFirstFormTouched] = useState(false);

    /** State for selected language code */
    const [languageCode, setLanguageCode] = useState(locale)

	const labelsOptions = useMemo(() => availableLabels.map(({ name }) => ({ text: name, value: name })), [availableLabels]);

	/** Can edit general info */
	const canEdit = permissionUtils.has( PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, PERMISSION_ACTION.MODIFY ) && generalInfo.isEditable;

	/** Check is form changed
		* @function
		* @param {object} formValues - form current values
		* @returns {boolean}
		* @memberOf SystemPaymentGeneralInfo
  */
	const formChanged = formValues => {
		return isFormChanged({ ...formValues, id: searchParams.id }, { ...generalInfo })
	}

	/** Fires when forms submitted
		* @function
		* @memberOf SystemPaymentGeneralInfo
  */
	const handleSave = (formControlsFormValues) => {
		validateFields()
			.then(data => {
				saveSystemPaymentGeneralInfo({
					...data,
					...formControlsFormValues,
					id: searchParams.id
				});
				setIsFirstFormTouched(false);
			}).catch(err => {
				console.log(err)
			})
	};

	const onUpload = logo => {
		const msg = logo?.response?.message ?? null;
		msg && message.success(msg);
	};

	/** Load System Payment general info */
	useEffect(() => {
		getSystemPaymentGeneralInfo(searchParams.id);
	}, []);

	/** Load company available languages */
    useEffect(() => {
        getSystemAvailableLanguages()
    }, [])

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...generalInfo,
			[GENERAL_INFO_FIELDS_NAMES.LABELS]: generalInfo.labels === null ? [] : generalInfo.labels
		});
	}, [generalInfo]);

	useEffect(() => {
		onTabChange(isFirstFormTouched || formBuilderRef.current?.isFormTouched);
	}, [isFirstFormTouched, formBuilderRef.current?.isFormTouched]);

	return (
		<TabFormDashboardLayout
			buttons={
				[
					{
						type: "primary",
						onClick: () => formBuilderRef.current.handleForm(),
						text: t("backoffice.common.save"),
						enabled: canEdit,
						loading: isSaving,
						disabled: !(isFirstFormTouched || formBuilderRef.current?.isFormTouched)
					}
				]
			}
			id={generalInfo.id}
			longId={generalInfo.longId}
			dropdown={
                {
                    value: languageCode,
                    onChange: value => setLanguageCode(value),
                    items: Object.keys(systemAvailableLanguages).map(item => ({
                        key: item.toUpperCase(),
                        value: t(`backoffice.languages.${item}`)
                    })),
                }   
            }
		>

			<Spin spinning={isLoading} wrapperClassName="rt--form-spin">
				<Form
					colon={false}
					form={formInstance}
					requiredMark={false}
					layout="vertical"
					initialValues={{
						[GENERAL_INFO_FIELDS_NAMES.NAME]: "",
						[GENERAL_INFO_FIELDS_NAMES.LABELS]: []
					}}
					onValuesChange={(changed, formValues) => setIsFirstFormTouched(formChanged({ ...formValues }))}
				>
					<Row gutter={[16, 0]}>
						<Col xs={24} sm={12} xl={6}>
							<Form.Item
								label={`${t('backoffice.payments.name')} *`}
								name={GENERAL_INFO_FIELDS_NAMES.NAME}
								rules={[
									{ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
									{ min: 5, message: t('backoffice.validation.fieldInvalid') },
									{ max: 30, message: t('backoffice.validation.fieldInvalid') },
									{ pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
								]}
								className={classNames('rt--general-form-item', !canEdit && 'rt--form-item-disabled')}
								data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.name')}`}
								validateFirst
							>
								<Input
									disabled={!canEdit}
									placeholder={`${t('backoffice.common.enter')} ${t('backoffice.payments.name')}`}
									maxLength={30}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<Form.Item
								label={t('backoffice.payments.labels')}
								name={GENERAL_INFO_FIELDS_NAMES.LABELS}
								rules={[
									{ max: 5, type: "array", message: t('backoffice.validation.fieldInvalid') }
								]}
								className={classNames(!canEdit && 'rt--form-item-disabled')}
								validateFirst
							>
								<Select
									placeholder={`${t('backoffice.common.select')} ${t('backoffice.payments.labels')}`}
									disabled={!canEdit}
									options={labelsOptions}
									search={{ pattern: LABEL_INPUT_REGEX }}
									add={true}
									isMultiple={true}
									showSelectAllButton={false}
									getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12} xl={6}>
							<ImageUploader
								disabled={!canEdit}
								uploadUrl={`${getAPIUrls().API_URL}${ApiUrls.UPLOAD_SYSTEM_PAYMENT_LOGO}`}
								defaultFile={generalInfo.logoPath ? {
									url: makeImagePath(generalInfo.logoPath),
									status: "done",
									percent: 100
								} : null}
								data={{ id: generalInfo.id }}
								fileBuilder={file => {
									return ({ url: makeImagePath(file?.logoPath) })
								}}
								size={1024 * 1024}
								disablePreview={true}
								onSuccess={onUpload}
								updateProps={[generalInfo.logoPath]}
								title={`${t('backoffice.payments.logo')} *`}
								titleTooltip={t('backoffice.payments.headerInfo')}
							/>
						</Col>
					</Row>
				</Form>
				<FormBuilder
					title={t("backoffice.payments.requestFields")}
					onTabChange={onTabChange}
					ref={formBuilderRef}
					formData={generalInfo?.formControls}
					userCanModify={canEdit}
					onFormFinish={(values) => handleSave(values)}
					fieldNameRender={fieldName => t("backoffice.payments." + fieldName)}
				/>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={24} xl={18}>
						<SystemPaymentMethodFormTranslations
							languageCode={languageCode}
						/>
					</Col>
				</Row>
			</Spin>
		</TabFormDashboardLayout >
	)
}

SystemPaymentGeneralInfo.propTypes = {
	/** Redux action to get System Payment General info */
	getSystemPaymentGeneralInfo: PropTypes.func,
	/** Redux action to save System Payment General info */
	saveSystemPaymentGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing System Payment  */
	generalInfo: systemPaymentType,
	/** Redux action to get company available languages */
    getSystemAvailableLanguages: PropTypes.func,
    /** Redux state property, represents the object of available system languages */
    systemAvailableLanguages: PropTypes.object,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
	{
		getSystemPaymentGeneralInfo: id => {
			dispatch(getSystemPaymentGeneralInfo(id));
		},

		saveSystemPaymentGeneralInfo: data => {
			dispatch(saveSystemPaymentGeneralInfo(data));
		},

		getSystemAvailableLanguages: () => {
            dispatch(getSystemAvailableLanguages())
        },
	}
)

const mapStateToProps = state => {
	return {
		generalInfo: state.systemPayments.edit.general,
		isSaving: state.systemPayments.isSaving,
		isLoading: state.systemPayments.isLoading,
		systemAvailableLanguages: state.common.systemAvailableLanguages,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemPaymentGeneralInfo);