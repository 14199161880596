import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

const invoiceService = {
    getAvailablePaymentMethods(data, signal){
        return httpService.get({
            url: API_URL.GET_INVOICE_AVAILABLE_PAYMENTS,
            signal,
            queryData: data
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getAvailableWallets(data, signal){
        return httpService.get({
            url: API_URL.GET_AFFILIATE_WALLETS,
            signal,
            queryData: data
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getPaymentTranslations(data, signal){
        return httpService.get({
            url: API_URL.GET_SYSTEM_PAYMENT_METHOD_FORM_TRANSLATIONS,
            signal,
            queryData: data
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    createInvoice(data, signal){
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            if(data[key]){
                formData.append(key, data[key]);
            }
        });

        return httpService.post({
            url: API_URL.CREATE_INVOICE,
            signal,
            requestBody: formData,
            configs: {
                headers: {
                    "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
                }
            }
        }).then(({ data }) => {
            return data;
        })
    }

}

export default invoiceService;