import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import useDate from "core/hooks/useDate";
import useAutosuggestion from 'hooks/useAutosuggestion';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import Input from 'components/common/input';

import { setRequestsFilters, getRequests } from "store/actions/portal/developer/requests/requests.action";

import { MONTHS } from "constants/common.constants"
import { PLATFORM_ERROR_CODES } from "constants/errorCode.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

/** Requests Page Filters Component */

const Filters = ({
    setRequestsFilters,
    getRequests,
    filters
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const YEARS = Array(dateService.getCurrentYMD().year - 2020 + 1).fill().map((_, idx) => 2020 + idx);

    const [ projectNames, getProjectNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.PROJECT, 
        autoGet: false
    });

    const projectNameOptions = useMemo(() => {
        return [{ value: "", text: t('backoffice.common.all') }].concat(
            projectNames.map(project => ({value: project.id, text: project.name}))
        )
    }, [projectNames, t])

    return (
        <FiltersWrapper
            loadFn={() => getRequests()}
            setFiltersFn={filters => setRequestsFilters(filters)}
            filters={filters}
            onInit={() => {
                getProjectNames();
            }}
        >
            <Row gutter={[16, 0]}>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.project')}
                        name="projectId"
                    >
                        <Select
                            options={projectNameOptions}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.project')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.action')}
                        name="action"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.action')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.errorlogs.action')}`}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.errorlogs.status')}
                        name="status"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: PLATFORM_ERROR_CODES.BAD_REQUEST, text: t('backoffice.errorlogs.badRequest') },
                                { value: PLATFORM_ERROR_CODES.UNAUTHORIZED, text: t('backoffice.errorlogs.unauthorized') },
                                { value: PLATFORM_ERROR_CODES.FORBIDDEN, text: t('backoffice.errorlogs.forbidden') },
                                { value: PLATFORM_ERROR_CODES.NOT_FOUND, text: t('backoffice.errorlogs.notFound') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.month')}
                        name="month"
                    >
                        <Select
                            options={
                                Object.values(MONTHS).map(m => (
                                    { value: m, text: t('backoffice.months.month' + m) }
                                ))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.month")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.year')}
                        name="year"
                    >
                        <Select
                            options={
                                YEARS.map(y => (
                                    { value: y, text: y }
                                ))
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.common.year")}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get requests */
    getRequests: PropTypes.func,
    /** Redux action to set requests filters */
    setRequestsFilters: PropTypes.func,
    /** Redux state property, requests filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getRequests: () => {
            dispatch(getRequests(false))
        },
        setRequestsFilters: filters => {
            dispatch(setRequestsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.requests.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);

