import { useEffect, useState } from "react";

import useDate from "core/hooks/useDate";

import { getOperationalLineChartData } from "../api";

import { makeTimePeriodCustomRangeOutputValue } from "../helpers";

const useOperationalLineChart = (dataType, period, updateLastUpdatedDate, isActive, affiliateId) => {

    const { dateService } = useDate();

    const [chartState, setChartState] = useState({
        isLoading: false,
        chartData: [],
    })

    useEffect(() => {
        if(isActive === false){
            return
        }

        setChartState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            }
        })

        const { request, cancelRequest } = getOperationalLineChartData({
            dataType,
            timePeriod: period?.period,
            affiliateId,
            ...makeTimePeriodCustomRangeOutputValue(period?.from, period?.to, dateService)
        })

        request
            .then((response) => {
                const { data: { value: payload } } = response;
                updateLastUpdatedDate(payload.lastUpdatedAt);
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                        chartData: payload.data,
                        // chartData: payload.data.map(d => ({
                        //     ...d,
                        //     registrationsCount: Math.floor(Math.random() * (1000 - 100 + 1) + 100), 
                        //     cpaCount: Math.floor(Math.random() * (3000 - 500 + 1) + 500), 
                        //     ftdCount: Math.floor(Math.random() * (1500 - 250 + 1) + 250), 
                        // }))
                    }
                })
            })
            .catch(() => {
                setChartState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false
                    }
                })
            })

        return () => {
            cancelRequest();
        }
    }, [dataType, period, updateLastUpdatedDate, isActive, affiliateId])

    return chartState;

}

export default useOperationalLineChart;