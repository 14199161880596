import React, { useRef, useState } from "react";

import { useTranslation } from "core/hooks/useTranslation";

import { Input } from 'antd';

import NumericInput from "components/common/numericInput";
import Icon from "components/common/icon";

import { isMobile } from "utils/common";

import useFirstRender from "hooks/useFirstRender";

import searchType from 'types/components/search.type';

/** Input Search Component */
const InputSearch = ({
    loadFn,
    placeholder,
    onSearch,
    onChange,
    maxLength,
    isNumeric,
    value: controledValue = "",
    inputRef
}) => {

    const { t } = useTranslation();

    const isSearched = useRef(false);

    const [value, setValue] = useState(controledValue);

    /** If changed from outside , then update input value */
    useFirstRender({
        afterFirstRenderCB: () => {
            handleChange(controledValue);
        },
        dependencies: [controledValue]
    })

    /** Input focus state */
    const [isFocused, setIsFocused] = useState(false);

    const handleSearch = v => {
        if(v !== ""){
            isSearched.current = true;
            onSearch?.(v);
            setTimeout(() => {
                loadFn?.(true);
            }, 20)
        } else {
            if(isSearched.current){
                onSearch?.(v);
                setTimeout(() => {
                    loadFn?.(true);
                }, 20)
                isSearched.current = false
            }
        }
        
    }

    const handleChange = v => {
        if(v){
            setValue(v);
        } else {
            setValue(v);
            handleSearch("")
        }
        onChange?.(v);
    }

    const clear = () => {
        handleChange("")
    };

    const commonProps = {
        placeholder: placeholder && isMobile() ? placeholder : t('backoffice.common.search'),
        maxLength: maxLength,
        type: 'search',
        prefix: (<Icon name="search" />),
        value: value
    }

    if(isMobile()){
        commonProps.onFocus = () => setIsFocused(true);
        commonProps.onBlur = () => setTimeout(() => setIsFocused(false), 0)
        
        commonProps.onBlur = () => setTimeout(() => {
            if (!iconClicked) {
                setIsFocused(false);
            }
        }, 0);
        
        let iconClicked = false;
        
        commonProps.suffix = value ? (
            <Icon 
                name="close"
                onClick={() => {
                    iconClicked = true;
                    clear();
                }}
            />
        ) : undefined;
    } else {
        commonProps.suffix = value ? (<Icon name="close" onClick={clear} />) : undefined
    }

    return (
        <div className="rt--table-search rt--table-search-input">
            {
                !isMobile() && <label className="rt--search-label">{placeholder}</label>
            }
            <div
                className={
                    "rt--flex rt--align-center rt--table-search-input-wrapper" + 
                    ( isFocused ? " rt--table-search-input-wrapper-focused rt--mt-8" : "")
                }
            >
                {
                    isNumeric ? (
                        <NumericInput 
                            { ...commonProps }
                            isInteger={true}
                            isOriginalInput={true}
                            onChange={handleChange}
                            onPressEnter={e => {
                                const inputValue = e?.target?.value ?? "";
                                if(inputValue && inputValue.length > 1){
                                    handleSearch(inputValue);
                                }
                            }}
                        />
                    ) : (
                        <Input 
                            { ...commonProps }
                            onChange = {e => handleChange(e.target.value)}
                            ref={inputRef}
                            onPressEnter={e => {
                                const inputValue = e?.target?.value ?? "";
                                if(inputValue && inputValue.length > 1){
                                    handleSearch(inputValue);
                                }
                            }}
                        />
                    )
                }
            </div>
        </div>
    )
}

/** Search propTypes
    * PropTypes
*/
InputSearch.propTypes = searchType;

export default InputSearch;

