import { lazy } from "react";

import Spin from "core/ui-kit/spin";

import withSuspenseHoc from "core/HOCs/withSuspense";

import ROUTES from "core/constants/routes/routes";

const AdditionalAccessContainer = withSuspenseHoc(lazy(() => import("./additionalAccessContainer")), <Spin />);

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

const AdditionalAccessPageRoute = {
	path: ROUTES.ADDITIONAL_ACCESS,
	element: AdditionalAccessContainer, 
    permission: { resource: PERMISSION_RESOURCE.PROJECT_ADDITIONAL_ACCESS_SETTINGS, action: PERMISSION_ACTION.VIEW },
};

export default AdditionalAccessPageRoute;