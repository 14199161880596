//#region sections
import Banner from "pages/newsletterCustomize/editor/sections/banner";
import Section1 from "pages/newsletterCustomize/editor/sections/section1";
import Section2 from "pages/newsletterCustomize/editor/sections/section2";
import Button from "pages/newsletterCustomize/editor/sections/button";
//#endregion

//#region styles
import Colors from 'pages/newsletterCustomize/editor/styles/colors';
import Fonts from 'pages/newsletterCustomize/editor/styles/fonts';
import Logo from 'pages/newsletterCustomize/editor/styles/logo';
//#endregion

export const NEWSLETTER_STATUS = {
    SCHEDULED: 1 << 0,
    SENT: 1 << 1
}

export const NEWSLETTER_TEMPLATE = {
    DEFAULT: 1,
    PURPLE: 2
}

export const NEWSLETTER_TEMPLATE_CONFIGS = {
    [NEWSLETTER_TEMPLATE.DEFAULT]: {
        COLORS: ["primaryColor", "titleColor", "descriptionColor", "bgColor", "backgroundTopColor", "backgroundBottomColor"],
        FILES: ["logo", "banner1", "banner2"]
    }
}

export const NEWSLETTER_SECTION_TYPE = {
    BANNER: 1 << 0,
    SECTION_1: 1 << 1,
    BUTTON: 1 << 2,
    SECTION_2: 1 << 3
}

export const SECTION_NAMES = {
    BANNER: "banner",
    BUTTON: "button",
    SECTION_1: "section1",
    SECTION_2: "section2",

    COLORS: "colors",
    FONTS: "fonts",
    LOGO: "logo",
}

export const SECTION_PREDEFINED_DATA_BY_SECTION_NAMES = {
    [SECTION_NAMES.BANNER]: {
        key: SECTION_NAMES.BANNER,
        sectionType: NEWSLETTER_SECTION_TYPE.BANNER,
        text: "backoffice.communication.banner",
        content: Banner
    },
    [SECTION_NAMES.SECTION_1]: {
        key: SECTION_NAMES.SECTION_1,
        sectionType: NEWSLETTER_SECTION_TYPE.SECTION_1,
        text: "backoffice.communication.section1",
        content: Section1
    },
    [SECTION_NAMES.BUTTON]: {
        key: SECTION_NAMES.BUTTON,
        sectionType: NEWSLETTER_SECTION_TYPE.BUTTON,
        text: "backoffice.communication.button",
        content: Button
    },
    [SECTION_NAMES.SECTION_2]: {
        key: SECTION_NAMES.SECTION_2,
        sectionType: NEWSLETTER_SECTION_TYPE.SECTION_2,
        text: "backoffice.communication.section2",
        content: Section2
    }
}

export const SECTIONS_DEFAULT_SELECTED_KEY = SECTION_PREDEFINED_DATA_BY_SECTION_NAMES[SECTION_NAMES.BANNER].key;

export const STYLES_EDITOR_MENU_ITEMS = {
    [SECTION_NAMES.COLORS]: {
        key: SECTION_NAMES.COLORS,
        text: "backoffice.promo.colors",
        hideDeactivationSwitch: true,
        content: Colors,
    },

    [SECTION_NAMES.FONTS]: {
        key: SECTION_NAMES.FONTS,
        text: "backoffice.promo.fonts",
        hideDeactivationSwitch: true,
        content: Fonts,
    },

    [SECTION_NAMES.LOGO]: {
        key: SECTION_NAMES.LOGO,
        text: "backoffice.promo.logo",
        hideDeactivationSwitch: true,
        content: Logo,
    }
}

export const STYLES_DEFAULT_SELECTED_KEY = SECTION_NAMES.COLORS // Colors

export const NEWSLETTER_FILE_NAMES = {
    LOGO: "logo",
    BANNER1: "banner1",
    BANNER2: "banner2"
}

export const NEWSLETTER_DEFAULT_FILES = {
    "logo": "system/newsletter/images/logo.png",
    "affiliate-logo": "system/newsletter/images/footer-logo.png",
    "banner1": "system/newsletter/images/default-bg.png",
    "banner2": "system/newsletter/images/default-bg.png"
}

export const NEWSLETTER_TEMPLATE_HTML = {
    [NEWSLETTER_TEMPLATE.DEFAULT] : `
    <!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

  <head>
    <!--[if gte mso 9]> <xml> <o:officedocumentsettings> <o:allowpng> <o:pixelsperinch>96</o:pixelsperinch> </o:officedocumentsettings> </xml> <![endif]-->
    <meta content="text/html; charset=UTF-8" http-equiv=Content-Type>
    <meta content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no" name=viewport>
    <meta name=x-apple-disable-message-reformatting>
    <!--[if !mso]> <!-->
    <meta content="IE=edge" http-equiv=X-UA-Compatible> <!-- <![endif]-->
    <title></title>
    <style>
    @font-face {
      font-family: Sf-Pro-Display;
      font-style: normal;
      font-weight: 400;
      src: local("Sf-Pro-Display"),
        url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYREGULAR.woff)
          format("woff");
    }
    @font-face {
      font-family:Sf-Pro-Display;
      font-style: normal;
      font-weight: 500;
      src: local("Sf-Pro-Display"),
        url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYMEDIUM.woff)
          format("woff");
    }
    
    @font-face {
      font-family: Sf-Pro-Display;
      font-style: normal;
      font-weight: 700;
      src: local("Sf-Pro-Display"),
        url(https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBOLD.woff) format("woff");
    }
    
    </style>
    <style>
      @media only screen and (min-width:620px) {
        .u-row .u-col {
          vertical-align: top
        }

        .u-row .u-col-50 {
          width: 300px !important
        }
      }

      @media (max-width:620px) {
        .u-row-container {
          max-width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important
        }

        .u-col-50 .v-container-padding-padding div {
          justify-content: start !important
        }

        #u_content_html_1 .v-container-padding-padding {
          padding-top: 20px !important
        }

        .u-col-50 .v-container-padding-padding .v-text-align.v-font-size {
          text-align: left !important
        }

        .u-row .u-col {
          max-width: 100% !important;
          display: block !important
        }

        .u-row {
          width: 100% !important
        }

        .u-col {
          width: 100% !important
        }

        .u-col>div {
          margin: 0 auto
        }
      }

      body {
        margin: 0;
        padding: 0
      }

      table,
      td,
      tr {
        vertical-align: top;
        border-collapse: collapse;
        padding: 0;
      }

      p {
        margin: 0
      }

      .ie-container table,
      .mso-container table {
        table-layout: fixed
      }

      * {
        line-height: inherit
      }

      a[x-apple-data-detectors=true] {
        color: inherit !important;
        text-decoration: none !important
      }

      table,
      td {
        color: var(--titleColor)
      }

      #u_body a {
        color: #fdfdfd;
        text-decoration: none
      }

      @media (max-width:480px) {
        #u_row_14.v-row-padding--vertical {
          padding-top: 0 !important;
          padding-bottom: 0 !important
        }

        #u_content_text_30 .v-container-padding-padding {
          padding: 30px 0 14px !important
        }

        #section1 .v-container-padding-padding {
          padding: 20px 30px 0 !important
        }

        #section1 .v-font-size {
          font-size: 18px !important
        }

        #u_content_text_20 .v-container-padding-padding {
          padding: 16px 30px 0 !important
        }

        #u_content_text_20 .v-font-size {
          font-size: 14px !important
        }

        #button .v-container-padding-padding {
          padding: 40px 0 !important
        }

        #u_content_image_12 .v-container-padding-padding {
          padding: 0 30px !important
        }

        #section2 .v-container-padding-padding {
          padding: 24px 30px 40px !important
        }

        #section2 .v-font-size {
          font-size: 18px !important
        }

        #u_content_text_21 .v-container-padding-padding {
          padding: 16px 30px 60px !important
        }

        #u_content_text_21 .v-font-size {
          font-size: 14px !important
        }

        #u_row_11.v-row-padding--vertical {
          padding-top: 20px !important;
          padding-bottom: 49px !important
        }

        #u_content_html_1 .v-container-padding-padding {
          padding: 24px 0 0 !important
        }

        #u_content_text_29 .v-text-align {
          text-align: left !important
        }
      }
    </style>
    <!--[if !mso]> <!-->
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap" rel=stylesheet>
    <!-- <![endif]-->

  <body class="clean-body u_body" style=margin:0;padding:0;-webkit-text-size-adjust:100%>
    <!--[if IE]> <div class=ie-container> <![endif]-->
    <!--[if mso]> <div class=mso-container> <![endif]-->
    <div>
      <table cellpadding=0 cellspacing=0
        style="border-collapse:collapse;border-spacing:0;mso-table-lspace:0;mso-table-rspace:0;vertical-align:top;min-width:320px;Margin:0 auto;width:100%;background-color:var(--backgroundTopColor);background:linear-gradient(to top,var(--backgroundBottomColor) 51.5%,var(--backgroundTopColor) 49.5%)"
        id=u_body>
        <tr style=vertical-align:top>
          <td
            style=word-break:break-word;border-collapse:collapse!important;vertical-align:top;padding-left:48px;padding-right:48px>
            <div style="width: 600px; margin: 0 auto; max-width:calc(100vw - 96px);" align = "center">
            <!--[if (mso)|(IE)]> <table cellpadding=0 cellspacing=0 border=0 width=100%> <tr> <td style=background-color:var(--backgroundTopColor) align=center> <![endif]-->
            <div style=padding:0;background-color:transparent class="u-row-container v-row-padding--vertical"
              id=u_row_14>
              <div
                style="margin:0 auto;max-width:600px;overflow-wrap:break-word;word-wrap:break-word;word-break:break-word;background-color:transparent"
                class=u-row>
                <div style=border-collapse:collapse;display:table;width:100%;height:100%;background-color:transparent>
                  <!--[if (mso)|(IE)]> <table cellpadding=0 cellspacing=0 border=0 width=100%> <tr> <td style=padding:0;background-color:transparent align=center> <table cellpadding=0 cellspacing=0 border=0 style=width:600px> <tr style=background-color:transparent> <![endif]-->
                  <!--[if (mso)|(IE)]> <td style="width:600px;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0"align=center width=600 valign=top> <![endif]-->
                  <div style="max-width:600px;display:table-cell;vertical-align:top; width: 100%; margin: 0 auto;"
                    class="u-col u-col-100">
                    <div
                      style=height:100%;width:100%!important;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0>
                      <!--[if (!mso)&(!IE)]> <!-->
                      <div
                        style="box-sizing:border-box;height:100%;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0">
                        <!-- <![endif]-->
                        <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                          style=font-family:var(--font) id=u_content_text_30>
                          <tr>
                            <td
                              style="overflow-wrap:break-word;word-break:break-word;padding:140px 0 20px;font-family:var(--font)"
                              align=center class=v-container-padding-padding>
                              <img alt="logo" id="logo" style="max-width:400px; max-height:64px; width: 100%; margin:0 auto; display:block" />
                        </table>
                        <!--[if (!mso)&(!IE)]> <!-->
                      </div> <!-- <![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]> <![endif]-->
                  <!--[if (mso)|(IE)]> <![endif]-->
                </div>
              </div>
            </div>

            <div id="banner">
                <img alt="banner" id="banner1" style="border-top-left-radius:20px;border-top-right-radius:20px;width:100%; display: block"; outline: none; text-decoration: none; clear: both; height: auto; float: none; border: none; width: 100%; />
            </div>

                <table style="margin:0 auto;max-width:600px;overflow-wrap:break-word;word-wrap:break-word;word-break:break-word;background-color:var(--bgColor); border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;">
                  <tr>
                    <td>
                      <div
                      
                      class=u-row>
                      <div style=border-collapse:collapse;display:table;width:100%;height:100%;background-color:transparent>
                        <!--[if (mso)|(IE)]> <table cellpadding=0 cellspacing=0 border=0 width=100%> <tr> <td style=padding:0;background-color:transparent align=center> <table cellpadding=0 cellspacing=0 border=0 style=width:600px> <tr style=background-color:transparent> <![endif]-->
                        <!--[if (mso)|(IE)]> <td style="background-color:#fff;width:600px;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0"align=center width=600 valign=top> <![endif]-->
                        
                              <div style=max-width:600px; display:table-cell;vertical-align:top
                                class="u-col u-col-100">
                                <div
                                  style="height:100%;width:100%!important;border-bottom-left-radius:20px;border-bottom-right-radius:20px">
                                  <!--[if (!mso)&(!IE)]> <!-->
                                  <div
                                    style="box-sizing:border-box;height:100%;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0">
                                    <!-- <![endif]-->
                                    <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                      style=font-family:var(--font) id=section1>
                                      <tr>
                                        <td
                                          style="overflow-wrap:break-word;word-break:break-word;padding:80px 80px 0;font-family:var(--font)"
                                          align=center class=v-container-padding-padding>
                                          <!--[if mso]> <table width=100%> <tr> <td> <![endif]-->
                                          <h1 class="v-text-align v-font-size"
                                            style=margin:0;line-height:140%;text-align:center;word-wrap:break-word;font-size:32px;font-weight:500>
                                            <span> <span> <span> <span> <span> <span> <span> <span> <span> <span> <span>
                                                                  <span
                                                                    data-metadata=" <!--(figmeta)eyJmaWxlS2V5IjoiUnhTRE9lR3ZOSGk3Zk5hWGNWUlZJcSIsInBhc3RlSUQiOjEwNDgwMTY0ODAsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->">
                                                                  </span> <span id=title1>Section Title 1</span> </span>
                                                              </span>
                                                            </span> </span> </span> </span> </span> </span> </span> </span>
                                            </span>
                                          </h1>
                                          <!--[if mso]> <![endif]-->
                                      <tr>
                                        <td
                                          style="overflow-wrap:break-word;word-break:break-word;padding:24px 80px 0;font-family:var(--font)"
                                          align=center class=v-container-padding-padding>
                                          <div
                                            style=font-size:16px;color:var(--descriptionColor);line-height:140%;text-align:center;word-wrap:break-word;font-weight:400
                                            class="v-text-align v-font-size"> <span style=line-height:140%> <span
                                                style=line-height:22.4px
                                                data-metadata=" <!--(figmeta)eyJmaWxlS2V5IjoiUnhTRE9lR3ZOSGk3Zk5hWGNWUlZJcSIsInBhc3RlSUQiOjE4MzczNDY3ODMsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->">
                                              </span> <span style=line-height:22.4px id=description1>Our Brand Affiliates offers
                                                one
                                                of the most competitive online Affiliate Programs in theindustry. We focus
                                                on
                                                building and maintaining a true partnership with each of ouraffiliates and
                                                improve
                                                our mutual success.</span> </span> </div>
                                    </table>
                                    
                                    <table cellpadding="0" cellspacing="0" border="0" width="100%" role="presentation" style="font-family: Arial, sans-serif;" id=button>
                                      <!-- Spacer row for margin-top -->
                                      <tr>
                                          <td height="60"></td>
                                      </tr>
                                      <tr>
                                          <td align="center">
                                              <!--[if mso]>
                                              <table cellpadding="0" cellspacing="0" border="0" align="center" width="auto">
                                                  <tr>
                                                      <td>
                                              <![endif]-->
                                              <table cellpadding="0" cellspacing="0" border="0" style="border-radius: 6px; background-color: var(--primaryColor);">
                                                  <tr>
                                                      <td style="padding: 11px 24px;">
                                                          <a id=buttonlink href="" target="_blank" style="display: block; color: #ffffff; text-decoration: none;">
                                                              <span id=buttontext>Login</span>
                                                          </a>
                                                      </td>
                                                  </tr>
                                              </table>
                                              <!--[if mso]>
                                                      </td>
                                                  </tr>
                                              </table>
                                              <![endif]-->
                                          </td>
                                      </tr>
                                      <!-- Spacer row for margin-bottom -->
                                      <tr>
                                          <td height="60"></td>
                                      </tr>
                                  </table>

                                    <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                      style=font-family:var(--font) id=section2>
                                      <tr>
                                        <td
                                          style="overflow-wrap:break-word;word-break:break-word;padding:0 80px;font-family:var(--font)"
                                          align=center class=v-container-padding-padding>
                                          <table cellpadding=0 cellspacing=0 border=0 width=100%>
                                            <tr>
                                              <td style=padding-right:0;padding-left:0 align=center class=v-text-align>
                                              <img alt="banner" id="banner2" style="width:100%;border-radius:20px; display:block"/>
                                          </table>
                                      <tr>
                                        <td
                                          style="overflow-wrap:break-word;word-break:break-word;padding:48px 80px 0;font-family:var(--font)"
                                          align=center class=v-container-padding-padding>
                                          <!--[if mso]> <table width=100%> <tr> <td> <![endif]-->
                                          <h1 class="v-text-align v-font-size"
                                            style=margin:0;line-height:140%;text-align:center;word-wrap:break-word;font-size:32px;font-weight:500>
                                            <span> <span> <span> <span> <span> <span> <span> <span> <span> <span> <span
                                                                  data-metadata=" <!--(figmeta)eyJmaWxlS2V5IjoiUnhTRE9lR3ZOSGk3Zk5hWGNWUlZJcSIsInBhc3RlSUQiOjEwNDgwMTY0ODAsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->">
                                                                </span> <span id=title2>Section Title 2</span> </span>
                                                            </span>
                                                          </span> </span> </span> </span> </span> </span> </span> </span>
                                          </h1>
                                          <!--[if mso]> <![endif]-->
                                      <tr>
                                        <td
                                          style="overflow-wrap:break-word;word-break:break-word;padding:24px 90px 120px 80px; font-family:var(--font)"
                                          align=center class=v-container-padding-padding>
                                          <div
                                            style=font-size:16px;color:var(--descriptionColor);line-height:140%;text-align:center;word-wrap:break-word
                                            class="v-text-align v-font-size"> <span style=line-height:140%> <span
                                                style=line-height:19.6px
                                                data-metadata=" <!--(figmeta)eyJmaWxlS2V5IjoiUnhTRE9lR3ZOSGk3Zk5hWGNWUlZJcSIsInBhc3RlSUQiOjE4MzczNDY3ODMsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->">
                                              </span> <span style=line-height:19.6px id=description2>Our Brand Affiliates offers
                                                one
                                                of the most competitive online Affiliate Programs in theindustry. We focus
                                                on
                                                building and maintaining a true partnership with each of ouraffiliates and
                                                improve
                                                our mutual success.</span> </span> </div>
                                    </table>
                                    <!--[if (!mso)&(!IE)]> <!-->
                                  </div> <!-- <![endif]-->
                                </div>
                              </div>
                           
    
                        <!--[if (mso)|(IE)]> <![endif]-->
                        <!--[if (mso)|(IE)]> <![endif]-->
                      </div>
                    </div>
                    </td>
                  </tr>
                </table>
               


              <table style="max-width: 600px; margin: 0 auto; width: 100%;">
                <tr>
                  <td style="padding:20px 0 174px;">
                    <div style="background-color:transparent"
                    class="u-row-container v-row-padding--vertical" id=u_row_11>
                    <div
                      style="margin:0 auto;min-width:320px;max-width:600px;overflow-wrap:break-word;word-wrap:break-word;word-break:break-word;background-color:transparent"
                      class=u-row>
                      <div style=border-collapse:collapse;display:table;width:100%;height:100%;background-color:transparent>
                        <!--[if (mso)|(IE)]> <table cellpadding=0 cellspacing=0 border=0 width=100%> <tr> <td style="padding:20px 0 174px;background-color:transparent"align=center> <table cellpadding=0 cellspacing=0 border=0 style=width:600px> <tr style=background-color:transparent> <![endif]-->
                        <!--[if (mso)|(IE)]> <td style="width:300px;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0"align=center width=300 valign=top> <![endif]-->
                        <div style=max-width:320px;min-width:300px;display:table-cell;vertical-align:top
                          class="u-col u-col-50">
                          <div
                            style=height:100%;width:100%!important;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0>
                            <!--[if (!mso)&(!IE)]> <!-->
                            <div
                              style="box-sizing:border-box;height:100%;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0">
                              <!-- <![endif]-->
                              <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                style=font-family:var(--font)>
                                <tr>
                                  <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:0 0 4px;font-family:var(--font)"
                                    align=left class=v-container-padding-padding>
                                    <div
                                      style=font-size:12px;color:#a0a3bd;line-height:140%;text-align:left;word-wrap:break-word
                                      class="v-text-align v-font-size"> <span style=line-height:140%>Best Regards,</span>
                                    </div>
                              </table>
                              <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                style=font-family:var(--font)>
                                <tr>
                                  <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:0 0 4px;font-family:var(--font)"
                                    align=left class=v-container-padding-padding>
                                    <div
                                      style=font-size:12px;color:#a0a3bd;line-height:140%;text-align:left;word-wrap:break-word
                                      class="v-text-align v-font-size"> <span style=line-height:140%>{{PROJECT_NAME}}</span>
                                    </div>
                              </table>
                              <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                style=font-family:var(--font)>
                                <tr>
                                  <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:0 0 4px;font-family:var(--font)"
                                    align=left class=v-container-padding-padding>
                                    <div
                                      style=font-size:12px;color:#a0a3bd;line-height:140%;text-align:left;word-wrap:break-word
                                      class="v-text-align v-font-size"> <span style=line-height:140%>{{PROJECT_EMAIL}}</span>
                                    </div>
                              </table>
                              <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                style=font-family:var(--font)>
                                <tr>
                                  <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:0 0 4px;font-family:var(--font)"
                                    align=left class=v-container-padding-padding>
                                    <div
                                      style=font-size:12px;color:#a0a3bd;line-height:140%;text-align:left;word-wrap:break-word
                                      class="v-text-align v-font-size"> <span style=line-height:140%>{{PROJECT_MAIN_DOMAIN}}</span>
                                    </div>
                              </table>
                              <!--[if (!mso)&(!IE)]> <!-->
                            </div> <!-- <![endif]-->
                          </div>
                        </div>
                        <!--[if (mso)|(IE)]> <![endif]-->
                        <!--[if (mso)|(IE)]> <td style="width:300px;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0"align=center width=300 valign=top> <![endif]-->
                        <div style=max-width:320px;min-width:300px;display:table-cell;vertical-align:top
                          class="u-col u-col-50">
                          <div
                            style=height:100%;width:100%!important;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0>
                            <!--[if (!mso)&(!IE)]> <!-->
                            <div
                              style="box-sizing:border-box;height:100%;padding:0;border-top:0 solid transparent;border-left:0 solid transparent;border-right:0 solid transparent;border-bottom:0 solid transparent;border-radius:0;-webkit-border-radius:0;-moz-border-radius:0">
                              <!-- <![endif]-->
                              <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                style=font-family:var(--font) id=u_content_html_1>
                                <tr>
                                  <td style=overflow-wrap:break-word;word-break:break-word;padding:0;font-family:var(--font)
                                    align=left class=v-container-padding-padding>
                                    <div>
                                      <div style=display:flex;justify-content:end;align-items:center> <span
                                          style="color:#a0a3bd;font-size:12px; margin-right: 8px">Powered By</span>
                                          <img alt="logo" id="affiliate-logo" style="max-width:400px ;max-height:64px; display:block" />
                                      </div>
                                    </div>
                              </table>
                              <table cellpadding=0 cellspacing=0 border=0 width=100% role=presentation
                                style=font-family:var(--font) id=u_content_text_29>
                                <tr>
                                  <td
                                    style="overflow-wrap:break-word;word-break:break-word;padding:8px 0 0;font-family:var(--font)"
                                    align=left class=v-container-padding-padding>
                                    <div
                                      style=font-size:12px;color:#a0a3bd;line-height:140%;text-align:right;word-wrap:break-word
                                      class="v-text-align v-font-size"> <span style=line-height:140%> <span
                                          style=line-height:16.8px
                                          data-metadata=" <!--(figmeta)eyJmaWxlS2V5IjoiUnhTRE9lR3ZOSGk3Zk5hWGNWUlZJcSIsInBhc3RlSUQiOjEzNTYzOTA0MzYsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->">
                                        </span> <span style=line-height:16.8px>© <span style=line-height:16.8px
                                            data-metadata=" <!--(figmeta)eyJmaWxlS2V5IjoiUnhTRE9lR3ZOSGk3Zk5hWGNWUlZJcSIsInBhc3RlSUQiOjYyMDI0MjE3NCwiZGF0YVR5cGUiOiJzY2VuZSJ9Cg==(/figmeta)-->">
                                          </span> <span style=line-height:16.8px>{{SCHEDULEDDATE_YEAR}}</span> </br> <span style=line-height:16.8px>{{PROJECT_NAME}}</span> <span
                                            style=line-height:16.8px
                                            data-metadata=" <!--(figmeta)eyJmaWxlS2V5IjoiUnhTRE9lR3ZOSGk3Zk5hWGNWUlZJcSIsInBhc3RlSUQiOjEwOTIyMTc1NTgsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)-->">
                                          </span> </br> <span style=line-height:16.8px;color:#838fff> <a href={{UNSUBSCRIBE_LINK}} style=color:#838fff
                                              target=_blank rel=noopener> <span style=line-height:16.8px>Unsubscribe</span>
                                            </a> </span> </span> </span> </div>
                              </table>
                              <!--[if (!mso)&(!IE)]> <!-->
                            </div> <!-- <![endif]-->
                          </div>
                        </div>
                        <!--[if (mso)|(IE)]> <![endif]-->
                        <!--[if (mso)|(IE)]> <![endif]-->
                      </div>
                    </div>
                  </div>
                  </td>
                </tr>
              </table>
          
            <!--[if (mso)|(IE)]> <![endif]-->
            </div>
            </td>
            </tr>
      </table>
    </div>
    <!--[if mso]> <![endif]-->
    <!--[if IE]>`
}