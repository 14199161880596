import React, {Fragment, useState, useEffect, useRef} from "react";
import {Tabs as AntTabs, Popover} from 'antd';
import {
    useNotificationsSelector,
    notificationsStore,
    notificationsSelector,
    totalSelector,
    unreadNotificationsSelector,
    unreadTotalSelector,
    soundEnabledSelector,
    playSoundSelector,
} from "core/stores/notificationsStore";
import {globalCompanyIdSelector, globalProjectIdSelector, useAuthSelector} from "core/stores/authStore";
import {useTranslation} from "core/hooks/useTranslation";
import useDevice from "core/hooks/useDevice";
import Tooltip from "core/ui-kit/tooltip";
import NotificationList from "./components/notificationList";
import NotificationSound from "core/ui-kit/notificationSound";
import Icon from "core/ui-kit/icon";
import Modal from "core/ui-kit/modal";
import notificationsService from "core/services/apiServices/notificationsService";
import {NOTIFICATIONS_TABS, TABS, pageLimit} from "./constants";
import {POPUP_SIZE} from "core/constants/common";
import Responsive from "core/ui-kit/responsive";
import {useGlobalSelector, userInfoSelector} from "core/stores/globalStore";

const Notifications = () => {
    const { t } = useTranslation();
    const { isMobile } = useDevice();

    const [opened, setOpened] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [operationalLoading, setOperationalLoading] = useState(false);

    const isLoadingRef = useRef(false);

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);
    const userInfo = useGlobalSelector(userInfoSelector);

    const currentUserId = userInfo?.id;

    const notifications = useNotificationsSelector(notificationsSelector);
    const total = useNotificationsSelector(totalSelector);

    const unreadNotifications = useNotificationsSelector(unreadNotificationsSelector);
    const unreadTotal = useNotificationsSelector(unreadTotalSelector);

    const isSoundEnabled = useNotificationsSelector(soundEnabledSelector);
    const playSound = useNotificationsSelector(playSoundSelector);

    const notificationsPagesCount = Math.ceil(total / pageLimit);
    const unreadNotificationsPagesCount = Math.ceil(unreadTotal / pageLimit);

    const allCurrentPage = notifications.length % pageLimit === 0 ? Math.floor(notifications.length / pageLimit) : Math.floor(notifications.length / pageLimit)+ 1;
    const unreadCurrentPage = unreadNotifications.length % pageLimit === 0 ? Math.floor(unreadNotifications.length / pageLimit) : Math.floor(unreadNotifications.length / pageLimit) + 1;

    const loadMoreUnreadNotifications = () => {
        if (unreadNotificationsPagesCount === unreadCurrentPage || isLoadingRef.current) return;
        isLoadingRef.current = true;
        const nextPage = unreadNotifications.length % pageLimit === 0 ? unreadCurrentPage + 1 : unreadCurrentPage;

        notificationsStore.getUnreadNotifications({
            page: nextPage,
            limit: pageLimit,
        }).finally(() => {
            isLoadingRef.current = false;
        });
    }

    const loadInitialUnreadNotifications = () => {
        setIsLoading(true);
        isLoadingRef.current = true;

        notificationsStore.getUnreadNotifications({
            page: 1,
            limit: pageLimit,
        }).finally(() => {
            setIsLoading(false);
            isLoadingRef.current = false;
        });
    }

    const loadMoreNotifications = () => {
        if (notificationsPagesCount === allCurrentPage || isLoadingRef.current) return;
        isLoadingRef.current = true;
        const nextPage = notifications.length % pageLimit === 0 ? allCurrentPage + 1 : allCurrentPage;

        notificationsStore.getNotifications({
            page: nextPage,
            limit: pageLimit,
        }).finally(() => {
            isLoadingRef.current = false;
        });
    }

    const loadInitialNotifications = () => {
        setIsLoading(true);
        isLoadingRef.current = true;
        notificationsStore.getNotifications({
            page: 1,
            limit: pageLimit,
        }).finally(() => {
            isLoadingRef.current = false;
            setIsLoading(false);
        });
    }

    const toggleSound = () => {
        notificationsStore.toggleSound();
    }

    const stopSound = () => {
        notificationsStore.stopSound();
    }

    const markAllAsRead = () => {
        notificationsService.notificationsMarkAllAsRead()
            .then(() => {
                notificationsStore.markAllAsRead();
            })
    }

    const deleteAll = () => {
        notificationsService.notificationsDeleteAll()
            .then(() => {
                notificationsStore.reset();
            })
    }

    const removeNotification = (id, type, state) => {
        notificationsService.removeNotification({ id })
            .then(() => {
                notificationsStore.removeNotification(id, type, state);
            });
    }

    const markAsRead = (id, type) => {
        setOperationalLoading(true);
        notificationsService.notificationMarkAsRead({ id })
            .then(() => {
                notificationsStore.markAsRead(id, type);
            })
            .finally(() => {
                setOperationalLoading(false);
            })
    }

    const onDestroy = (type) => {
        if (type === TABS.all) {
            notificationsStore.destroyAll();
        }
        if (type === TABS.unread) {
            notificationsStore.destroyUnread();
        }
    }

    const closeNotifications = () => {
        setOpened(false);
    }

    useEffect(loadInitialNotifications, [currentUserId]);

    const renderHeaderControl = () => {
        return (
            <>
                <Tooltip
                    title={isSoundEnabled ? t('backoffice.notifications.mute') : t('backoffice.notifications.unmute')}
                    trigger={["hover"]}
                    placement="top"
                    getPopupContainer={null}
                >
                    <button
                        type="button"
                        className="btn-reset rt--settings-btn rt--flex rt--align-center rt--justify-center rt--mr-12"
                        onClick={toggleSound}
                    >
                        <Icon size={20} name={!isSoundEnabled ? 'mute' : 'unmute'}/>
                    </button>
                </Tooltip>
                <Tooltip
                    title={t('backoffice.notifications.markAllAsRead')}
                    trigger={["hover"]}
                    placement="top"
                    getPopupContainer={null}
                >
                    <button
                        type="button"
                        className="btn-reset rt--settings-btn rt--flex rt--align-center rt--justify-center rt--mr-12"
                        onClick={() => markAllAsRead()}
                    >
                        <Icon size={20} name="read"/>
                    </button>
                </Tooltip>
                <Tooltip
                    title={t('backoffice.notifications.deleteAll')}
                    trigger={["hover"]}
                    placement="top"
                    getPopupContainer={null}
                >
                    <button
                        type="button"
                        className="btn-reset rt--settings-btn rt--flex rt--align-center rt--justify-center rt--mr-12"
                        onClick={() => deleteAll()}
                    >
                        <Icon size={20} name="bin"/>
                    </button>
                </Tooltip>
            </>
        )
    }

    const renderMobileTitle = () => {
        return (
            <div className="rt--mobile-notifications-header">
                <span className="rt-mobile-ntf-heading">{t('backoffice.notifications.notifications')}</span>
                <div className="rt-mobile-ntf-actions">
                    {renderHeaderControl()}
                </div>
            </div>
        )
    }

    const render = () => {
        return (
            <div className="rt--notifications-dropdown">
                {
                    !isMobile && (
                        <div className="rt--notifications-head rt--flex rt--align-center rt--justify-between rt--pr-16 rt--pl-24">
                            <span className="rt--font-big rt--font-extra-bold">
                                {t('backoffice.notifications.notifications')}
                            </span>
                            <div className="rt--flex">
                                {renderHeaderControl()}
                            </div>
                        </div>
                    )
                }
                <div className="rt--notifications-body">
                    <AntTabs
                        animated={false}
                        defaultActiveKey={NOTIFICATIONS_TABS.ALL}
                        destroyInactiveTabPane={true}
                        className='rt--tabs'
                    >
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.notifications.all")}</span>}
                            key={NOTIFICATIONS_TABS.ALL}
                        >
                            <NotificationList
                                type={TABS.all}
                                notifications={notifications}
                                onLoadMore={loadMoreNotifications}
                                onInitialLoad={loadInitialNotifications}
                                onDestroy={onDestroy}
                                isLoading={isLoading}
                                removeNotification={removeNotification}
                                markAsRead={markAsRead}
                                globalProjectId={globalProjectId}
                                globalCompanyId={globalCompanyId}
                                closeNotifications={closeNotifications}
                                totalCount={total}
                                operationalLoading={operationalLoading}
                            />
                        </AntTabs.TabPane>
                        <AntTabs.TabPane
                            tab={
                                (
                                    <>
                                        <span>{t("backoffice.notifications.Unread")}</span>
                                        {
                                            unreadTotal > 0 && (
                                                <div className="rt--unread--count tab">
                                                    <span>{unreadTotal}</span>
                                                </div>
                                            )
                                        }
                                    </>
                                )
                            }
                            key={NOTIFICATIONS_TABS.UNREAD}
                        >
                            <NotificationList
                                type={TABS.unread}
                                notifications={unreadNotifications}
                                onLoadMore={loadMoreUnreadNotifications}
                                onInitialLoad={loadInitialUnreadNotifications}
                                onDestroy={onDestroy}
                                isLoading={isLoading}
                                removeNotification={removeNotification}
                                markAsRead={markAsRead}
                                globalProjectId={globalProjectId}
                                globalCompanyId={globalCompanyId}
                                closeNotifications={closeNotifications}
                                totalCount={unreadTotal}
                                operationalLoading={operationalLoading}
                            />
                        </AntTabs.TabPane>
                    </AntTabs>
                </div>
            </div>
        )
    }

    const renderMainButton = () => {
        const moreThan = unreadTotal > 99;
        const unreadTotalStr = moreThan ? '99+' : `${unreadTotal}`;
        const style = moreThan ? { fontSize: '9px' } : {};
        return (
            <div className="rt-notifications-btn"
                 onClick={(evt) => {
                     evt.stopPropagation();
                     evt.preventDefault();
                     setOpened(true);
                 }}>
                <Icon name="bell"/>
                {
                    unreadTotal > 0 && (
                        <div className="rt--unread--count">
                            <span style={style}>{unreadTotalStr}</span>
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <Fragment>
            <Responsive
                mobile={
                    (
                        <Fragment>
                            {renderMainButton()}
                            {
                                opened && (
                                    <Fragment>
                                        <Modal
                                            open={opened}
                                            title={renderMobileTitle()}
                                            size={POPUP_SIZE.MIDDLE}
                                            onCancel={() => setOpened(false)}
                                        >
                                            {render()}
                                        </Modal>
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    )
                }
                desktop={
                    (
                        <Popover
                            content={(
                                <div className="rt--notifications-holder">{render()}</div>
                            )}
                            trigger="click"
                            placement="bottomRight"
                            visible={opened}
                            onVisibleChange={(state) =>  setOpened(state)}
                            onOpenChange={setOpened}
                            destroyPopupOnHide
                        >
                            {renderMainButton()}
                        </Popover>
                    )
                }
            />
            {isSoundEnabled && playSound && <NotificationSound onEnded={stopSound}/>}
        </Fragment>
    )
}

export default Notifications;