import React, { useRef, useCallback, useState } from 'react';

import { useTranslation } from "core/hooks/useTranslation";
import useAccess from "core/hooks/useAccess";

import { Tabs as AntTabs } from 'antd';

import TabDashboardLayout from "components/layouts/tab";

import General from "./tabs/general";
import Earning from './tabs/earning';
import TopAffiliates from "./tabs/topAffiliates";
import TopPlayers from './tabs/topPlayers';
import LastUpdated from '../components/lastUpdated';

import { getHashValue, isMobile, updateLocationHash } from 'utils/common';

import { FINANCIAL_TAB_NAMES } from '../constants';
import { USER_ROLE } from 'constants/user.constants';

import useDate from "core/hooks/useDate";
import useCurrentProject from 'core/hooks/useCurrentProject';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { ADDITIONAL_ACCESS_TYPE } from 'core/constants/additionalAccess';

const getActiveTab = () => {
    const tabKey = getHashValue("tab");

    return tabKey ? tabKey : FINANCIAL_TAB_NAMES.GENERAL
}

const FinancialDashboard = () => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector);
    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const { hasAccess } = useAccess();

    const currentProject = useCurrentProject()

    const { dateService } = useDate();

    const [lastUpdatedDate, setLastUpdatedDate] = useState(null);

    const activeTabRef = useRef(getActiveTab());

    const handleTabChange = (tab) => {
        updateLocationHash("tab=" + tab);
    }

    const updateLastUpdatedDate = useCallback((newDate) => {
        setLastUpdatedDate((prevState) => {
            if (lastUpdatedDate === null || dateService.isAfter(newDate, lastUpdatedDate)) {
                return newDate;
            }

            return prevState;
        })
    }, [])

    const canViewTopAffiliates = () => {
        if(userRole === USER_ROLE.AFFILIATE){
            return false;
        } 

        if([
            ADDITIONAL_ACCESS_TYPE.NGR,
            ADDITIONAL_ACCESS_TYPE.GGR,
            ADDITIONAL_ACCESS_TYPE.BET_AMOUNT
        ].some(access => hasAccess(access))){
            return true;
        }

        if(currentProject.commissionEnabled){
            return true;
        }

        return false;
    }

    const canViewTopPlayers = () => {

        return [
            ADDITIONAL_ACCESS_TYPE.NGR,
            ADDITIONAL_ACCESS_TYPE.GGR,
            ADDITIONAL_ACCESS_TYPE.BET_AMOUNT,
            ADDITIONAL_ACCESS_TYPE.DEPOSIT_AMOUNT
        ].some(access => hasAccess(access));
    }


    return (
        <TabDashboardLayout
            header={
                isMobile() ? { extra: <LastUpdated lastUpdatedDate={lastUpdatedDate} hasFilter={true} /> } : undefined
            }
            breadcrumbs={{
                items: [{ title: t('backoffice.menu.financial') }]
            }}
        >
            <AntTabs
                animated={false}
                defaultActiveKey={activeTabRef.current}
                destroyInactiveTabPane={true}
                onChange={ handleTabChange }
                className='rt--tabs rt--chart-tab'
                tabBarExtraContent={
                    !isMobile() ? <LastUpdated lastUpdatedDate={lastUpdatedDate} /> : undefined
                }
            >
                <AntTabs.TabPane
                    tab={<span>{t("backoffice.dashboard.general")}</span>}
                    key={FINANCIAL_TAB_NAMES.GENERAL}
                >
                    <General updateLastUpdatedDate={updateLastUpdatedDate} />
                </AntTabs.TabPane>
                {
                    currentProject.commissionEnabled && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.earning")}</span>}
                            key={FINANCIAL_TAB_NAMES.EARNING}
                        >
                            <Earning updateLastUpdatedDate={updateLastUpdatedDate} />
                        </AntTabs.TabPane>
                    )
                }

                {
                    canViewTopAffiliates() && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.topAffiliates")}</span>}
                            key={FINANCIAL_TAB_NAMES.TOP_AFFILIATES}
                        >
                            <TopAffiliates updateLastUpdatedDate={updateLastUpdatedDate} />
                        </AntTabs.TabPane>
                    )
                }
                {
                    canViewTopPlayers() && (
                        <AntTabs.TabPane
                            tab={<span>{t("backoffice.dashboard.topPlayers")}</span>}
                            key={FINANCIAL_TAB_NAMES.TOP_PLAYERS}
                        >
                            <TopPlayers updateLastUpdatedDate={updateLastUpdatedDate} />
                        </AntTabs.TabPane>
                    )
                }
            </AntTabs>
        </TabDashboardLayout>
    )
}

export default FinancialDashboard;