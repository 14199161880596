import React, { Fragment, useEffect } from "react";
import { Outlet } from "react-router-dom";


import { globalStore, mainInfoLoaderSelector, useGlobalSelector } from "core/stores/globalStore";
import { useAuthSelector, userSelector } from "core/stores/authStore";

import ConfirmationProvider from "components/common/confirmationProvider";
import UnsavedChangesProvider from "components/common/unsavedChangesProvider";

import { Layout } from 'core/ui-kit/layout';
import Spin from "core/ui-kit/spin";

import SignalR from "core/global/signalR";
import SessionExpireModal from "core/global/sessionExpireModal";
import Hotjar from "core/global/hotjar";

import useLogo from "core/hooks/useLogo";

import { USER_ROLE } from "core/constants/auth";
import { LOGO_TYPE } from "core/constants/common/logo";


const MainLayout = () => {

    const isMainInfoLoading = useGlobalSelector(mainInfoLoaderSelector)
    const currentUser = useAuthSelector(userSelector);

    /** Logos */
    const { logos } = useLogo()

    useEffect(() => {        
        Promise.all([
            globalStore.getUserInfo(),
            (
                currentUser?.role !== USER_ROLE.ACCESS_MANAGER &&
                currentUser?.projectId !== null
            ) && globalStore.getCurrencies()
        ]).finally(() => {
            globalStore.setMainInfoLoading(false)
        })
    }, [])

    /** Set Favicon */
    useEffect(() => {
        const favicon = document.querySelector('link[rel="icon"]');
        favicon.href = `${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.FAVICON]}`;
    }, [logos])

    return (
        <Layout className='rt--portal-layout'>
            {
                isMainInfoLoading ? <Spin /> : (
                    <ConfirmationProvider>
                        <UnsavedChangesProvider>
                            <SignalR />
                            <Hotjar />
                            <SessionExpireModal />
                            <Outlet />
                       </UnsavedChangesProvider>
                    </ConfirmationProvider>
                )
            }
        </Layout>
    )
}

export default MainLayout;