import { memo, useEffect } from "react";

import { useAuthSelector, userSelector } from "core/stores/authStore";
import { useGlobalSelector, userInfoSelector } from "core/stores/globalStore";

import HotjarService from "core/services/integrationServices/hotjarService";
import getCurrentCompany from "core/helpers/common/getCurrentCompany";
import getCurrentProject from "core/helpers/common/getCurrentProject";

const Hotjar = () => {

    const user = useAuthSelector(userSelector);
    const userInfo = useGlobalSelector(userInfoSelector);

    useEffect(() => {

        const { companies } = userInfo;

        const company = getCurrentCompany(companies, user.companyId);
        const project = getCurrentProject(companies, user.companyId, user.projectId)
        
        HotjarService.identify(userInfo.id, {
            longId: userInfo.longId,
            name: `${userInfo.firstName} ${userInfo.lastName}`,
            companyId: user.companyId,
            projectId: user.projectId,
            companyName: company?.name,
            projectName: project?.name,
            role: user.role,
            userName: user.userName
        })
    }, [user, userInfo])

    return null;
}

export default memo(Hotjar);