import React from 'react';
import Tooltip from 'core/ui-kit/tooltip';

const Ellipsis = ({
    value,
    maxWidth = 260,
    emptySymbol = '-',
}) => {
    return (
        <div className='rt--table-col rt--flex rt--align-center rt--table-col-copy'>
            <Tooltip placement='top' title={value}>
                <div className='rt--ml-8 rt--ellipsis' style={{ maxWidth: `${maxWidth}px` }}>{value || emptySymbol}</div>
            </Tooltip>
        </div>
    );
}

export default Ellipsis;