export const MEDIA_CLICK_STATE = {
    VALID: 0,
    AFFILIATE_NOT_ALLOWED: 2,
    MEDIA_NOT_FOUND: 4,
    MEDIA_NOT_ALLOWED: 8,
    BRAND_NOT_FOUND: 16,
    COUNTRY_NOT_DETECTED: 32,
    COUNTRY_NOT_ALLOWED: 64,
    CAMPAIGN_NOT_FOUND: 256,
    CAMPAIGN_NOT_ALLOWED: 512
}

export const MEDIA_CLICK_STATE_TRANSLATION_KEYS = {
    [MEDIA_CLICK_STATE.VALID] : "backoffice.reports.valid",
    [MEDIA_CLICK_STATE.AFFILIATE_NOT_ALLOWED] : "backoffice.reports.affiliateHasNoAccessForCampaign",
    [MEDIA_CLICK_STATE.MEDIA_NOT_FOUND] : "backoffice.reports.unknownMedia",
    [MEDIA_CLICK_STATE.MEDIA_NOT_ALLOWED] : "backoffice.reports.inactiveMedia",
    [MEDIA_CLICK_STATE.BRAND_NOT_FOUND] : "backoffice.reports.unknownBrand",
    [MEDIA_CLICK_STATE.COUNTRY_NOT_DETECTED] : "backoffice.reports.unknownCountry",
    [MEDIA_CLICK_STATE.COUNTRY_NOT_ALLOWED] : "backoffice.reports.restrictedCountry",
    [MEDIA_CLICK_STATE.CAMPAIGN_NOT_FOUND] : "backoffice.reports.unknownCampaign",
    [MEDIA_CLICK_STATE.CAMPAIGN_NOT_ALLOWED] : "backoffice.reports.inactiveCampaign"
}

export const TOTAL_TYPE = {
    AFFILIATE_PERFORMACE_REPORT: 1,
    PLAYER_PERFORMANCE_REPORT: 2
}