import React, {forwardRef, useRef} from 'react';

const Scroller = forwardRef(
    ( {className, children, onScroll, onScrollEnd, offset}, ref) => {
        const scrollEndTriggered = useRef(false);

        const handleScroll = ({target: {clientHeight, scrollTop, scrollHeight}}) => {
            const triggerLinePassed = clientHeight + scrollTop + offset >= scrollHeight;

            if (triggerLinePassed) {
                if (!scrollEndTriggered.current) {
                    onScrollEnd?.();
                    scrollEndTriggered.current = true;
                }
            } else {
                scrollEndTriggered.current = false;
            }
        }

        return (
            <div
                ref={ref}
                className={className}
                style={{overflow: "auto", height: '100%'}}
                onScroll={event => {
                    onScroll?.(event);
                    handleScroll(event);
                }}
            >
                {children}
            </div>
        );
    });

export default Scroller;
