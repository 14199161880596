import { useCallback } from 'react';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import { currenciesSelector, currencySelector, useGlobalSelector } from 'core/stores/globalStore';

const useCurrencyConversion = () => {

    //#region ------------------------------------------- REDUX --------------------------------------------//

    const currencies = useGlobalSelector(currenciesSelector);
    const selectedCurrencyCode = useGlobalSelector(currencySelector);
    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    //#endregion

    const getRate = currency => {
        return currency?.currency.rate ?? 1;
    }

    const convertCurrencyToDefault = useCallback((amount, currencyCode) => {
        let rate;
        let currency = currencies.find(c => c.currencyCode.toLowerCase() === currencyCode.toLowerCase() && c.projectId === globalProjectId);

        if (!currency) {
            currency = currencies.find(c => c.currencyCode.toLowerCase() === currencyCode.toLowerCase());
        }

        if (!currency) return amount;

        rate = getRate(currency);

        return amount * rate;
    }, [currencies, globalProjectId])

    const convertCurrency = useCallback( ( amount, from, to=selectedCurrencyCode) => {
        amount = amount ?? 0;
        if(from?.toLowerCase() === to.toLowerCase()) return amount;

        let fromCurrency = currencies.find(c => c.currencyCode.toLowerCase() === from?.toLowerCase() && c.projectId === globalProjectId);
        
        if (!fromCurrency) {
            fromCurrency = currencies.find(c => c.currencyCode.toLowerCase() === from?.toLowerCase());
        }

        if (!fromCurrency) return amount;

        let rate;

        rate = getRate(fromCurrency);

        return convertCurrencyToDefault(amount/rate, to);
    }, [currencies, selectedCurrencyCode, globalProjectId])

    const getConversionRate = useCallback((from, to) => {
        if(from?.toLowerCase() === to.toLowerCase()) return 1;
        
        let fromCurrency = currencies.find(c => c.currencyCode.toLowerCase() === from?.toLowerCase() && c.projectId === globalProjectId);
        
        if (!fromCurrency) {
            fromCurrency = currencies.find(c => c.currencyCode.toLowerCase() === from?.toLowerCase());
        }

        if (!fromCurrency) return 1;

        const fromRate = getRate(fromCurrency);

        let toCurrency = currencies.find(c => c.currencyCode.toLowerCase() === to?.toLowerCase() && c.projectId === globalProjectId);

        if (!toCurrency) {
            toCurrency = currencies.find(c => c.currencyCode.toLowerCase() === to?.toLowerCase());
        }

        if (!toCurrency) return 1;

        const toRate = getRate(toCurrency);

        return fromRate/toRate;

    }, [currencies, globalProjectId])

    return {
        convertCurrency, getConversionRate
    }
}

export default useCurrencyConversion;