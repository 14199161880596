import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DropdownRangePicker from '../../components/dropdownRangePicker';
import Select from 'components/common/select';

import currencyType from 'types/profile/currency.type';
import FilterByAffiliate from 'pages/dashboard/components/filterByAffiliate';

const DesktopFilters = ({
    filters,
    updateFilters,
    showDataType=false,
    showCurrency=false,
    currencies=[],
    dataTypeFields=[],
    showAffiliateFilter,
}) => {

    const currencyFieldOptions = useMemo(() => {
        return currencies.map(currency => ({
            value: currency.currencyCode,
            text: currency.currencyCode
        }))
    }, [currencies])

    return (
        <div className="rt--flex">
            {
                showAffiliateFilter && (
                    <FilterByAffiliate
                        onChange={(affiliateId) => updateFilters({ affiliateId: affiliateId })}
                        value={filters?.affiliateId}
                    />
                )
            }

            {
                showCurrency && (
                    <div className="rt--flex rt--justify-end rt--align-center rt--ml-16 rt--chart-filter">
                        <Select
                            options={currencyFieldOptions}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            onChange={value => updateFilters({ currencyCode: value})}
                            value={filters.currencyCode}
                        />
                    </div>
                )
            }

            {
                showDataType && (
                    <div className="rt--flex rt--justify-end rt--align-center rt--ml-16 rt--chart-filter">
                        <Select
                            options={dataTypeFields}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            onChange={value => updateFilters({ dataType: value})}
                            value={filters.dataType}
                        />
                    </div>
                )
            }
            
            <div className="rt--flex rt--justify-end rt--align-center rt--chart-filter rt--ml-16">
                <DropdownRangePicker
                    value={filters.period}
                    onChange={value => updateFilters({ period: value})}
                />
            </div>
        </div>
    )
}

DesktopFilters.propTypes = {
    updateFilters: PropTypes.func,
    filters: PropTypes.object,
    showDataType: PropTypes.bool,
    showCurrency: PropTypes.bool,
    currencies: PropTypes.arrayOf(currencyType),
    dataTypeFields: PropTypes.array
}

export default DesktopFilters;