/** Auth */

export const SET_PASSWORD_SETTINGS_ACTION_BEFORE = "SET_PASSWORD_SETTINGS_ACTION_BEFORE";
export const SET_PASSWORD_SETTINGS_ACTION_FINISH = "SET_PASSWORD_SETTINGS_ACTION_FINISH";
export const SET_PASSWORD_SETTINGS = "SET_PASSWORD_SETTINGS";
export const SET_ACCOUNTS_ACTION_BEFORE = "SET_ACCOUNTS_ACTION_BEFORE";
export const SET_ACCOUNTS_ACTION_FINISH = "SET_ACCOUNTS_ACTION_FINISH";
export const SET_ACCOUNTS_SAVE_ACTION_BEFORE = "SET_ACCOUNTS_SAVE_ACTION_BEFORE";
export const SET_ACCOUNTS_SAVE_ACTION_FINISH = "SET_ACCOUNTS_SAVE_ACTION_FINISH";
export const SET_ACCOUNT_PROJECT_ACCESS = "SET_ACCOUNT_PROJECT_ACCESS";

/** Registration */

export const SET_REGISTRATION_FORM_ACTION_BEFORE = "SET_REGISTRATION_FORM_ACTION_BEFORE";
export const SET_REGISTRATION_FORM_ACTION_FINISH = "SET_REGISTRATION_FORM_ACTION_FINISH";
export const SET_REGISTRATION_FORM = "SET_REGISTRATION_FORM";
export const SET_REGISTRATION_ACTION_BEFORE = "SET_REGISTRATION_ACTION_BEFORE";
export const SET_REGISTRATION_ACTION_FINISH = "SET_REGISTRATION_ACTION_FINISH";
export const SET_REGISTRATION_AVAILABLE_CURRENCIES = "SET_REGISTRATION_AVAILABLE_CURRENCIES";
export const SET_REGISTRATION_AVAILABLE_PAYMENTS = "SET_REGISTRATION_AVAILABLE_PAYMENTS";
export const SET_REGISTRATION_AVAILABLE_CHANNELS = "SET_REGISTRATION_AVAILABLE_CHANNELS";
export const SET_REGISTRATION_PASSWORD_SETTINGS = "SET_REGISTRATION_PASSWORD_SETTINGS";
export const SET_REGISTRATION_PAYMENT_DETAILS = "SET_REGISTRATION_PAYMENT_DETAILS";

/** Common */

export const SET_ALL_COMPANIES = "SET_ALL_COMPANIES";
export const SET_GLOBAL_COMPANY_ID = "SET_GLOBAL_COMPANY_ID";
export const SET_GLOBAL_PROJECT_ID = "SET_GLOBAL_PROJECT_ID";

export const SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE = "SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_BEFORE";
export const SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH = "SET_SYSTEM_AVAILABLE_LANGUAGES_ACTION_FINISH";
export const SET_SYSTEM_AVAILABLE_LANGUAGES = "SET_SYSTEM_AVAILABLE_LANGUAGES";

export const SET_PROJECT_AVAILABLE_LANGUAGES_BEFORE = "SET_PROJECT_AVAILABLE_LANGUAGES_BEFORE";
export const SET_PROJECT_AVAILABLE_LANGUAGES_FINISH = "SET_PROJECT_AVAILABLE_LANGUAGES_FINISH";
export const SET_PROJECT_AVAILABLE_LANGUAGES = "SET_PROJECT_AVAILABLE_LANGUAGES";

/** Companies */

export const SET_COMPANIES_ACTION_BEFORE = "SET_COMPANIES_ACTION_BEFORE";
export const SET_COMPANIES_ACTION_FINISH = "SET_COMPANIES_ACTION_FINISH";
export const SET_COMPANIES = "SET_COMPANIES";
export const SET_COMPANIES_SORTING = "SET_COMPANIES_SORTING";
export const SET_COMPANIES_FILTERS = "SET_COMPANIES_FILTERS";
export const SET_COMPANIES_SAVE_ACTION_BEFORE = "SET_COMPANIES_SAVE_ACTION_BEFORE";
export const SET_COMPANIES_SAVE_ACTION_FINISH = "SET_COMPANIES_SAVE_ACTION_FINISH";
export const SET_COMPANY_GENERAL_INFO = "SET_COMPANY_GENERAL_INFO";

export const SET_COMPANY_PASSWORD_SETTINGS = "SET_COMPANY_PASSWORD_SETTINGS";
export const SET_COMPANY_TOKEN_SETTINGS = "SET_COMPANY_TOKEN_SETTINGS";
export const SET_COMPANY_AUTHENTICATION_SETTINGS = "SET_COMPANY_AUTHENTICATION_SETTINGS";

/** Affiliates */

export const SET_AFFILIATES_ACTION_BEFORE = "SET_AFFILIATES_ACTION_BEFORE";
export const SET_AFFILIATES_ACTION_FINISH = "SET_AFFILIATES_ACTION_FINISH";
export const SET_AFFILIATES_SAVE_ACTION_BEFORE = "SET_AFFILIATES_SAVE_ACTION_BEFORE";
export const SET_AFFILIATES_SAVE_ACTION_FINISH = "SET_AFFILIATES_SAVE_ACTION_FINISH";
export const SET_AFFILIATES = "SET_AFFILIATES";
export const SET_AFFILIATES_SORTING = "SET_AFFILIATES_SORTING";
export const SET_AFFILIATES_FILTERS = "SET_AFFILIATES_FILTERS";
export const SET_AFFILIATE_WALLETS_BY_CURRENCY = "SET_AFFILIATE_WALLETS_BY_CURRENCY";
export const SET_AFFILIATE_GENERAL_INFO = "SET_AFFILIATE_GENERAL_INFO";
export const SET_AFFILIATE_ADDITIONAL_INFO = "SET_AFFILIATE_ADDITIONAL_INFO";
export const SET_AFFILIATE_TRAFFIC_SOURCES = "SET_AFFILIATE_TRAFFIC_SOURCES";
export const SET_AFFILIATES_SUB_AFFILIATES = "SET_AFFILIATES_SUB_AFFILIATES";
export const SET_AFFILIATES_SUB_AFFILIATES_FILTERS = "SET_AFFILIATES_SUB_AFFILIATES_FILTERS";
export const SET_AFFILIATE_PAYMENT_METHODS_ACTION_BEFORE = "SET_AFFILIATE_PAYMENT_METHODS_ACTION_BEFORE";
export const SET_AFFILIATE_PAYMENT_METHODS_ACTION_FINISH = "SET_AFFILIATE_PAYMENT_METHODS_ACTION_FINISH";
export const SET_AFFILIATE_PAYMENT_METHODS = "SET_AFFILIATE_PAYMENT_METHODS";
export const SET_AFFILIATE_AVAILABLE_PAYMENT_METHODS = "SET_AFFILIATE_AVAILABLE_PAYMENT_METHODS";
export const SET_AFFILIATE_PAYMENT_METHOD_FORM_CONTROLS = "SET_AFFILIATE_PAYMENT_METHOD_FORM_CONTROLS";
export const SET_AFFILIATE_PAYMENT_METHOD_DETAILS = "SET_AFFILIATE_PAYMENT_METHOD_DETAILS";
export const SET_AFFILIATE_NEGATIVE_CARRY_OVER = "SET_AFFILIATE_NEGATIVE_CARRY_OVER";

/** Affiliate Groups */

export const SET_AFFILIATE_GROUPS_ACTION_BEFORE = "SET_AFFILIATE_GROUPS_ACTION_BEFORE";
export const SET_AFFILIATE_GROUPS_ACTION_FINISH = "SET_AFFILIATE_GROUPS_ACTION_FINISH";
export const SET_AFFILIATE_GROUPS_SAVE_ACTION_BEFORE = "SET_AFFILIATE_GROUPS_SAVE_ACTION_BEFORE";
export const SET_AFFILIATE_GROUPS_SAVE_ACTION_FINISH = "SET_AFFILIATE_GROUPS_SAVE_ACTION_FINISH";
export const SET_AFFILIATE_GROUPS = "SET_AFFILIATE_GROUPS";
export const SET_AFFILIATE_GROUPS_SORTING = "SET_AFFILIATE_GROUPS_SORTING";
export const SET_AFFILIATE_GROUPS_FILTERS = "SET_AFFILIATE_GROUPS_FILTERS";
export const SET_AFFILIATE_GROUP_AVAILABLE_AFFILIATES = "SET_AFFILIATE_GROUP_AVAILABLE_AFFILIATES";
export const SET_AFFILIATE_GROUP_GENERAL_INFO = "SET_AFFILIATE_GROUP_GENERAL_INFO";
export const SET_AFFILIATE_GROUP_MEMBERS = "SET_AFFILIATE_GROUP_MEMBERS";
export const SET_AFFILIATE_GROUP_MEMBERS_SORTING = "SET_AFFILIATE_GROUP_MEMBERS_SORTING";
export const SET_AFFILIATE_GROUP_MEMBERS_FILTERS = "SET_AFFILIATE_GROUP_MEMBERS_FILTERS";
export const SET_AFFILIATE_GROUP_FILTER = "SET_AFFILIATE_GROUP_FILTER";

/** Affiliate Access */

export const SET_AFFILIATE_ACCESS_SETTINGS_ACTION_BEFORE = "SET_AFFILIATE_ACCESS_SETTINGS_ACTION_BEFORE";
export const SET_AFFILIATE_ACCESS_SETTINGS_ACTION_FINISH = "SET_AFFILIATE_ACCESS_SETTINGS_ACTION_FINISH";
export const SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_BEFORE = "SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_BEFORE";
export const SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_FINISH = "SET_AFFILIATE_ACCESS_SETTINGS_SAVE_ACTION_FINISH";
export const SET_AFFILIATE_ACCESS_SETTINGS = "SET_AFFILIATE_ACCESS_SETTINGS";

/** Commissions */

export const SET_COMMISSION_PLANS_ACTION_BEFORE = "SET_COMMISSION_PLANS_ACTION_BEFORE";
export const SET_COMMISSION_PLANS_ACTION_FINISH = "SET_COMMISSION_PLANS_ACTION_FINISH";
export const SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE = "SET_COMMISSION_PLANS_SAVE_ACTION_BEFORE";
export const SET_COMMISSION_PLANS_SAVE_ACTION_FINISH = "SET_COMMISSION_PLANS_SAVE_ACTION_FINISH";
export const SET_COMMISSION_PLANS = "SET_COMMISSION_PLANS";
export const SET_COMMISSION_PLANS_FILTERS = "SET_COMMISSION_PLANS_FILTERS";
export const SET_COMMISSION_PLANS_SORTING = "SET_COMMISSION_PLANS_SORTING";
export const SET_COMMISSION_PLAN_GENERAL_INFO = "SET_COMMISSION_PLAN_GENERAL_INFO";
export const UPDATE_COMMISSION_PLAN_STATE = "UPDATE_COMMISSION_PLAN_STATE";
export const SET_AVAILABLE_COMMISSION_PLANS_ACTION_BEFORE = "SET_AVAILABLE_COMMISSION_PLANS_ACTION_BEFORE";
export const SET_AVAILABLE_COMMISSION_PLANS_ACTION_FINISH = "SET_AVAILABLE_COMMISSION_PLANS_ACTION_FINISH";
export const SET_AVAILABLE_COMMISSION_PLANS = "SET_AVAILABLE_COMMISSION_PLANS";

/** Wallets */

export const SET_WALLET_ACTION_BEFORE = "SET_WALLET_ACTION_BEFORE";
export const SET_WALLET_ACTION_FINISH = "SET_WALLET_ACTION_FINISH";
export const SET_WALLET_SAVE_ACTION_BEFORE = "SET_WALLET_SAVE_ACTION_BEFORE";
export const SET_WALLET_SAVE_ACTION_FINISH = "SET_WALLET_SAVE_ACTION_FINISH";
export const SET_AFFILIATE_WALLETS = "SET_AFFILIATE_WALLETS";
export const UPDATE_AFFILIATE_WALLETS = "UPDATE_AFFILIATE_WALLETS";
export const SET_WALLET_TRANSFER_SUCCESS = "SET_WALLET_TRANSFER_SUCCESS";
export const SET_WALLET_TRANSFER_RESULT = "SET_WALLET_TRANSFER_RESULT";
export const SET_WALLET_HISTORY = "SET_WALLET_HISTORY";
export const SET_WALLET_HISTORY_SORTING = "SET_WALLET_HISTORY_SORTING";
export const SET_WALLET_HISTORY_FILTERS = "SET_WALLET_HISTORY_FILTERS";

/** Projects */

export const SET_PROJECTS_ACTION_BEFORE = "SET_PROJECTS_ACTION_BEFORE";
export const SET_PROJECTS_ACTION_FINISH = "SET_PROJECTS_ACTION_FINISH";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PROJECTS_SORTING = "SET_PROJECTS_SORTING";
export const SET_PROJECTS_FILTERS = "SET_PROJECTS_FILTERS";
export const SET_PROJECTS_SAVE_ACTION_BEFORE = "SET_PROJECTS_SAVE_ACTION_BEFORE";
export const SET_PROJECTS_SAVE_ACTION_FINISH = "SET_PROJECTS_SAVE_ACTION_FINISH";
export const SET_PROJECT_GENERAL_INFO = "SET_PROJECT_GENERAL_INFO";
export const SET_PROJECT_CUSTOM_HOSTNAMES = "SET_PROJECT_CUSTOM_HOSTNAMES";
export const SET_PROJECT_DOMAIN_SITES = "SET_PROJECT_DOMAIN_SITES";
export const UPDATE_PROJECT_DOMAIN_SITES = "UPDATE_PROJECT_DOMAIN_SITES";
export const SET_PROJECT_REFERRAL_SETTINGS = "SET_PROJECT_REFERRAL_SETTINGS";
export const SET_PROJECT_PAYOUT_SETTINGS = "SET_PROJECT_PAYOUT_SETTINGS";
export const SET_PROJECT_FEE_SETTINGS = "SET_PROJECT_FEE_SETTINGS";
export const SET_PROJECT_INTEGRATION = "SET_PROJECT_INTEGRATION";
export const SET_GAME_PROVIDERS = "SET_GAME_PROVIDERS";
export const SET_PROJECT_CHATUI = "SET_PROJECT_CHATUI";

export const SET_PROJECT_BRANDS = "SET_PROJECT_BRANDS";
export const ADD_PROJECT_BRAND = "ADD_PROJECT_BRAND";
export const UPDATE_PROJECT_BRAND = "UPDATE_PROJECT_BRAND";
export const DELETE_PROJECT_BRAND = "DELETE_PROJECT_BRAND";

export const SET_PROJECT_LANGUAGES = "SET_PROJECT_LANGUAGES";

export const SET_PROJECT_CURRENCIES = "SET_PROJECT_CURRENCIES";
export const SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE = "SET_PROJECT_AVAILABLE_CURRENCIES_BEFORE";
export const SET_PROJECT_AVAILABLE_CURRENCIES_FINISH = "SET_PROJECT_AVAILABLE_CURRENCIES_FINISH";
export const SET_PROJECT_AVAILABLE_CURRENCIES = "SET_PROJECT_AVAILABLE_CURRENCIES";

export const SET_PROJECT_AFFILIATE_REGISTRATION_FORM = "SET_PROJECT_AFFILIATE_REGISTRATION_FORM";

export const SET_PROJECT_PAYMENT_METHODS = "SET_PROJECT_PAYMENT_METHODS";
export const SET_PROJECT_PAYMENT_STATE = "SET_PROJECT_PAYMENT_STATE";
export const SET_PROJECT_PAYMENT_AVAILABLE_CURRENCIES = "SET_PROJECT_PAYMENT_AVAILABLE_CURRENCIES";
export const ADD_PROJECT_PAYMENT_CURRENCY = "ADD_PROJECT_PAYMENT_CURRENCY";
export const SET_PROJECT_PAYMENT_METHOD_DETAILS = "SET_PROJECT_PAYMENT_METHOD_DETAILS";
export const CHANGE_PROJECT_PAYMENT_METHOD_DETAILS = "CHANGE_PROJECT_PAYMENT_METHOD_DETAILS";

export const SET_PROJECT_PROMO = "SET_PROJECT_PROMO";
export const SET_PROJECT_PROMO_STATUS = "SET_PROJECT_PROMO_STATUS";

export const SET_PROJECT_ADDITIONAL_PARAMS = "SET_PROJECT_ADDITIONAL_PARAMS";

/** Invoices */

export const SET_INVOICES_ACTION_BEFORE = "SET_INVOICES_ACTION_BEFORE";
export const SET_INVOICES_ACTION_FINISH = "SET_INVOICES_ACTION_FINISH";
export const SET_INVOICES_SAVE_ACTION_BEFORE = "SET_INVOICES_SAVE_ACTION_BEFORE";
export const SET_INVOICES_SAVE_ACTION_FINISH = "SET_INVOICES_SAVE_ACTION_FINISH";
export const SET_PENDING_INVOICES = "SET_PENDING_INVOICES";
export const SET_PENDING_INVOICES_SORTING = "SET_PENDING_INVOICES_SORTING";
export const SET_PENDING_INVOICES_FILTERS = "SET_PENDING_INVOICES_FILTERS";
export const SET_INVOICES_HISTORY = "SET_INVOICES_HISTORY";
export const SET_INVOICES_HISTORY_SORTING = "SET_INVOICES_HISTORY_SORTING";
export const SET_INVOICES_HISTORY_FILTERS = "SET_INVOICES_HISTORY_FILTERS";
export const SET_INVOICES_AVAILABLE_PAYMENT_METHODS = "SET_INVOICES_AVAILABLE_PAYMENT_METHODS";
export const SET_INVOICES_PAYMENT_TRANSLATIONS = "SET_INVOICES_PAYMENT_TRANSLATIONS";
export const SET_INVOICES_TOTALS_ACTION_BEFORE = "SET_INVOICES_TOTALS_ACTION_BEFORE";
export const SET_INVOICES_TOTALS_ACTION_FINISH = "SET_INVOICES_TOTALS_ACTION_FINISH";
export const SET_INVOICES_TOTALS = "SET_INVOICES_TOTALS";
export const SET_INVOICE_GENERATION_TYPE = "SET_INVOICE_GENERATION_TYPE";

/** Balance History */

export const SET_BALANCE_HISTORY_ACTION_BEFORE = "SET_BALANCE_HISTORY_ACTION_BEFORE";
export const SET_BALANCE_HISTORY_ACTION_FINISH = "SET_BALANCE_HISTORY_ACTION_FINISH";
export const SET_BALANCE_HISTORY = "SET_BALANCE_HISTORY";
export const SET_BALANCE_HISTORY_FILTERS = "SET_BALANCE_HISTORY_FILTERS";
export const SET_BALANCE_HISTORY_SORTING = "SET_BALANCE_HISTORY_SORTING";

/** Players */

export const SET_PLAYERS_ACTION_BEFORE = "SET_PLAYERS_ACTION_BEFORE";
export const SET_PLAYERS_ACTION_FINISH = "SET_PLAYERS_ACTION_FINISH";
export const SET_PLAYERS = "SET_PLAYERS";
export const SET_PLAYERS_SORTING = "SET_PLAYERS_SORTING";
export const SET_PLAYERS_FILTERS = "SET_PLAYERS_FILTERS";
export const SET_PLAYERS_SAVE_ACTION_BEFORE = "SET_PLAYERS_SAVE_ACTION_BEFORE";
export const SET_PLAYERS_SAVE_ACTION_FINISH = "SET_PLAYERS_SAVE_ACTION_FINISH";
export const SET_PLAYER_GENERAL_INFO = "SET_PLAYER_GENERAL_INFO";
export const SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID = "SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID";
export const SET_UPDATE_PLAYER_DATA = "SET_UPDATE_PLAYER_DATA";

/** Reports */

export const SET_MARKETING_REPORTS_ACTION_BEFORE = "SET_MARKETING_REPORTS_ACTION_BEFORE";
export const SET_MARKETING_REPORTS_ACTION_FINISH = "SET_MARKETING_REPORTS_ACTION_FINISH";

export const SET_CLICK_REPORT = "SET_CLICK_REPORT";
export const SET_CLICK_REPORT_SORTING = "SET_CLICK_REPORT_SORTING";
export const SET_CLICK_REPORT_FILTERS = "SET_CLICK_REPORT_FILTERS";

export const SET_MEDIA_REPORT = "SET_MEDIA_REPORT";
export const SET_MEDIA_REPORT_SORTING = "SET_MEDIA_REPORT_SORTING";
export const SET_MEDIA_REPORT_FILTERS = "SET_MEDIA_REPORT_FILTERS";
export const SET_MEDIA_REPORT_TOTALS = "SET_MEDIA_REPORT_TOTALS";

export const SET_PERFORMANCE_REPORTS_ACTION_BEFORE = "SET_PERFORMANCE_REPORTS_ACTION_BEFORE";
export const SET_PERFORMANCE_REPORTS_ACTION_FINISH = "SET_PERFORMANCE_REPORTS_ACTION_FINISH";

export const SET_AFFILIATE_REPORT = "SET_AFFILIATE_REPORT";
export const SET_AFFILIATE_REPORT_SORTING = "SET_AFFILIATE_REPORT_SORTING";
export const SET_AFFILIATE_REPORT_FILTERS = "SET_AFFILIATE_REPORT_FILTERS";
export const SET_AFFILIATE_REPORT_TOTALS = "SET_AFFILIATE_REPORT_TOTALS";

export const SET_PLAYER_REPORT = "SET_PLAYER_REPORT";
export const SET_PLAYER_REPORT_SORTING = "SET_PLAYER_REPORT_SORTING";
export const SET_PLAYER_REPORT_FILTERS = "SET_PLAYER_REPORT_FILTERS";
export const SET_PLAYER_REPORT_TOTALS = "SET_PLAYER_REPORT_TOTALS";

/** Aggregated Data */

export const SET_AGGREGATED_DATA_ACTION_BEFORE = "SET_AGGREGATED_DATA_ACTION_BEFORE";
export const SET_AGGREGATED_DATA_ACTION_FINISH = "SET_AGGREGATED_DATA_ACTION_FINISH";
export const SET_AGGREGATED_DATA = "SET_AGGREGATED_DATA";
export const SET_AGGREGATED_DATA_SORTING = "SET_AGGREGATED_DATA_SORTING";
export const SET_AGGREGATED_DATA_FILTERS = "SET_AGGREGATED_DATA_FILTERS";

/** Commission Calculations */

export const SET_COMMISSION_CALCULATIONS_ACTION_BEFORE = "SET_COMMISSION_CALCULATIONS_ACTION_BEFORE";
export const SET_COMMISSION_CALCULATIONS_ACTION_FINISH = "SET_COMMISSION_CALCULATIONS_ACTION_FINISH";
export const SET_COMMISSION_CALCULATIONS = "SET_COMMISSION_CALCULATIONS";
export const SET_COMMISSION_CALCULATIONS_SORTING = "SET_COMMISSION_CALCULATIONS_SORTING";
export const SET_COMMISSION_CALCULATIONS_FILTERS = "SET_COMMISSION_CALCULATIONS_FILTERS";

/** Calculation Approvals */

export const SET_CALCULATION_APPROVALS_ACTION_BEFORE = "SET_CALCULATION_APPROVALS_ACTION_BEFORE";
export const SET_CALCULATION_APPROVALS_ACTION_FINISH = "SET_CALCULATION_APPROVALS_ACTION_FINISH";
export const SET_CALCULATION_APPROVALS = "SET_CALCULATION_APPROVALS";
export const SET_CALCULATION_APPROVALS_SORTING = "SET_CALCULATION_APPROVALS_SORTING";
export const SET_CALCULATION_APPROVALS_FILTERS = "SET_CALCULATION_APPROVALS_FILTERS";

/** Users */

export const SET_USERS_ACTION_BEFORE = "SET_USERS_ACTION_BEFORE";
export const SET_USERS_ACTION_FINISH = "SET_USERS_ACTION_FINISH";
export const SET_USERS = "SET_USERS";
export const SET_USERS_SORTING = "SET_USERS_SORTING";
export const SET_USERS_FILTERS = "SET_USERS_FILTERS";
export const SET_USERS_SAVE_ACTION_BEFORE = "SET_USERS_SAVE_ACTION_BEFORE";
export const SET_USERS_SAVE_ACTION_FINISH = "SET_USERS_SAVE_ACTION_FINISH";
export const SET_USER_AVAILABLE_COMPANIES_BEFORE = "SET_USER_AVAILABLE_COMPANIES_BEFORE";
export const SET_USER_AVAILABLE_COMPANIES_FINISH = "SET_USER_AVAILABLE_COMPANIES_FINISH";
export const SET_USER_AVAILABLE_COMPANIES = "SET_USER_AVAILABLE_COMPANIES";
export const SET_USER_GENERAL_INFO = "SET_USER_GENERAL_INFO";
export const SET_USER_PERMISSION_GROUPS = "SET_USER_PERMISSION_GROUPS";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE = "SET_USER_AVAILABLE_PERMISSION_GROUPS_BEFORE";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH = "SET_USER_AVAILABLE_PERMISSION_GROUPS_FINISH";
export const SET_USER_AVAILABLE_PERMISSION_GROUPS = "SET_USER_AVAILABLE_PERMISSION_GROUPS";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const SET_USER_COMPANY_ACCESS = "SET_USER_COMPANY_ACCESS";

/** Affiliate Managers */

export const SET_AFFILIATE_MANAGERS_ACTION_BEFORE = "SET_AFFILIATE_MANAGERS_ACTION_BEFORE";
export const SET_AFFILIATE_MANAGERS_ACTION_FINISH = "SET_AFFILIATE_MANAGERS_ACTION_FINISH";
export const SET_AFFILIATE_MANAGERS = "SET_AFFILIATE_MANAGERS";
export const SET_AFFILIATE_MANAGERS_SORTING = "SET_AFFILIATE_MANAGERS_SORTING";
export const SET_AFFILIATE_MANAGERS_FILTERS = "SET_AFFILIATE_MANAGERS_FILTERS";
export const SET_AFFILIATE_MANAGERS_SAVE_ACTION_BEFORE = "SET_AFFILIATE_MANAGERS_SAVE_ACTION_BEFORE";
export const SET_AFFILIATE_MANAGERS_SAVE_ACTION_FINISH = "SET_AFFILIATE_MANAGERS_SAVE_ACTION_FINISH";
export const SET_AFFILIATE_MANAGER_GENERAL_INFO = "SET_AFFILIATE_MANAGER_GENERAL_INFO";
export const SET_AFFILIATE_MANAGER_AFFILIATES = "SET_AFFILIATE_MANAGER_AFFILIATES";
export const SET_AFFILIATE_MANAGER_AFFILIATES_SORTING = "SET_AFFILIATE_MANAGER_AFFILIATES_SORTING";
export const SET_AFFILIATE_MANAGER_AFFILIATES_FILTERS = "SET_AFFILIATE_MANAGER_AFFILIATES_FILTERS";
export const SET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES = "SET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES";

/** Communication */

export const SET_COMMUNICATION_TEMPLATES_ACTION_BEFORE = "SET_COMMUNICATION_TEMPLATES_ACTION_BEFORE";
export const SET_COMMUNICATION_TEMPLATES_ACTION_FINISH = "SET_COMMUNICATION_TEMPLATES_ACTION_FINISH";
export const SET_COMMUNICATION_TEMPLATES = "SET_COMMUNICATION_TEMPLATES";
export const SET_COMMUNICATION_TEMPLATE_ACTION_BEFORE = "SET_COMMUNICATION_TEMPLATE_ACTION_BEFORE";
export const SET_COMMUNICATION_TEMPLATE_ACTION_FINISH = "SET_COMMUNICATION_TEMPLATE_ACTION_FINISH";
export const SET_COMMUNICATION_TEMPLATE = "SET_COMMUNICATION_TEMPLATE";
export const SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_BEFORE = "SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_BEFORE";
export const SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_FINISH = "SET_COMMUNICATION_TEMPLATE_SAVE_ACTION_FINISH";
export const SET_COMMUNICATION_TEMPLATE_VARIABLES = "SET_COMMUNICATION_TEMPLATE_VARIABLES";
export const SET_COMMUNICATION_TEMPLATE_TRANSLATION = "SET_COMMUNICATION_TEMPLATE_TRANSLATION";
export const SET_TEMPLATE_SYSTEM_DEFAULT = "SET_TEMPLATE_SYSTEM_DEFAULT";

/** NewsLetter */

export const SET_NEWSLETTER_ACTION_BEFORE = "SET_NEWSLETTER_ACTION_BEFORE";
export const SET_NEWSLETTER_ACTION_FINISH = "SET_NEWSLETTER_ACTION_FINISH";
export const SET_NEWSLETTER_SAVE_ACTION_BEFORE = "SET_NEWSLETTER_SAVE_ACTION_BEFORE";
export const SET_NEWSLETTER_SAVE_ACTION_FINISH = "SET_NEWSLETTER_SAVE_ACTION_FINISH";
export const SET_NEWSLETTER_TEMPLATES = "SET_NEWSLETTER_TEMPLATES";
export const SET_NEWSLETTER_TEMPLATES_SORTING = "SET_NEWSLETTER_TEMPLATES_SORTING";
export const SET_NEWSLETTER_TEMPLATES_FILTERS = "SET_NEWSLETTER_TEMPLATES_FILTERS";
export const SET_NEWSLETTER_AFFILIATES_ACTION_BEFORE = "SET_NEWSLETTER_AFFILIATES_ACTION_BEFORE";
export const SET_NEWSLETTER_AFFILIATES_ACTION_FINISH = "SET_NEWSLETTER_AFFILIATES_ACTION_FINISH";
export const SET_NEWSLETTER_AFFILIATES = "SET_NEWSLETTER_AFFILIATES";
export const SET_NEWSLETTER_AFFILIATES_SORTING = "SET_NEWSLETTER_AFFILIATES_SORTING";
export const SET_NEWSLETTER_AVAILABLE_AFFILIATES = "SET_NEWSLETTER_AVAILABLE_AFFILIATES";
export const SET_NEWSLETTERS = "SET_NEWSLETTERS";
export const SET_NEWSLETTERS_SORTING = "SET_NEWSLETTERS_SORTING";
export const SET_NEWSLETTERS_FILTERS = "SET_NEWSLETTERS_FILTERS";
export const SET_NEWSLETTER_SEND_TEST_ACTION_BEFORE = "SET_NEWSLETTER_SEND_TEST_ACTION_BEFORE";
export const SET_NEWSLETTER_SEND_TEST_ACTION_FINISH = "SET_NEWSLETTER_SEND_TEST_ACTION_FINISH";
export const SET_NEWSLETTER_GENERAL_INFO = "SET_NEWSLETTER_GENERAL_INFO";

/** Access Managers */

export const SET_ACCESS_MANAGERS_ACTION_BEFORE = "SET_ACCESS_MANAGERS_ACTION_BEFORE";
export const SET_ACCESS_MANAGERS_ACTION_FINISH = "SET_ACCESS_MANAGERS_ACTION_FINISH";
export const SET_ACCESS_MANAGERS = "SET_ACCESS_MANAGERS";
export const SET_ACCESS_MANAGERS_SORTING = "SET_ACCESS_MANAGERS_SORTING";
export const SET_ACCESS_MANAGERS_FILTERS = "SET_ACCESS_MANAGERS_FILTERS";
export const SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE = "SET_ACCESS_MANAGERS_SAVE_ACTION_BEFORE";
export const SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH = "SET_ACCESS_MANAGERS_SAVE_ACTION_FINISH";
export const SET_ACCESS_MANAGER_GENERAL_INFO = "SET_ACCESS_MANAGER_GENERAL_INFO";

/** Profile */

export const SET_PROFILE_ACTION_BEFORE = "SET_PROFILE_ACTION_BEFORE";
export const SET_PROFILE_ACTION_FINISH = "SET_PROFILE_ACTION_FINISH";
export const SET_PROFILE_SAVE_ACTION_BEFORE = "SET_PROFILE_SAVE_ACTION_BEFORE";
export const SET_PROFILE_SAVE_ACTION_FINISH = "SET_PROFILE_SAVE_ACTION_FINISH";
export const SET_PERSONAL_INFORMATION = "SET_PERSONAL_INFORMATION";
export const SET_FORMAT_SETTINGS = "SET_FORMAT_SETTINGS";
export const CHANGE_PASSWORD_ACTION_BEFORE = "CHANGE_PASSWORD_ACTION_BEFORE";
export const CHANGE_PASSWORD_ACTION_FINISH = "CHANGE_PASSWORD_ACTION_FINISH";

/** Permissions */

export const SET_PERMISSIONS_ACTION_BEFORE = "SET_PERMISSIONS_ACTION_BEFORE";
export const SET_PERMISSIONS_ACTION_FINISH = "SET_PERMISSIONS_ACTION_FINISH";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_PERMISSION_RESOURCES_ACTION_BEFORE = "SET_PERMISSION_RESOURCES_ACTION_BEFORE";
export const SET_PERMISSION_RESOURCES_ACTION_FINISH = "SET_PERMISSION_RESOURCES_ACTION_FINISH";
export const SET_PERMISSIONS_RESOURCES = "SET_PERMISSIONS_RESOURCES";

/** Permission Groups */

export const SET_PERMISSION_GROUPS_ACTION_BEFORE = "SET_PERMISSION_GROUPS_ACTION_BEFORE";
export const SET_PERMISSION_GROUPS_ACTION_FINISH = "SET_PERMISSION_GROUPS_ACTION_FINISH";
export const SET_PERMISSION_GROUPS = "SET_PERMISSION_GROUPS";
export const SET_PERMISSION_GROUPS_SORTING = "SET_PERMISSION_GROUPS_SORTING";
export const SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE = "SET_PERMISSION_GROUPS_SAVE_ACTION_BEFORE";
export const SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH = "SET_PERMISSION_GROUPS_SAVE_ACTION_FINISH";
export const SET_PERMISSION_GROUP_GENERAL_INFO = "SET_PERMISSION_GROUP_GENERAL_INFO";
export const SET_PERMISSION_GROUP_USERS = "SET_PERMISSION_GROUP_USERS";
export const SET_PERMISSION_GROUP_USERS_SORTING = "SET_PERMISSION_GROUP_USERS_SORTING";
export const SET_PERMISSION_GROUP_USERS_FILTERS = "SET_PERMISSION_GROUP_USERS_FILTERS";

/** Permission Requets */

export const SET_PERMISSION_REQUESTS_ACTION_BEFORE = "SET_PERMISSION_REQUESTS_ACTION_BEFORE";
export const SET_PERMISSION_REQUESTS_ACTION_FINISH = "SET_PERMISSION_REQUESTS_ACTION_FINISH";
export const SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE = "SET_PERMISSION_REQUESTS_SAVE_ACTION_BEFORE";
export const SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH = "SET_PERMISSION_REQUESTS_SAVE_ACTION_FINISH";
export const SET_PERMISSION_REQUESTS = "SET_PERMISSION_REQUESTS";
export const SET_PERMISSION_REQUESTS_COUNT = "SET_PERMISSION_REQUESTS_COUNT";
export const SET_PERMISSION_REQUESTS_HISTORY = "SET_PERMISSION_REQUESTS_HISTORY";
export const SET_PERMISSION_REQUESTS_HISTORY_SORTING = "SET_PERMISSION_REQUESTS_HISTORY_SORTING";
export const SET_PERMISSION_REQUESTS_HISTORY_FILTERS = "SET_PERMISSION_REQUESTS_HISTORY_FILTERS";

/** Sessions */

export const SET_SESSIONS_ACTION_BEFORE = "SET_SESSIONS_ACTION_BEFORE";
export const SET_SESSIONS_ACTION_FINISH = "SET_SESSIONS_ACTION_FINISH";
export const SET_SESSIONS = "SET_SESSIONS";
export const SET_SESSIONS_SORTING = "SET_SESSIONS_SORTING";
export const SET_SESSIONS_FILTERS = "SET_SESSIONS_FILTERS";
export const SET_SESSIONS_SAVE_ACTION_BEFORE = "SET_SESSIONS_SAVE_ACTION_BEFORE";
export const SET_SESSIONS_SAVE_ACTION_FINISH = "SET_SESSIONS_SAVE_ACTION_FINISH";

/** Campaigns */

export const SET_CAMPAIGNS_ACTION_BEFORE = "SET_CAMPAIGNS_ACTION_BEFORE";
export const SET_CAMPAIGNS_ACTION_FINISH = "SET_CAMPAIGNS_ACTION_FINISH";
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
export const SET_CAMPAIGNS_SORTING = "SET_CAMPAIGNS_SORTING";
export const SET_CAMPAIGNS_FILTERS = "SET_CAMPAIGNS_FILTERS";
export const SET_CAMPAIGNS_SAVE_ACTION_BEFORE = "SET_CAMPAIGNS_SAVE_ACTION_BEFORE";
export const SET_CAMPAIGNS_SAVE_ACTION_FINISH = "SET_CAMPAIGNS_SAVE_ACTION_FINISH";
export const SET_CAMPAIGN_GENERAL_INFO = "SET_CAMPAIGN_GENERAL_INFO";
export const UPDATE_CAMPAIGN_STATE = "UPDATE_CAMPAIGN_STATE";
export const SET_CAMPAIGN_CREATIVES = "SET_CAMPAIGN_CREATIVES";
export const SET_CAMPAIGN_CREATIVES_SORTING = "SET_CAMPAIGN_CREATIVES_SORTING";
export const SET_CAMPAIGN_CREATIVES_FILTERS = "SET_CAMPAIGN_CREATIVES_FILTERS";
export const SET_CREATIVE_HTML_ACTION_BEFORE = "SET_CREATIVE_HTML_ACTION_BEFORE";
export const SET_CREATIVE_HTML_ACTION_FINISH = "SET_CREATIVE_HTML_ACTION_FINISH";
export const SET_CREATIVE_HTML = "SET_CREATIVE_HTML";

/** Media Tools */

export const SET_MEDIA_TOOLS_ACTION_BEFORE = "SET_MEDIA_TOOLS_ACTION_BEFORE";
export const SET_MEDIA_TOOLS_ACTION_FINISH = "SET_MEDIA_TOOLS_ACTION_FINISH";
export const SET_MEDIA_TOOLS_SAVE_ACTION_BEFORE = "SET_MEDIA_TOOLS_SAVE_ACTION_BEFORE";
export const SET_MEDIA_TOOLS_SAVE_ACTION_FINISH = "SET_MEDIA_TOOLS_SAVE_ACTION_FINISH";
export const SET_MEDIA_TOOLS = "SET_MEDIA_TOOLS";
export const SET_MEDIA_TOOLS_SORTING = "SET_MEDIA_TOOLS_SORTING";
export const SET_MEDIA_TOOLS_FILTERS = "SET_MEDIA_TOOLS_FILTERS";
export const SET_MEDIA_TOOLS_CREATION_RESULT = "SET_MEDIA_TOOLS_CREATION_RESULT";

/** System Currencies */

export const SET_SYSTEM_CURRENCIES_ACTION_BEFORE = "SET_SYSTEM_CURRENCIES_ACTION_BEFORE";
export const SET_SYSTEM_CURRENCIES_ACTION_FINISH = "SET_SYSTEM_CURRENCIES_ACTION_FINISH";
export const SET_SYSTEM_CURRENCIES = "SET_SYSTEM_CURRENCIES";
export const SET_SYSTEM_CURRENCIES_SORTING = "SET_SYSTEM_CURRENCIES_SORTING";
export const SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE = "SET_SYSTEM_CURRENCIES_SAVE_ACTION_BEFORE";
export const SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH = "SET_SYSTEM_CURRENCIES_SAVE_ACTION_FINISH";
export const SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE = "SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_BEFORE";
export const SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH = "SET_SYSTEM_AVAILABLE_CURRENCIES_ACTION_FINISH";
export const SET_SYSTEM_AVAILABLE_CURRENCIES = "SET_SYSTEM_AVAILABLE_CURRENCIES";
export const SET_AVAILABLE_CURRENCIES = "SET_AVAILABLE_CURRENCIES";

/** Languages */

export const SET_SYSTEM_LANGUAGES_ACTION_BEFORE = "SET_SYSTEM_LANGUAGES_ACTION_BEFORE";
export const SET_SYSTEM_LANGUAGES_ACTION_FINISH = "SET_SYSTEM_LANGUAGES_ACTION_FINISH";
export const SET_SYSTEM_LANGUAGES = "SET_SYSTEM_LANGUAGES";
export const SET_SYSTEM_LANGUAGES_SORTING = "SET_SYSTEM_LANGUAGES_SORTING";
export const SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE = "SET_SYSTEM_LANGUAGES_SAVE_ACTION_BEFORE";
export const SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH = "SET_SYSTEM_LANGUAGES_SAVE_ACTION_FINISH";
export const SET_AVAILABLE_LANGUAGES = "SET_AVAILABLE_LANGUAGES";

/** System Payments */

export const SET_SYSTEM_PAYMENTS_ACTION_BEFORE = "SET_SYSTEM_PAYMENTS_ACTION_BEFORE";
export const SET_SYSTEM_PAYMENTS_ACTION_FINISH = "SET_SYSTEM_PAYMENTS_ACTION_FINISH";
export const SET_SYSTEM_PAYMENTS = "SET_SYSTEM_PAYMENTS";
export const SET_SYSTEM_PAYMENT_SAVE_ACTION_BEFORE = "SET_SYSTEM_PAYMENT_SAVE_ACTION_BEFORE";
export const SET_SYSTEM_PAYMENT_SAVE_ACTION_FINISH = "SET_SYSTEM_PAYMENT_SAVE_ACTION_FINISH";
export const SET_SYSTEM_PAYMENTS_SORTING = "SET_SYSTEM_PAYMENTS_SORTING";
export const SET_SYSTEM_PAYMENTS_FILTERS = "SET_SYSTEM_PAYMENTS_FILTERS";
export const SET_SYSTEM_PAYMENT_FORM_TRANSLATION = "SET_SYSTEM_PAYMENT_FORM_TRANSLATION";
export const SET_SYSTEM_PAYMENT_FORM_TRANSLATIONS = "SET_SYSTEM_PAYMENT_FORM_TRANSLATIONS";
export const SET_SYSTEM_PAYMENTS_GENERAL_INFO = "SET_SYSTEM_PAYMENTS_GENERAL_INFO";

/** Translations */

export const SET_TRANSLATIONS_ACTION_BEFORE = "SET_TRANSLATIONS_ACTION_BEFORE";
export const SET_TRANSLATIONS_ACTION_FINISH = "SET_TRANSLATIONS_ACTION_FINISH";
export const SET_TRANSLATION_GROUPS_ACTION_BEFORE = "SET_TRANSLATION_GROUPS_ACTION_BEFORE";
export const SET_TRANSLATION_GROUPS_ACTION_FINISH = "SET_TRANSLATION_GROUPS_ACTION_FINISH";
export const SET_TRANSLATION_GROUPS = "SET_TRANSLATION_GROUPS";
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const UPDATE_TRANSLATION = "UPDATE_TRANSLATION";
export const SET_TRANSLATIONS_SAVE_ACTION_BEFORE = "SET_TRANSLATIONS_SAVE_ACTION_BEFORE";
export const SET_TRANSLATIONS_SAVE_ACTION_FINISH = "SET_TRANSLATIONS_SAVE_ACTION_FINISH";
export const SET_TRANSLATIONS_SYSTEM_DEFAULT = "SET_TRANSLATIONS_SYSTEM_DEFAULT";
export const SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE = "SET_TRANSLATIONS_PUBLISH_ACTION_BEFORE";
export const SET_TRANSLATIONS_PUBLISH_ACTION_FINISH = "SET_TRANSLATIONS_PUBLISH_ACTION_FINISH";
export const SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE = "SET_TRANSLATIONS_UNPUBLISH_ACTION_BEFORE";
export const SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH = "SET_TRANSLATIONS_UNPUBLISH_ACTION_FINISH";

/** User Logs */

export const SET_USER_LOGS_ACTION_BEFORE = "SET_USER_LOGS_ACTION_BEFORE";
export const SET_USER_LOGS_ACTION_FINISH = "SET_USER_LOGS_ACTION_FINISH";
export const SET_USER_LOGS = "SET_USER_LOGS";
export const SET_USER_LOGS_SORTING = "SET_USER_LOGS_SORTING";
export const SET_USER_LOGS_FILTERS = "SET_USER_LOGS_FILTERS";
export const SET_USER_LOG_DETAILS = "SET_USER_LOG_DETAILS";
export const SET_USER_LOG_RESOURCES = "SET_USER_LOG_RESOURCES";

/** Errors */

export const SET_ERRORS_ACTION_BEFORE = "SET_ERRORS_ACTION_BEFORE";
export const SET_ERRORS_ACTION_FINISH = "SET_ERRORS_ACTION_FINISH";
export const SET_ERRORS = "SET_ERRORS";
export const SET_ERRORS_SORTING = "SET_ERRORS_SORTING";
export const SET_ERRORS_FILTERS = "SET_ERRORS_FILTERS";

/** Platform Requests Log */

export const SET_REQUESTS_ACTION_BEFORE = "SET_REQUESTS_ACTION_BEFORE";
export const SET_REQUESTS_ACTION_FINISH = "SET_REQUESTS_ACTION_FINISH";
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_REQUESTS_SORTING = "SET_REQUESTS_SORTING";
export const SET_REQUESTS_FILTERS = "SET_REQUESTS_FILTERS";

/** Generations */
export const SET_GENERATIONS_ACTION_BEFORE = "SET_GENERATIONS_ACTION_BEFORE";
export const SET_GENERATIONS_ACTION_FINISH = "SET_GENERATIONS_ACTION_FINISH";
export const SET_GENERATIONS = "SET_GENERATIONS";

/** Jobs */

export const SET_JOBS_ACTION_BEFORE = "SET_JOBS_ACTION_BEFORE";
export const SET_JOBS_ACTION_FINISH = "SET_JOBS_ACTION_FINISH";
export const SET_JOBS = "SET_JOBS";
export const UPDATE_JOB = "UPDATE_JOB";
export const SET_JOBS_SAVE_ACTION_BEFORE = "SET_JOBS_SAVE_ACTION_BEFORE";
export const SET_JOBS_SAVE_ACTION_FINISH = "SET_JOBS_SAVE_ACTION_FINISH";
export const SET_JOB_SETTINGS = "SET_JOB_SETTINGS";
export const SET_JOB_DETAILS_ACTION_BEFORE = "SET_JOB_DETAILS_ACTION_BEFORE";
export const SET_JOB_DETAILS_ACTION_FINISH = "SET_JOB_DETAILS_ACTION_FINISH";
export const SET_JOB_DETAILS = "SET_JOB_DETAILS";

/** DB Connections */

export const SET_DB_CONNECTIONS_ACTION_BEFORE = "SET_DB_CONNECTIONS_ACTION_BEFORE";
export const SET_DB_CONNECTIONS_ACTION_FINISH = "SET_DB_CONNECTIONS_ACTION_FINISH";
export const SET_DB_CONNECTIONS = "SET_DB_CONNECTIONS";

/** Monitoring */

export const SET_MONITORING_ACTION_BEFORE = "SET_MONITORING_ACTION_BEFORE";
export const SET_MONITORING_ACTION_FINISH = "SET_MONITORING_ACTION_FINISH";
export const SET_MONITORING_SERVICE_ACTION_BEFORE = "SET_MONITORING_SERVICE_ACTION_BEFORE";
export const SET_MONITORING_SERVICE_ACTION_FINISH = "SET_MONITORING_SERVICE_ACTION_FINISH";
export const SET_MONITORING_SERVICES = "SET_MONITORING_SERVICES";
export const SET_MONITORING_SERVICE = "SET_MONITORING_SERVICE";

/** Post Deployment Actions */

export const SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE = "SET_POST_DEPLOYMENT_ACTIONS_ACTION_BEFORE";
export const SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH = "SET_POST_DEPLOYMENT_ACTIONS_ACTION_FINISH";
export const SET_LANGUAGES_ACTION_BEFORE = "SET_LANGUAGES_ACTION_BEFORE";
export const SET_LANGUAGES_ACTION_FINISH = "SET_LANGUAGES_ACTION_FINISH";
export const SET_LANGUAGES_ACTION = "SET_LANGUAGES_ACTION";
export const SET_EXPORT_ACTION_BEFORE = "SET_EXPORT_ACTION_BEFORE";
export const SET_EXPORT_ACTION_FINISH = "SET_EXPORT_ACTION_FINISH";

/** Timezones */

export const SET_SYSTEM_TIMEZONES_ACTION_BEFORE = "SET_SYSTEM_TIMEZONES_ACTION_BEFORE";
export const SET_SYSTEM_TIMEZONES_ACTION_FINISH = "SET_SYSTEM_TIMEZONES_ACTION_FINISH";
export const SET_SYSTEM_TIMEZONES = "SET_SYSTEM_TIMEZONES";

/** Platforms */

export const SET_PLATFORMS_ACTION_BEFORE = "SET_PLATFORMS_ACTION_BEFORE";
export const SET_PLATFORMS_ACTION_FINISH = "SET_PLATFORMS_ACTION_FINISH";
export const SET_PLATFORMS = "SET_PLATFORMS";
export const SET_PLATFORM_TEST_ACTION_BEFORE = "SET_PLATFORM_TEST_ACTION_BEFORE";
export const SET_PLATFORM_TEST_ACTION_FINISH = "SET_PLATFORM_TEST_ACTION_FINISH";
export const SET_PLATFORM_TESTS = "SET_PLATFORM_TESTS";

/** Promo Customization */

export const SET_PROMO_CUSTOMIZE_LANGUAGE = "SET_PROMO_CUSTOMIZE_LANGUAGE";
export const SET_PROMO_CUSTOMIZE_ACTION_BEFORE = "SET_PROMO_CUSTOMIZE_ACTION_BEFORE";
export const SET_PROMO_CUSTOMIZE_ACTION_FINISH = "SET_PROMO_CUSTOMIZE_ACTION_FINISH";
export const SET_PROMO_CUSTOMIZE_SAVE_ACTION_BEFORE = "SET_PROMO_CUSTOMIZE_SAVE_ACTION_BEFORE";
export const SET_PROMO_CUSTOMIZE_SAVE_ACTION_FINISH = "SET_PROMO_CUSTOMIZE_SAVE_ACTION_FINISH";
export const SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_BEFORE = "SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_BEFORE";
export const SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_FINISH = "SET_PROMO_CUSTOMIZE_PUBLISH_ACTION_FINISH";
export const SET_PROMO_CUSTOMIZE_CONFIGURATION = "SET_PROMO_CUSTOMIZE_CONFIGURATION";
export const SET_PROMO_CUSTOMIZE_IMAGE = "SET_PROMO_CUSTOMIZE_IMAGE";
export const SET_PROMO_CUSTOMIZE_COLORS = "SET_PROMO_CUSTOMIZE_COLORS";
export const SET_PROMO_CUSTOMIZE_FONT_FAMILY = "SET_PROMO_CUSTOMIZE_FONT_FAMILY";
export const SET_PROMO_CUSTOMIZE_SECTION_STATE = "SET_PROMO_CUSTOMIZE_SECTION_STATE";
export const SET_PROMO_CUSTOMIZE_LANGUAGES = "SET_PROMO_CUSTOMIZE_LANGUAGES";
export const SET_PROMO_CUSTOMIZE_DEFAULT_LANGUAGES = "SET_PROMO_CUSTOMIZE_DEFAULT_LANGUAGES";
export const SET_PROMO_CUSTOMIZE_SECTION_DATA = "SET_PROMO_CUSTOMIZE_SECTION_DATA";
export const SET_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS = "SET_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS";
export const UPDATE_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS = "UPDATE_PROMO_CUSTOMIZE_SECTION_TRANSLATIONS";
export const SET_PROMO_CUSTOMIZE_SECTION_TRANSLATION_GROUPS = "SET_PROMO_CUSTOMIZE_SECTION_TRANSLATION_GROUPS";

/** Newsletter Customize */

export const SET_NEWSLETTER_CUSTOMIZE_ACTION_BEFORE = "SET_NEWSLETTER_CUSTOMIZE_ACTION_BEFORE";
export const SET_NEWSLETTER_CUSTOMIZE_ACTION_FINISH = "SET_NEWSLETTER_CUSTOMIZE_ACTION_FINISH";
export const SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_BEFORE = "SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_BEFORE";
export const SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_FINISH = "SET_NEWSLETTER_CUSTOMIZE_SAVE_ACTION_FINISH";
export const SET_NEWSLETTER_CUSTOMIZE_CONFIGURATION = "SET_NEWSLETTER_CUSTOMIZE_CONFIGURATION";
export const SET_NEWSLETTER_TEMPLATE_VARIABLES = "SET_NEWSLETTER_TEMPLATE_VARIABLES";
export const SET_NEWSLETTER_CUSTOMIZE_COLORS = "SET_NEWSLETTER_CUSTOMIZE_COLORS";
export const SET_NEWSLETTER_CUSTOMIZE_FONT_FAMILY = "SET_NEWSLETTER_CUSTOMIZE_FONT_FAMILY";
export const SET_NEWSLETTER_CUSTOMIZE_IMAGE = "SET_NEWSLETTER_CUSTOMIZE_IMAGE";
export const SET_NEWSLETTER_CUSTOMIZE_TEXT = "SET_NEWSLETTER_CUSTOMIZE_TEXT";
export const SET_NEWSLETTER_CUSTOMIZE_SECTION_STATE = "SET_NEWSLETTER_CUSTOMIZE_SECTION_STATE";
