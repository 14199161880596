import React from "react";
import PropTypes from "prop-types";

import Button from "core/ui-kit/button";

import classNames from "core/helpers/common/classNames";

const ModalMobileFooter = ({
    isLoading,
    footer,
    onOk,
    onCancel,
    okText,
    cancelText,
    okButtonProps,
    cancelButtonProps,
}) => { 
    if(footer){
        return footer;
    }

    const okButtonClassName = okButtonProps.className ? okButtonProps.className: "rt--button-primary";
    const cancelButtonClassName = cancelButtonProps.className ? cancelButtonProps.className: "rt--button-ghost";

    return (
        <div className='rt--modal-footer rt--flex rt--justify-end rt--align-center'>
            {
                (cancelText && !cancelButtonProps.hidden) && (
                    <Button
                        type="link"
                        className={classNames(cancelButtonClassName, "rt--ml-8")}
                        onClick={onCancel}
                        disabled={cancelButtonProps.disabled}
                    >
                        <span>{cancelText}</span>
                    </Button>
                )
            }

            {
                (okText && !okButtonProps.hidden) && (
                    <Button
                        loading={isLoading}
                        type="primary"
                        className={classNames("rt--button", okButtonClassName, "rt--ml-16")}
                        onClick={onOk}
                        disabled={okButtonProps.disabled}
                    >
                        <span>{okText}</span>
                    </Button>
                )
            }
        </div>
    )
} 

ModalMobileFooter.propTypes = {
    isLoading: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    okButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    cancelButtonProps: PropTypes.shape({
        hidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string
    }),
    footer: PropTypes.node,
}

export default ModalMobileFooter;