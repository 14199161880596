import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Form, Radio, Divider, Tooltip, Checkbox } from 'antd';

import { useTranslation } from "core/hooks/useTranslation";

import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';
import NumericRanges from 'components/common/numericRanges';
import Icon from 'components/common/icon';

import { COMMISSION_PLAN_SOURCE, COMMISSION_PLAN_STRUCTURE } from 'constants/commission.constants';
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';

import { numberTransform } from 'utils/common';

import usePermissions from 'core/hooks/usePermission';

/** Commission Plan Creating Popup RevShare Component */
const RevShare = ({
    formInstance,
    editMode,
    getProjectFeeSettings,
    feeSettings,
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [structure, setStructure] = useState(editMode?.structure ? editMode?.structure : COMMISSION_PLAN_STRUCTURE.FLAT);
    const [source, setSource] = useState(editMode?.source ? editMode.source : null);

    const hasEditPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, editMode ? PERMISSION_ACTION.MODIFY : PERMISSION_ACTION.CREATE );

    const canEdit = hasEditPermission && (!editMode || !editMode.isAffiliate);

    const depositFee = feeSettings?.depositFee || 0;

    const feeTypeOptions = useMemo( () => {
        const options = [];
        if(source !== COMMISSION_PLAN_SOURCE.NGR && source !== COMMISSION_PLAN_SOURCE.NET_DEPOSIT) return options;
        if(source === COMMISSION_PLAN_SOURCE.NGR){
            options.push({
                label: t('backoffice.commissionplans.playerBalanceAdjustment'),
                value: "applyOtherCosts"
            })
        }
        options.push({
            label: t('backoffice.commissionplans.depositFee'),
            value: "applyDepositFee",
            disabled: depositFee === 0,
        })
        return options
    }, [source, t, depositFee]);

    useEffect(getProjectFeeSettings, [getProjectFeeSettings]);

    return (
        <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
                <Form.Item
                    label={`${t('backoffice.commissionplans.source')} *`}
                    name="source"
                    rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                    className={Boolean(editMode) || !canEdit ? "rt--form-item-disabled" : ""}
                >
                    <Select
                        options={[
                            { value: COMMISSION_PLAN_SOURCE.GGR, text: t('backoffice.commissionplans.ggr') },
                            { value: COMMISSION_PLAN_SOURCE.NGR, text: t('backoffice.commissionplans.ngr') },
                            { value: COMMISSION_PLAN_SOURCE.TURNOVER, text: t('backoffice.commissionplans.turnover') },
                            { value: COMMISSION_PLAN_SOURCE.NET_DEPOSIT, text: t('backoffice.commissionplans.netDeposit') }
                        ]}
                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.source')}`}
                        search={true}
                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        disabled={Boolean(editMode) || !canEdit}
                        onChange={value => setSource(value)}
                    />
                </Form.Item>
            </Col>
            {
                source !== COMMISSION_PLAN_SOURCE.NET_DEPOSIT && (
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label={`${t('backoffice.projects.product')} *`}
                            name="products"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            className={!canEdit ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={[
                                    { value: PROJECT_BRAND_PRODUCT_TYPE.CASINO, text: t('backoffice.projects.casino') },
                                    { value: PROJECT_BRAND_PRODUCT_TYPE.SPORT, text: t('backoffice.projects.sport') }
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.projects.product')}`}
                                disabled={!canEdit}
                                isMultiple={true}
                            />
                        </Form.Item>
                    </Col>
                )
            }

            {
                (
                    source === COMMISSION_PLAN_SOURCE.NGR || source === COMMISSION_PLAN_SOURCE.NET_DEPOSIT
                ) && (
                    <Col xs={24}>
                        <span className='rt--font-small rt--text-light rt--font-regular'>
                            {
                                source === COMMISSION_PLAN_SOURCE.NGR ? t('backoffice.commissionplans.feeInfo') : t('backoffice.commissionplans.netDepositFeeInfo')
                            }
                        </span>
                        <Form.Item
                            label=""
                            name="feeType"
                            className={"rt--mt-8" + (Boolean(editMode) || !canEdit ? " rt--form-item-disabled" : "")}
                        >
                            <Checkbox.Group
                                options={ feeTypeOptions }
                                disabled={Boolean(editMode) || !canEdit}
                             />
                        </Form.Item>
                    </Col>
                )
            }
            

            <Col span={24}>
                <Form.Item
                    label={t("backoffice.commissionplans.structure")}
                    name="structure"
                    rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                    className={"rt--form-item-radio rt--form-item-radio-structure rt--form-item-without-margin" + (Boolean(editMode) || !canEdit ? " rt--form-item-disabled" : "")}
                >
                    <Radio.Group
                        options={[
                            { label: t('backoffice.commissionplans.flat'), value: COMMISSION_PLAN_STRUCTURE.FLAT },
                            { label: t('backoffice.commissionplans.progressive'), value: COMMISSION_PLAN_STRUCTURE.PROGRESSIVE },
                            { label: t('backoffice.commissionplans.playerTiered'), value: COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED },
                            { label: t('backoffice.commissionplans.playerLifetime'), value: COMMISSION_PLAN_STRUCTURE.PLAYER_LIFETIME }
                        ]}
                        onChange={e => setStructure(e.target.value)}
                        disabled={Boolean(editMode) || !canEdit}
                    />
                </Form.Item>
            </Col>
            <Divider />

            {
                structure === COMMISSION_PLAN_STRUCTURE.FLAT ? (
                    <Col xs={24} sm={12} >
                        <Form.Item
                            label={`${t('backoffice.commissionplans.percent')} *`}
                            name="percent"
                            rules={[
                                { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                () => ({
                                    validator(rule, value) {
                                        if (value === "" || value === null || value === undefined) {
                                            return Promise.reject(t('backoffice.validation.fieldRequired'))
                                        }
                                        if (Number(value) < 0.000001 || Number(value) > 100) {
                                            return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0.000001).replace("%Y%", 100))
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}
                            validateFirst
                            className={'rt--form-item-with-suffix rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.percent')}`}
                        >
                            <NumericInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.percent')}`}
                                suffix={<span className='rt--text-light rt--font-smallest'>%</span>}
                                disabled={!canEdit}
                                maxLength={8}
                            />
                        </Form.Item>
                    </Col>
                ) : (
                    structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED ||
                    structure === COMMISSION_PLAN_STRUCTURE.PROGRESSIVE ||
                    structure === COMMISSION_PLAN_STRUCTURE.PLAYER_LIFETIME
                ) ? (
                    <Col span={24}>
                        <NumericRanges
                            label={
                                structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED ? (
                                    <div className="rt--flex rt--align-center">
                                        {t('backoffice.commissionplans.playerRanges')}
                                        <Tooltip
                                            title={t(`backoffice.commissionplans.playerRangesTooltip`)}
                                            trigger={["hover", "click"]}
                                            placement="bottomLeft"
                                            enableMobile={true}
                                        >
                                            <Icon name='info' size={14} className="rt--ml-6" />
                                        </Tooltip>
                                    </div>
                                ) : structure === COMMISSION_PLAN_STRUCTURE.PROGRESSIVE ? 
                                t('backoffice.commissionplans.sourceRanges') : t('backoffice.commissionplans.playerLifetimeMonth')
                            }
                            formInstance={formInstance}
                            isPercent={true}
                            fieldName="range"
                            editable={canEdit}
                            disabled={!canEdit}
                            maxPercent={100}
                            minRangesCount={2}
                            isInteger={structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED || structure === COMMISSION_PLAN_STRUCTURE.PLAYER_LIFETIME}
                        />
                    </Col>
                ) : null
            }
            {/* <Col span={24}>
                <div className="rt--flex-inline rt--align-center rt--form-item-checkbox">
                    <Form.Item
                        className='rt--form-item-inline rt--form-item-without-margin'
                        name="includeOtherCosts"
                        valuePropName='checked'
                    >
                        <Checkbox />
                    </Form.Item>
                    <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.commissionplans.includeOtherCosts')}</span>
                </div>
            </Col> */}
        </Row>
    )
}

/** RevShare propTypes
    * PropTypes
*/
RevShare.propTypes = {
    formInstance: PropTypes.object,
    editMode: PropTypes.shape({
        source: PropTypes.oneOf(Object.values(COMMISSION_PLAN_SOURCE)),
        structure: PropTypes.oneOf(Object.values(COMMISSION_PLAN_STRUCTURE)),
        isAffiliate: PropTypes.bool
    })
}

export default RevShare;