export const notificationState = {
    unreaded: 1,
    readed: 2,
}

export const NOTIFICATIONS_TABS = {
    ALL: 'all',
    UNREAD: 'unread',
}

export const TABS = {
    all: 'all',
    unread: 'unread',
}

export const pageLimit = 20;

export const minimumItemsCount = 5;

export const NOTIFICATION_KEY_BY_ACTION_CODE = {
    newAffiliateRegistered: 0,
    earningBalanceAdjustmentBonus: 1,
    earningBalanceAdjustmentDeduction: 2,
    assignedManagerUpdate: 3,
    addPaymentMethodToAffiliate: 4,
    removePaymentMethodFromAffiliate: 5,
    subAffiliateRegistersWithReferralLink: 6,
    commissionApproval: 7,
    newCalculatedCommissionsReadyForApproval: 8,
    commissionCalculated: 9,
    newCommissionAssigned: 10,
    commissionPlanUpdate: 11,
    removeCommissionFromAffiliate: 12,
    pendingInvoiceCreation: 13,
    payInvoice: 14,
    cancelInvoice: 15,
    userUpdateProfileInfo: 16,
    negativeCarryOverConfigurationChange: 17,
    referralProgramPercentageChange: 18,
    referralProgramSwitchedOnOff: 19,
    campaignCreation: 20,
    campaignActive: 21,
    campaignInactive: 22,
    accessManagerRequest: 23,
    addPaymentMethodByAffiliate: 24,
};

const ICON_KEYS = {
    affiliate: 'ntf-affiliate',
    campaign: 'ntf-campaign',
    comission: 'ntf-comission',
    invoice: 'ntf-invoice',
    project: 'ntf-project',
}

export const NOTIFICATION_ICON_NAME = {
    [NOTIFICATION_KEY_BY_ACTION_CODE.newAffiliateRegistered]: ICON_KEYS.affiliate,
    [NOTIFICATION_KEY_BY_ACTION_CODE.earningBalanceAdjustmentBonus]: ICON_KEYS.affiliate,
    [NOTIFICATION_KEY_BY_ACTION_CODE.earningBalanceAdjustmentDeduction]: ICON_KEYS.affiliate,
    [NOTIFICATION_KEY_BY_ACTION_CODE.assignedManagerUpdate]: ICON_KEYS.affiliate,
    [NOTIFICATION_KEY_BY_ACTION_CODE.addPaymentMethodToAffiliate]: ICON_KEYS.affiliate,
    [NOTIFICATION_KEY_BY_ACTION_CODE.addPaymentMethodByAffiliate]: ICON_KEYS.affiliate,
    [NOTIFICATION_KEY_BY_ACTION_CODE.removePaymentMethodFromAffiliate]: ICON_KEYS.affiliate,
    [NOTIFICATION_KEY_BY_ACTION_CODE.subAffiliateRegistersWithReferralLink]: ICON_KEYS.affiliate,

    [NOTIFICATION_KEY_BY_ACTION_CODE.commissionApproval]: ICON_KEYS.comission,
    [NOTIFICATION_KEY_BY_ACTION_CODE.newCalculatedCommissionsReadyForApproval]: ICON_KEYS.comission,
    [NOTIFICATION_KEY_BY_ACTION_CODE.commissionCalculated]: ICON_KEYS.comission,
    [NOTIFICATION_KEY_BY_ACTION_CODE.newCommissionAssigned]: ICON_KEYS.comission,
    [NOTIFICATION_KEY_BY_ACTION_CODE.commissionPlanUpdate]: ICON_KEYS.comission,
    [NOTIFICATION_KEY_BY_ACTION_CODE.removeCommissionFromAffiliate]: ICON_KEYS.comission,

    [NOTIFICATION_KEY_BY_ACTION_CODE.pendingInvoiceCreation]: ICON_KEYS.invoice,
    [NOTIFICATION_KEY_BY_ACTION_CODE.payInvoice]: ICON_KEYS.invoice,
    [NOTIFICATION_KEY_BY_ACTION_CODE.cancelInvoice]: ICON_KEYS.invoice,
    [NOTIFICATION_KEY_BY_ACTION_CODE.userUpdateProfileInfo]: ICON_KEYS.invoice,

    [NOTIFICATION_KEY_BY_ACTION_CODE.negativeCarryOverConfigurationChange]: ICON_KEYS.project,
    [NOTIFICATION_KEY_BY_ACTION_CODE.referralProgramPercentageChange]: ICON_KEYS.project,
    [NOTIFICATION_KEY_BY_ACTION_CODE.referralProgramSwitchedOnOff]: ICON_KEYS.project,

    [NOTIFICATION_KEY_BY_ACTION_CODE.campaignCreation]: ICON_KEYS.campaign,
    [NOTIFICATION_KEY_BY_ACTION_CODE.campaignActive]: ICON_KEYS.campaign,
    [NOTIFICATION_KEY_BY_ACTION_CODE.campaignInactive]: ICON_KEYS.campaign,

    [NOTIFICATION_KEY_BY_ACTION_CODE.accessManagerRequest]: ICON_KEYS.campaign,
}

export const NOTIFICATION_TITLES = {
    [NOTIFICATION_KEY_BY_ACTION_CODE.newAffiliateRegistered]: 'backoffice.notifications.NewAffiliateRegisteredTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.earningBalanceAdjustmentBonus]: 'backoffice.notifications.EarningBalanceAdjustmentTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.earningBalanceAdjustmentDeduction]: 'backoffice.notifications.EarningBalanceAdjustmentTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.assignedManagerUpdate]: 'backoffice.notifications.AssignedManagerUpdateTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.addPaymentMethodToAffiliate]: 'backoffice.notifications.AddPaymentMethodToAffiliateTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.addPaymentMethodByAffiliate]: 'backoffice.notifications.AddPaymentMethodByAffiliateTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.removePaymentMethodFromAffiliate]: 'backoffice.notifications.RemovePaymentMethodFromAffiliateTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.subAffiliateRegistersWithReferralLink]: 'backoffice.notifications.SubAffiliateRegistersWithReferralLinkTitle',

    [NOTIFICATION_KEY_BY_ACTION_CODE.commissionApproval]: 'backoffice.notifications.CommissionApprovalTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.newCalculatedCommissionsReadyForApproval]: 'backoffice.notifications.NewCalculatedCommissionsReadyForApprovalTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.commissionCalculated]: 'backoffice.notifications.CommissionCalculatedTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.newCommissionAssigned]: 'backoffice.notifications.NewCommissionAssignedTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.commissionPlanUpdate]: 'backoffice.notifications.CommissionPlanUpdateTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.removeCommissionFromAffiliate]: 'backoffice.notifications.RemoveCommissionFromAffiliateTitle',

    [NOTIFICATION_KEY_BY_ACTION_CODE.pendingInvoiceCreation]: 'backoffice.notifications.PendingInvoiceCreationTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.payInvoice]: 'backoffice.notifications.PayInvoiceTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.cancelInvoice]: 'backoffice.notifications.CancelInvoiceTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.userUpdateProfileInfo]: 'backoffice.notifications.UserUpdateProfileInfoTitle',

    [NOTIFICATION_KEY_BY_ACTION_CODE.negativeCarryOverConfigurationChange]: 'backoffice.notifications.NegativeCarryOverConfigurationChangeTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.referralProgramPercentageChange]: 'backoffice.notifications.ReferralProgramPercentageChangeTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.referralProgramSwitchedOnOff]: 'backoffice.notifications.ReferralProgramSwitchedOnOffTitle',

    [NOTIFICATION_KEY_BY_ACTION_CODE.campaignCreation]: 'backoffice.notifications.CampaignCreationTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.campaignActive]: 'backoffice.notifications.CampaignActiveTitle',
    [NOTIFICATION_KEY_BY_ACTION_CODE.campaignInactive]: 'backoffice.notifications.CampaignInactiveTitle',

    [NOTIFICATION_KEY_BY_ACTION_CODE.accessManagerRequest]: 'backoffice.notifications.AccessManagerRequestTitle',
};