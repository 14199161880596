import React, { Fragment } from "react"
import PropTypes from "prop-types";

import { Button } from "antd";

import Breadcrumbs from "components/common/breadcrumbs";
import Search from "components/common/search";
import ExportButton from "components/common/exportButton";
import ColumnsButton from "components/common/columnsButton";
import Select from "components/common/select";
import Icon from "components/common/icon";
import DateRangePicker from "components/common/dateRangePicker";

import { classNames, isMobile } from "utils/common";

import { SEARCH_TYPE } from "components/common/search/constants";

import breadcrumbsType from 'types/components/breadcrumbs.type';
import exportButtonType from 'types/components/exportButton.type';
import columnsButtonType from 'types/components/columnsButton.type';
import searchType from 'types/components/search.type';
import buttonType from 'types/components/button.type';

/** Main dashboard layout */
const MainDashboardLayout = ({
    children,
    header,
    dropdowns,
    extraContent,
    extraFooter,
    isContentScrollable = false
}) => {

    /** Function to Render dropdown 
         * @function
         * @returns {JSX}
         * @memberOf MainDashboardLayout
    */
    const renderDropdown = dropdown => (
        <Fragment>
            <Select
                options={
                    dropdown.items.map(item => (
                        {
                            value: item.key,
                            text: item.value,
                            subs: item.subs && item.subs.length > 0 ? item.subs.map(sub => ({ value: sub.key, text: sub.value })) : undefined
                        }
                    ))
                }
                placeholder={dropdown.placeholder}
                value={dropdown.value}
                onChange={dropdown.onChange}
                search={dropdown.search}
                isDark={true}
                className={classNames(
                    "rt--main-layout-header-actions-dropdown",
                    dropdown.middle && "rt--main-layout-header-actions-dropdown-middle",
                    dropdown.small && "rt--main-layout-header-actions-dropdown-small",

                )}
                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
            />
            {
                dropdown && dropdown.button && dropdown.button.enabled && (
                    <Button
                        icon={<Icon name={dropdown.button.icon} />}
                        type={dropdown.button.type}
                        className={
                            "rt--button" +
                            (isMobile() ? ' rt--ml-8 ' : ' rt--ml-16 ') +
                            (dropdown.button.className ? dropdown.button.className : "") +
                            (dropdown.button.type === "primary" ? "rt--button-main" : "")
                        }
                        onClick={dropdown.button.onClick}
                        key={dropdown.button.text}
                        disabled={dropdown.button.disabled ? true : false}
                    >
                        {
                            !isMobile() ? dropdown.button.text : null
                        }

                    </Button>
                )
            }
        </Fragment>
    )

    return (
        <div className={"rt--main-layout " + (!isMobile() ? "rt--pt-16 rt--pl-16 rt--pr-16 rt--pb-16" : "rt--pt-8")}>
            <div className="rt--main-layout-inner rt--flex rt--flex-col">

                <div className={"rt--main-layout-header" + (isMobile() ? " rt--pl-16 rt--pr-12" : " rt--pb-16")}>
                    <div className="rt--main-layout-header-inner">
                        {
                            header.breadcrumbs && (
                                <Breadcrumbs
                                    {...header.breadcrumbs}
                                    className="rt--breadcrumbs-with-margin"
                                />
                            )
                        }

                        <div className={"rt--main-layout-header-actions rt--flex rt--justify-between" + (!isMobile() ? " rt--pt-16 rt--align-end" : "rt--align-center")}>
                            <div className="rt--main-layout-header-actions-search rt--flex rt--align-center">
                                
                                {
                                    header.search && (
                                        <Search
                                            {...header.search}
                                        />
                                    )
                                }

                                {
                                    (!isMobile() && header.date) && (
                                        <Search
                                            {...header.date}
                                            type={SEARCH_TYPE.DATEPICKER}
                                        />
                                    )
                                }

                                {
                                    header.leftContent && (
                                        <div className="rt--main-layout-header-actions-search-extra">
                                            {header.leftContent}
                                        </div>
                                    )
                                }
                            </div>
                            <div className={
                                "rt--main-layout-header-actions-rest rt--flex rt--align-center rt--justify-end"
                                //(!dropdowns || !hasOnlyBreadcrumbs() ? "rt--justify-end" : "rt--flex-equal")
                            }>
                                {
                                    !isMobile() && dropdowns && (
                                        <Fragment>
                                            {
                                                dropdowns.map((d, index) => (
                                                    <div key={index} className={index !== 0 ? "rt--ml-16" : "rt--flex"}>
                                                        {renderDropdown(d)}
                                                    </div>
                                                ))
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    !isMobile() && (
                                        <Fragment>
                                            {
                                                header.columns && (
                                                    <ColumnsButton
                                                        {...header.columns}
                                                    />
                                                )
                                            }
                                            {
                                                header.export && (
                                                    <ExportButton
                                                        {...header.export}
                                                    />
                                                )
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    header.filters ? header.filters : null
                                }

                                {
                                    header.buttons && header.buttons.map(b => b.enabled ? (
                                        <Button
                                            icon={b.icon ? <Icon name={b.icon} /> : undefined}
                                            type={b.type}
                                            className={
                                                "rt--button" +
                                                (isMobile() ? ' rt--ml-8 ' : ' rt--ml-16 ') +
                                                (b.className ? b.className : "") +
                                                (b.type === "primary" ? "rt--button-main" : "")
                                            }
                                            onClick={b.onClick}
                                            key={b.text}
                                            disabled={b.disabled ? true : false}
                                        >
                                            {
                                                !isMobile() || !b.icon ? b.text : null
                                            }

                                        </Button>
                                    ) : null)
                                }

                                {
                                    header.extra ? <div className="rt--main-layout-header-actions-rest-extra rt--pl-16 rt--pr-16">{header.extra}</div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className={
                    "rt--main-layout-content rt--flex rt--flex-col rt--flex-equal " +
                    ( isContentScrollable ? " rt--overflow-auto" : " rt--overflow-hidden" ) +
                    ( isMobile() ? " rt--mt-8" : "")
                }>
                    {
                        ( isMobile() && header?.date ) && (
                            <div className="rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16">
                                <Search
                                    {...header.date}
                                    type={SEARCH_TYPE.DATEPICKER}
                                />
                            </div>
                        )
                    }
                    {
                        isMobile() && dropdowns && (
                            <div className="rt--main-layout-content-toolbar rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12">
                                {dropdowns.map((d, index) => (
                                    <Fragment key={index}>
                                        {renderDropdown(d)}
                                    </Fragment>
                                ))}
                            </div>
                        )
                    }
                    {
                        Boolean(extraContent?.content) && (
                            <div className={
                                "rt--main-layout-content-extra rt--pt-16 rt--pb-16 " +
                                (extraContent.isToolbar ? "rt--main-layout-content-extra-toolbar " : "") +
                                (!isMobile() ? "rt--pl-24 rt--pr-24" : "rt--pl-16 rt--pr-16")
                            }>
                                {extraContent.content}
                            </div>
                        )
                    }
                    <div className={
                        "rt--main-layout-content-inner rt--main-scrollable rt--flex-equal " +
                        (isMobile() ? "rt--pl-16 rt--pr-16 rt--pb-16" : "rt--pb-24 rt--pl-24 rt--pr-24") +
                        (!Boolean(extraContent?.content) ? isMobile() ? " rt--pt-16" : " rt--pt-24" : isMobile() ? " rt--pt-8" : "")
                    }>
                        {children}
                    </div>
                </div>

                {
                    (
                        isMobile() && extraFooter
                    ) && (
                        <div className="rt--main-layout-footer rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8">
                            {extraFooter}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

/** MainDashboardLayout propTypes
 * PropTypes
*/
MainDashboardLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Header options */
    header: PropTypes.shape({
        /** Search options */
        search: PropTypes.shape(searchType),
        /** Date Search options */
        date: PropTypes.shape(searchType),
        /** Filters component */
        filters: PropTypes.node,
        /** Columns Button */
        columns: PropTypes.shape(columnsButtonType),
        /** Export Button */
        export: PropTypes.shape(exportButtonType),
        /** Buttons */
        buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
        /** Breadcrumbs */
        breadcrumbs: PropTypes.shape(breadcrumbsType),
        /** Extra content component */
        extra: PropTypes.node,
        /** Left content component, near search */
        leftContent: PropTypes.node,
    }),
    /** Dropdowns options */
    dropdowns: PropTypes.arrayOf(PropTypes.object),
    /** Extra content under table */
    extraContent: PropTypes.shape({
        /** Extra content JSX */
        content: PropTypes.node,
        /** Has toolbar styles */
        isToolbar: PropTypes.bool
    }),
    /** Extra footer for mobile */
    extraFooter: PropTypes.node,
    /** Allows to scroll content (prevents default content behavior) */
    isContentScrollable: PropTypes.bool
}

export default MainDashboardLayout;