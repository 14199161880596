import React from 'react';

import { useTranslation } from "core/hooks/useTranslation";

import useDate from "core/hooks/useDate";

const StatusChangeInfo = ({
    lastStatusChangedAt,
    stateChangeReason,
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    return (
        <div className="rt--tooltip-status">
            {
                lastStatusChangedAt && (
                    <div className='rt--flex rt--flex-col rt--mb-16'>
                        <b className='rt--font-bold rt--font-normal'>{t("backoffice.affiliates.statusChangeDate")}</b>
                        <span className='rt--font-regular rt--font-normal'>{dateService.format(lastStatusChangedAt)}</span>
                    </div>
                )
            }

            {
                stateChangeReason && (
                    <div className='rt--flex rt--flex-col'>
                        <b className='rt--font-bold rt--font-normal'>{t("backoffice.affiliates.reason")}</b>
                        <span className='rt--font-regular rt--font-normal'>{stateChangeReason}</span>
                    </div>
                )
            }
            
        </div>
    )
}

export default StatusChangeInfo;