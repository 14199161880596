import BaseStore from "core/lib/baseStore";

import isObjectEmpty from "core/helpers/common/isObjectEmpty";
import getTimeZoneOffset from "core/helpers/date/getTimeZoneOffset";

import systemLanguageService from "core/services/apiServices/systemLanguageService";
import accountService from "core/services/apiServices/accountService";
import browserSessionStorage from "core/services/storageService/sessionStorage";
import dateService from "core/services/dateService";

import globalInitialState from "./initialState";

import { authStore } from "../authStore";
import { USER_ROLE } from "core/constants/auth";
import { USER_ROLE_SPECIFIC_PERMISSIONS } from "core/constants/permission";


class GlobalStore extends BaseStore {
    constructor() {
		super(globalInitialState);
	}

    setMainInfoLoading(isLoading){
        this.state.isMainInfoLoading = isLoading;
    }

    setLoading(isLoading){
        this.state.isLoading = isLoading;
    }

    getUserInfo(){
        return accountService.getUserInfo()
            .then(value => {
                const info = {...value };

                const { role } = authStore.getState().user;

                info.permissions = value.permissions ?? {};

                if([USER_ROLE.ACCESS_MANAGER, USER_ROLE.AFFILIATE_MANAGER, USER_ROLE.AFFILIATE].includes(role)){
                    info.permissions = USER_ROLE_SPECIFIC_PERMISSIONS[role]
                }

                this.state.userInfo = info;

                this.state.companies = value.companies.map(c => ({
                    id: c.id,
                    name: c.name,
                    type: c.type,
                    projects: c.projects ?? []
                }))

                const formatSetting = info.formatSetting;
                dateService.setDateFormat(formatSetting?.dateFormatting);
                dateService.setTimeFormat(formatSetting?.timeFormatting);
                dateService.setTimeZone(getTimeZoneOffset(formatSetting?.timeZoneId));
            })
    }

    updateUserInfo(info){
        const currentValue = this.state.userInfo;
        this.state.userInfo = {
            ...currentValue,
            ...info
        }
    }

    changeCurrency(currency){
        browserSessionStorage.set("currency", currency);
        this.state.currency = currency;
    }

    getCurrencies(){
        return accountService.getCurrencies()
            .then(value => {
                const selectedCurrency = this.state.currency;
                const selectedCurrencyExist = value.some(c => c.currencyCode === selectedCurrency)
                if(!selectedCurrency || !selectedCurrencyExist){
                    const defaultCurrency = value.find(c => c.currency?.isDefault) ?? value[0];
                    this.changeCurrency(defaultCurrency.currencyCode)
                }

                this.state.currencies = value;
            })
    }

    getSystemLanguages(){
        const storageLanguages = browserSessionStorage.get("languages") ?? {};
        const isSystemLanguagesLoaded = !isObjectEmpty(storageLanguages)

        if(isSystemLanguagesLoaded){
            return Promise.resolve(storageLanguages);
        }

        return systemLanguageService.getSystemLanguages()
            .then(({ data: { value } }) => {
                this.state.languages = value;
                browserSessionStorage.set("languages", value);
                return value;
            })
            .catch(() => {
                this.state.languages = {};
                browserSessionStorage.set("languages", {});
            });
    }

    updateUserAvatar(avatarId){
        this.state.userInfo.avatarId = avatarId
    }
}

export default GlobalStore;