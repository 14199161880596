import { globalCompanyIdSelector, globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';

import getCurrentProject from 'core/helpers/common/getCurrentProject';

const useCurrentProject = () => {

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);
    const { companies } = useGlobalSelector(userInfoSelector);

    return getCurrentProject(companies, globalCompanyId, globalProjectId);
}

export default useCurrentProject;