export const AUTOSUGGESTION_TYPE = {
    PROJECT: 1,
    COMPANY: 2,
    USER: 3,
    AFFILIATES: 4,
    ACCESS_MANAGER: 5,
    AFFILIATE_MANAGERS: 6,
    PLAYER: 7,
    PERMISSION_GROUP: 8,
    CONTROLLER: 9,
    COMMISSION_PLAN: 10,
    USER_AVAILABLE_PERMISSION_GROUP: 11,
    PAYMENT_LABELS: 12,
    SYSTEM_PAYMENT_METHODS: 13,
    CAMPAIGN: 14,
    BRAND: 15,
    CREATIVE: 16,
    MEDIA: 17,
    TRAFFIC_SOURCES: 18,
    NEWSLETTER: 19,
    AFFILIATE_GROUPS: 20,
    AFFILIATE_GROUP_MEMBERS: 21,
    PROJECT_PAYMENT_METHODS: 22
}