import getCurrentProject from "../common/getCurrentProject";

const isCommissionEnabled = ( projectId, user, userInfo ) => {
    if(!projectId || !Boolean(user) || !Boolean(userInfo)){
        return false
    }

    const currentProject = getCurrentProject(userInfo.companies, user.companyId, user.projectId);

    if(!currentProject) return false;

    if(!currentProject.commissionEnabled) return false;

    return true;
}

export default isCommissionEnabled;