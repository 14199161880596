import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";

import { Col, Form, Row, Spin } from 'antd';
import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import Input from 'components/common/input';
import Select from 'components/common/select';
import Icon from 'components/common/icon';

import RevShare from '../../../commissionForms/revShare';
import CPA from '../../../commissionForms/cpa';
import Fix from '../../../commissionForms/fix';
import CommissionPlanActionsComponent from '../../commissionPlan-actions.component';

import {
    getCommissionPlanGeneralInfo,
    saveCommissionPlanRevShare,
    saveCommissionPlanCPA,
    saveCommissionPlanFix
} from "store/actions/portal/commissionPlans/general.action"
import { getProjectFeeSettings } from 'store/actions/portal/projects/feeSettings.action';

import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';

import { isFormChanged } from "utils/form";
import { deleteQueryStringParamsToUrl } from 'utils/queryString';
import { isMobile } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { NAME_REGEX } from 'constants/regex.constants';
import { COMMISSION_PLAN_SOURCE, COMMISSION_PLAN_STRUCTURE, COMMISSION_PLAN_TYPE, INFINITY, MINUS_INFINITY } from 'constants/commission.constants';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';

import commissionPlanType from 'types/commissionPlan/commissionPlan.type';

import usePermissions from 'core/hooks/usePermission';

const GeneralInfoComponent = ({
    isLoading,
    isSaving,
    onTabChange,
    generalInfo,
    getProjectFeeSettings,
    getCommissionPlanGeneralInfo,
    saveCommissionPlanRevShare,
    saveCommissionPlanCPA,
    saveCommissionPlanFix,
    isAffiliate
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const currentCommissionId = (new URLSearchParams(search)).get("commissionId");

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, PERMISSION_ACTION.MODIFY );

    const canEdit = hasModifyPermission && !isAffiliate;

    /** Load general info */
    useEffect(() => {
        getCommissionPlanGeneralInfo(currentCommissionId);
    }, [])

    /** Set default values */
    useEffect(() => {
        const values = {
            name: generalInfo.name
        };

        if (generalInfo.type === COMMISSION_PLAN_TYPE.REVSHARE) {
            values.source = generalInfo.revShareConfiguration?.source;
            values.products = generalInfo.revShareConfiguration?.products;
            values.structure = generalInfo.revShareConfiguration?.structure;
            if(values.source === COMMISSION_PLAN_SOURCE.NGR || values.source === COMMISSION_PLAN_SOURCE.NET_DEPOSIT){
                values.feeType = [];
                if(generalInfo.revShareConfiguration?.applyDepositFee){
                    values.feeType.push("applyDepositFee");
                }
                if(values.source === COMMISSION_PLAN_SOURCE.NGR && generalInfo.revShareConfiguration?.applyOtherCosts){
                    values.feeType.push("applyOtherCosts")
                }
            }

            if (values.structure === COMMISSION_PLAN_STRUCTURE.FLAT) {
                values.percent = generalInfo.revShareConfiguration?.percent;
            } else if (
                values.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED ||
                values.structure === COMMISSION_PLAN_STRUCTURE.PROGRESSIVE ||
                values.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_LIFETIME
            ) {
                values.range = generalInfo.revShareConfiguration?.range.map((r, i) => ({
                    ...r,
                    from: i === 0 ? MINUS_INFINITY : r.from,
                    to: i === generalInfo.revShareConfiguration?.range.length - 1 ? INFINITY : r.to,
                }))
            }
        } else if (generalInfo.type === COMMISSION_PLAN_TYPE.CPA) {
            values.minDepositAmount = generalInfo.cpaConfiguration?.minDepositAmount;
            values.minTurnoverAmount = generalInfo.cpaConfiguration?.minTurnoverAmount;
            values.period = generalInfo.cpaConfiguration?.period;
            values.ftdOnly = generalInfo.cpaConfiguration?.ftdOnly;
            values.structure = generalInfo.cpaConfiguration?.structure;
            if (values.structure === COMMISSION_PLAN_STRUCTURE.FLAT) {
                values.amount = generalInfo.cpaConfiguration?.amount;
            } else if (values.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED || values.structure === COMMISSION_PLAN_STRUCTURE.QFTD_TIERED) {
                values.range = generalInfo.cpaConfiguration?.range.map((r, i) => ({
                    ...r,
                    from: i === 0 ? MINUS_INFINITY : r.from,
                    to: i === generalInfo.cpaConfiguration?.range.length - 1 ? INFINITY : r.to,
                }))
            }
        } else if (generalInfo.type === COMMISSION_PLAN_TYPE.FIX) {
            values.amount = generalInfo.fixedConfiguration?.amount;
        }

        setFieldsValue(values)
    }, [generalInfo])


    const handleForm = () => {
        validateFields()
            .then(data => {
                const commission = {
                    name: data.name,
                    id: currentCommissionId
                }

                if(generalInfo.type === COMMISSION_PLAN_TYPE.REVSHARE){
                    commission.products = data.products;
                    if(generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.FLAT){
                        commission.percent = data.percent;
                    } else if(
                        generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED ||
                        generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PROGRESSIVE ||
                        generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_LIFETIME
                    ){
                        commission.range = data.range.map((r, i) => (
                            { 
                                ...r, 
                                to: r.to === INFINITY ? null : r.to, 
                                from: r.from === MINUS_INFINITY ? null : r.from 
                            }
                        ));
                    }

                    saveCommissionPlanRevShare(commission);

                } else if(generalInfo.type === COMMISSION_PLAN_TYPE.CPA){
                    commission.minDepositAmount = data.minDepositAmount;
                    commission.minTurnoverAmount = data.minTurnoverAmount;
                    commission.period = data.period;
                    commission.ftdOnly = data.ftdOnly;
                    if(generalInfo.cpaConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.FLAT){
                        commission.amount = data.amount;
                    } else if(generalInfo.cpaConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED || generalInfo.cpaConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.QFTD_TIERED){
                        commission.range = data.range.map((r, i) => (
                            { 
                                ...r, 
                                from: r.from === MINUS_INFINITY ? null : r.from, 
                                to: r.to === INFINITY ? null : r.to
                            }
                        ));
                    }

                    saveCommissionPlanCPA(commission);

                } else if(generalInfo.type === COMMISSION_PLAN_TYPE.FIX){
                    commission.amount = data.amount;

                    saveCommissionPlanFix(commission);
                }
                setIsFormTouched(false)
            })
            .catch(Function.prototype)
    }

    const handleValuesChange = values => {
        const initialValues = {};
        const formValues = {};
        initialValues.name = generalInfo.name;
        formValues.name = values.name;
        if(generalInfo.type === COMMISSION_PLAN_TYPE.REVSHARE){
            initialValues.products = generalInfo.revShareConfiguration?.products;
            formValues.products = values.products;
            
            if(generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.FLAT){
                initialValues.percent = generalInfo.revShareConfiguration?.percent;
                formValues.percent = values.percent;
            } else if(
                generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED ||
                generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PROGRESSIVE ||
                generalInfo.revShareConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_LIFETIME
            ){
                initialValues.range = generalInfo.revShareConfiguration?.range;
                const range = values.range.filter(r => r.from !== undefined && r.to !== undefined)
                formValues.range = range.map((r, i) => (
                    { 
                        value: Number(r.value), 
                        from: i === 0 ? null :  Number(r.from), 
                        to: i === range.length - 1 ? null : Number(r.to), 
                    }
                ));
            }
        } else if(generalInfo.type === COMMISSION_PLAN_TYPE.CPA){
            initialValues.minDepositAmount = generalInfo.cpaConfiguration?.minDepositAmount;
            initialValues.minTurnoverAmount = generalInfo.cpaConfiguration?.minTurnoverAmount;
            initialValues.ftdOnly = generalInfo.cpaConfiguration?.ftdOnly;
            initialValues.period = generalInfo.cpaConfiguration?.period;
            formValues.minDepositAmount = values.minDepositAmount;
            formValues.ftdOnly = values.ftdOnly;
            formValues.minTurnoverAmount = values.minTurnoverAmount;
            formValues.period = values.period;
            if(generalInfo.cpaConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.FLAT){
                initialValues.amount = generalInfo.cpaConfiguration?.amount;
                formValues.amount = values.amount;
            } else if(generalInfo.cpaConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.PLAYER_TIERED || generalInfo.cpaConfiguration?.structure === COMMISSION_PLAN_STRUCTURE.QFTD_TIERED){
                initialValues.range = generalInfo.cpaConfiguration?.range;
                const range = values.range.filter(r => r.from !== undefined && r.to !== undefined)
                formValues.range = range.map((r, i) => (
                    { 
                        value: Number(r.value), 
                        from: i === 0 ? null :  Number(r.from), 
                        to: i === range.length - 1 ? null : Number(r.to), 
                    }
                ));
            }
        } else if(generalInfo.type === COMMISSION_PLAN_TYPE.FIX){
            initialValues.amount = generalInfo.fixedConfiguration?.amount;
            formValues.amount = values.amount;
        }
        setIsFormTouched(isFormChanged(initialValues, formValues));
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={[
                {
                    type: "primary",
                    onClick: handleForm,
                    text: t("backoffice.common.save"),
                    enabled: canEdit,
                    loading: isSaving,
                    disabled: !isFormTouched
                },
                {
                    type: "secondary",
                    onClick: () => {
                        const url = deleteQueryStringParamsToUrl(pathname, search, hash, ["commissionId", "commissionName"] );
                        navigateWithConfirmation(url, { replace: true } )
                    },
                    text: (
                        <span className='rt--back-button'>
                            <Icon name="left" size={16} />
                            <span>
                                {t("backoffice.common.back")}
                            </span>
                        </span>
                    ),
                    enabled: true,
                    className: "rt--button-secondary",
                    placement: "left",
                },
            ]}
            actions={<CommissionPlanActionsComponent isAffiliate={isAffiliate}/>}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, allValues) => handleValuesChange(allValues)}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.commissionplans.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!canEdit ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.name')}`}
                                    maxLength={30}
                                    disabled={!canEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.commissionplans.currency')} *`}
                                className='rt--form-item-disabled'
                            >
                                <Select
                                    options={[]}
                                    disabled={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    value={generalInfo.currencyCode}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.commissionplans.commissionType')} *`}
                                className='rt--form-item-disabled rt--form-item-without-margin'
                            >
                                <Select
                                    options={[
                                        { value: COMMISSION_PLAN_TYPE.REVSHARE, text: t('backoffice.commissionplans.revshare') },
                                        { value: COMMISSION_PLAN_TYPE.CPA, text: t('backoffice.commissionplans.cpa') },
                                        { value: COMMISSION_PLAN_TYPE.FIX, text: t('backoffice.commissionplans.fix') }
                                    ]}
                                    disabled={true}
                                    value={generalInfo.type}
                                />
                            </Form.Item>
                            {
                                generalInfo.type === COMMISSION_PLAN_TYPE.FIX && <small className="rt--text-light"><i>{ t("backoffice.commissionplans.fixCommissionHint") }</i></small>
                            }
                        </Col>
                    </Row>
                    <div className={isMobile() ? "rt--mt-12" : ""}>
                        <Row gutter={[16, 0]}>
                            <Col xs={24} sm={24} xl={12}>
                                {
                                    generalInfo.type === COMMISSION_PLAN_TYPE.REVSHARE ? (
                                        <RevShare
                                            formInstance={formInstance}
                                            getProjectFeeSettings={getProjectFeeSettings}
                                            editMode={{
                                                source: generalInfo?.revShareConfiguration?.source,
                                                structure: generalInfo?.revShareConfiguration?.structure,
                                                isAffiliate: isAffiliate
                                            }}
                                        />
                                    ) : generalInfo.type === COMMISSION_PLAN_TYPE.CPA ? (
                                        <CPA
                                            formInstance={formInstance}
                                            editMode={{
                                                structure: generalInfo?.cpaConfiguration?.structure,
                                                isAffiliate: isAffiliate
                                            }}
                                            isAffiliate={isAffiliate}
                                        />
                                    ) : generalInfo.type === COMMISSION_PLAN_TYPE.FIX ? (
                                        <Fix
                                            editMode={{
                                                isAffiliate: isAffiliate
                                            }}
                                        />
                                    ) : null
                                }
                            </Col>
                        </Row>
                    </div>
                    
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    );
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, is true when commission plan is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when commission plan is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, current editing commission plan general info */
    generalInfo: commissionPlanType,
    /** Redux action to get commission plan general info */
    getCommissionPlanGeneralInfo: PropTypes.func,
    /** Redux action to save commission plan rev share general info */
    saveCommissionPlanRevShare: PropTypes.func,
    /** Redux action to save commission plan cpa general info */
    saveCommissionPlanCPA: PropTypes.func,
    /** Redux action to save commission plan fix general info */
    saveCommissionPlanFix: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func,
    /** Is Affiliate */
    isAffiliate: PropTypes.bool
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionPlanGeneralInfo: id => {
            dispatch(getCommissionPlanGeneralInfo(id));
        },

        saveCommissionPlanRevShare: data => {
            dispatch(saveCommissionPlanRevShare(data));
        },

        saveCommissionPlanCPA: data => {
            dispatch(saveCommissionPlanCPA(data));
        },

        saveCommissionPlanFix: data => {
            dispatch(saveCommissionPlanFix(data));
        },

        getProjectFeeSettings: () => {
            dispatch(getProjectFeeSettings());
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.commissionPlans.isSaving,
        isLoading: state.commissionPlans.isLoading,
        generalInfo: state.commissionPlans.edit.general,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);