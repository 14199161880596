import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import TabTableDashboardLayout from 'components/layouts/tab/table';
import LineChart from 'components/common/lineChart';
import Filters from '../../../components/filters';

import { FINANCIAL_ENTITY_TYPE, LINE_CHART_CONFIGS_BY_ENTITY, LINE_CHART_ENTITY_TYPE, TIME_PERIOD } from '../../../constants';

import { constructLineChartConfigs } from "utils/lineChart";

import {
    getCheckPointsTooltipConfigs,
    getLegendConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";

import {
    getLineChartData,
    getFinancialLineChartTooltip
} from "../../../helpers";

import useFirstRender from 'hooks/useFirstRender';
import useDate from "core/hooks/useDate";
import useFormat from 'hooks/useFormat';

import useFinancialLineChart from '../../../hooks/useFinancialLineChart';
import { isMobile } from 'utils/common';

import {globalProjectIdSelector, useAuthSelector, userRoleSelector, userSelector} from 'core/stores/authStore';
import { currenciesSelector, currencySelector, useGlobalSelector } from 'core/stores/globalStore';
import {USER_ROLE} from "constants/user.constants.js";

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        },
        affiliateId: '',
    }
)

const Earning = ({
    updateLastUpdatedDate
}) => {

    const { t } = useTranslation();

    const [ filters, setFilters ] = useState(getDefaultFilters());

    const { dateService } = useDate();
    const { formatCurrencyWithSymbol, formatAmount } = useFormat();

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const { allowImpactOnCPA } = useAuthSelector(userSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const currencyCode = useGlobalSelector(currencySelector);
    const currencies = useGlobalSelector(currenciesSelector);

    const showFilterByAffiliate = userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.AFFILIATE_MANAGER;

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })

    const { chartData, isLoading } = useFinancialLineChart(
        FINANCIAL_ENTITY_TYPE.EARNING_COMMISSIONS_AMOUNT,
        filters.period,
        updateLastUpdatedDate,
        currencyCode,
        undefined,
        filters.affiliateId,
    );

    /** Chart Data */
    const configs = useMemo(() => {
        const valuePropNames = [];
        const lineNames = [];
        valuePropNames.push(LINE_CHART_ENTITY_TYPE.EARNING);
        lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-earning'>${t("backoffice.dashboard.earning")}</span>`)

        if(!allowImpactOnCPA){
            valuePropNames.push(LINE_CHART_ENTITY_TYPE.CPA);
            lineNames.push(`<span class='rt--highchart-legend-title' data-type='type-cpa'>${t("backoffice.dashboard.cpa")}</span>`)
        }

        const charts = valuePropNames.map(valuePropName => (
            getLineChartData(chartData || [], filters.period, valuePropName, dateService, t)
        ))

        const categories = charts[0].categories;
        const dates = charts[0].dates;

        const counts = {};

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.EARNING)){
            const earningChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.EARNING)
            counts[LINE_CHART_ENTITY_TYPE.EARNING] = charts[earningChartIndex].counts;
        }

        if(valuePropNames.includes(LINE_CHART_ENTITY_TYPE.CPA)){
            const cpaChartIndex = valuePropNames.findIndex(v => v === LINE_CHART_ENTITY_TYPE.CPA)
            counts[LINE_CHART_ENTITY_TYPE.CPA] = charts[cpaChartIndex].counts;
        }

        return constructLineChartConfigs({
            xAxisConfigs: getXAxisConfigs({
                checkPoints: categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    getFinancialLineChartTooltip(
                        element,
                        {
                            dates: dates,
                            counts: counts,
                            currencyCode,
                            period: filters.period,
                            entityType: FINANCIAL_ENTITY_TYPE.EARNING_COMMISSIONS_AMOUNT, 
                            dateService,
                            formatAmount,
                            formatCurrencyWithSymbol,
                            t
                        }
                    )
                )
            }),
            lines: charts.map((chart, index) => (
                getMainLineConfigs({
                    ...LINE_CHART_CONFIGS_BY_ENTITY[valuePropNames[index]],
                    data: chart.values,
                    name: lineNames[index],
                }) 
            )),
            legend: getLegendConfigs({
                valueFormatter: value => formatCurrencyWithSymbol(currencyCode, formatAmount(value, currencyCode), false)
            })
        })
    }, [chartData, filters.period, dateService, currencyCode, currencies, allowImpactOnCPA, t, filters.affiliateId])

    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ? (
                    <Filters filters={filters} updateFilters={updateFilters} showAffiliateFilter={showFilterByAffiliate} />
                ) : undefined,
            }}
        >
            <div className="rt--chart-section rt--pb-24 rt--pl-24 rt--pr-24 rt--pt-24">
                <div className='rt--chart-section-header rt--mb-16 rt--flex rt--justify-between rt--align-center'>
                    <span className='rt--chart-section-title rt--font-extra-bold rt--font-big'>{t("backoffice.dashboard.affiliateEarning")}</span>
                    {
                        !isMobile() && (
                            <Filters
                                filters={filters}
                                updateFilters={updateFilters}
                                showAffiliateFilter={showFilterByAffiliate}
                            />
                        )
                    }
                </div>
                <LineChart
                    configs={configs}
                    hideChart={chartData.length === 0}
                    isLoading={isLoading}
                />
            </div>
                    
        </TabTableDashboardLayout>
    )
}

Earning.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default Earning;