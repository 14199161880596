//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getCampaigns,
    setCampaignsSorting,
    setCampaignsFilters
} from "store/actions/portal/marketingTools/campaigns/campaigns.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import MediaToolCreateComponent from "../mediaTools/createMedia/mediaTool-create-edit.component";
import Filters from "./filters.component";
import CampaignCreateComponent from "./campaign-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { hasCampaignEditPageAccess } from "utils/pageAccess";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import ROUTES from "core/constants/routes/routes";
import ApiUrls from 'constants/api.constants';
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { CAMPAIGNS } from "constants/pageName.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region types
import campaignType from "types/campaign/campaign.type";
import sortingType from "types/common/sorting.type";
//#endregion

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from "core/stores/authStore";

import usePermissions from "core/hooks/usePermission";

import { USER_ROLE } from "core/constants/auth";

const CampaignsComponent = ({
    getCampaigns,
    campaigns,
    isLoading,
    total,
    setCampaignsSorting,
    setCampaignsFilters,
    sorting,
    filters
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const permissionUtils = usePermissions()

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const [createCampaignPopupVisible, setCreateCampaignPopupVisible] = useState(false);
    const [mediaCreatePopupData, setMediaCreatePopupData] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: CAMPAIGNS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCampaignCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, PERMISSION_ACTION.CREATE );
    const hasMediaCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_MEDIA, PERMISSION_ACTION.CREATE );
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, PERMISSION_ACTION.EXPORT );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCampaignsFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleDateSearchChange = value => {
        setCampaignsFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    const showCampaignCreatePopup = () => {
        setCreateCampaignPopupVisible(true)
    }

    const hideCampaignCreatePopup = () => {
        setCreateCampaignPopupVisible(false)
    }

    const handleRightArrowIconClick = (record) => {
        navigate(
            `${ROUTES.CAMPAIGNS_EDIT}/${record.id}` +
            `?name=${record.name}`
        )
    }

    const showMediaCreatePopup = (record) => {
        setMediaCreatePopupData({
            campaignId: record.id,
        })
    }

    const hideMediaCreatePopup = () => {
        setMediaCreatePopupData(null);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsThatCanBeIncluded,
        columnsForExport
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns: includedColumns,
            constructForInclude: true,
            constructForExport: true,
            additionalProps: {
                t
            },
            t
        })
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasMediaCreatePermission && userRole === USER_ROLE.AFFILIATE) {
        tableRowActions.push({
            title: t('backoffice.campaigns.createMedia'),
            icon: "close-tag",
            onClick: showMediaCreatePopup
        })
    }

    if (hasCampaignEditPageAccess(permissionUtils)) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "right",
            onClick: handleRightArrowIconClick
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getCampaigns,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.CAMPAIGN,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.campaigns.nameOrId")
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.campaigns.createdAt'),
            showTime: false,
            loadFn: getCampaigns,
            value: [filters.from, filters.to]
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        buttons: [
            {
                icon: "plus",
                type: "primary",
                onClick: showCampaignCreatePopup,
                text: t("backoffice.campaigns.createCampaign"),
                enabled: hasCampaignCreatePermission
            }
        ],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.campaigns') }]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.campaigns"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_CAMPAIGNS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={campaigns}
                loadFn={getCampaigns}
                sorting={sorting}
                setSortingFn={setCampaignsSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
            />

            {createCampaignPopupVisible && (
                <CampaignCreateComponent onClose={hideCampaignCreatePopup} />
            )}

            {mediaCreatePopupData && (
                <MediaToolCreateComponent
                    onClose={hideMediaCreatePopup}
                    formInitialValues={mediaCreatePopupData}
                />
            )}
        </MainDashboardLayout>
    )
};

/** CampaignsComponent propTypes
 * PropTypes
*/
CampaignsComponent.propTypes = {
    /** Redux action to get campaigns */
    getCampaigns: PropTypes.func,
    /** Redux state property, represents the array of campaigns  */
    campaigns: PropTypes.arrayOf(campaignType),
    /** Redux state property, is true when loading campaigns */
    isLoading: PropTypes.bool,
    /** Redux state property, campaigns total count */
    total: PropTypes.number,
    /** Redux action to set campaigns sorting details */
    setCampaignsSorting: PropTypes.func,
    /** Redux action to set campaigns sorting details */
    setCampaignsFilters: PropTypes.func,
    /** Redux state property, campaigns sorting details */
    sorting: sortingType,
    /** Redux state property, campaigns filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getCampaigns: nextPage => {
        dispatch(getCampaigns(nextPage));
    },
    setCampaignsSorting: sorting => {
        dispatch(setCampaignsSorting(sorting));
    },
    setCampaignsFilters: filters => {
        dispatch(setCampaignsFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.campaigns.isLoading,
        campaigns: state.campaigns.campaigns,
        total: state.campaigns.total,
        sorting: state.campaigns.sorting,
        filters: state.campaigns.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignsComponent);
