import React, {useState} from 'react';
import Icon from "core/ui-kit/icon";
import {
    NOTIFICATION_ICON_NAME,
    NOTIFICATION_TITLES,
    NOTIFICATION_KEY_BY_ACTION_CODE,
    notificationState,
} from "../../constants";
import {useTranslation} from "core/hooks/useTranslation";
import useDate from "core/hooks/useDate";
import Dropdown from "core/ui-kit/dropdown";
import useDevice from "core/hooks/useDevice";
import {useNavigate} from "react-router-dom";
import ROUTES from "core/constants/routes/routes";
import {authStore} from "core/stores/authStore";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "core/constants/permission";
import usePermissions from "core/hooks/usePermission";

const NotificationItem = ({
    height,
    action,
    message,
    time,
    removeNotification,
    markAsRead,
    id,
    state,
    type,
    companyId,
    projectId,
    globalProjectId,
    globalCompanyId,
    affiliateId,
    campaignId,
    closeNotifications,
    operationalLoading,
}) => {
    const [menuOpened, openChange] = useState(false);

    const { t } = useTranslation();
    const { isMobile } = useDevice();
    const { dateService } = useDate();
    const navigate = useNavigate();
    const permissionUtils = usePermissions();

    const iconName = NOTIFICATION_ICON_NAME[action];
    const title = NOTIFICATION_TITLES[action];

    const isUnread = state === 1;
    const unreadClass = isUnread ? 'unread' : '';
    const disableStyle = operationalLoading ? { pointerEvents: 'none' } : {};

    const items = [
        ...(isUnread ? [
            {
                key: 'markAsRead',
                label: (
                    <div className="rt--flex rt--align-center">
                        <span className="rt--title rt--font-regular rt--font-normal rt--pl-4 rt--pr-4" style={{ width: '100%' }}>
                            {t('backoffice.notifications.MarkAsRead')}
                        </span>
                        <Icon name="read" />
                    </div>
                )
            }
        ] : []),
        {
            key: 'remove',
            label: (
                <div className="rt--flex rt--align-center">
                    <span className="rt--title rt--font-regular rt--font-normal rt--pl-4 rt--pr-4" style={{ width: '100%' }}>
                        {t('backoffice.notifications.Remove')}
                    </span>
                    <Icon name="bin"/>
                </div>
            )
        }
    ];

    const handleMenuAction = (evt) => {
        const { key } = evt;
        if (key === 'remove') {
            removeNotification(id, type, state);
        }
        if (key === 'markAsRead') {
            markAsRead(id, type);
        }
        openChange(false);
    }

    const changeProjectAndRedirect = (redirectCompanyId, redirectProjectId, redirectUrl, changeRequired) => {
        if (changeRequired) {
            authStore.changeProject({
                companyId: redirectCompanyId,
                projectId: redirectProjectId,
            }).then(() => {
                navigate(redirectUrl);
                if (state === notificationState.unreaded) {
                    markAsRead(id, type);
                }
                closeNotifications();
            })
        } else {
            navigate(redirectUrl);
            if (state === notificationState.unreaded) {
                markAsRead(id, type);
            }
            closeNotifications();
        }
    }

    const handleClick = () => {
        if (operationalLoading) {
            return;
        }
        const isChangeRequired = companyId !== globalCompanyId || projectId !== globalProjectId;
        switch (action) {
            case NOTIFICATION_KEY_BY_ACTION_CODE.newAffiliateRegistered: {
                const navUrl = `${ROUTES.AFFILIATES_EDIT}/${affiliateId}`;
                const canRedirect = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, PERMISSION_ACTION.VIEW );
                if (!canRedirect) {
                    break;
                }
                changeProjectAndRedirect(companyId, projectId, navUrl, isChangeRequired);
                break;
            }
            case NOTIFICATION_KEY_BY_ACTION_CODE.addPaymentMethodToAffiliate: {
                const navUrl = `${ROUTES.AFFILIATES_EDIT}/${affiliateId}#tab=1`;
                const canRedirect = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, PERMISSION_ACTION.VIEW );
                if (!canRedirect) {
                    break;
                }
                changeProjectAndRedirect(companyId, projectId, navUrl, isChangeRequired);
                break;
            }
            case NOTIFICATION_KEY_BY_ACTION_CODE.subAffiliateRegistersWithReferralLink:
                const navUrl = `${ROUTES.AFFILIATES_EDIT}/${affiliateId}#tab=2`;
                const canRedirect = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE, PERMISSION_ACTION.VIEW );
                if (!canRedirect) {
                    break;
                }
                changeProjectAndRedirect(companyId, projectId, navUrl, isChangeRequired);
                break;
            case NOTIFICATION_KEY_BY_ACTION_CODE.newCalculatedCommissionsReadyForApproval: {
                const navUrl = `${ROUTES.CALCULATION_APPROVALS}`;
                const canRedirect = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_COMMISSION_CALCULATIONS, PERMISSION_ACTION.VIEW );
                if (!canRedirect) {
                    break;
                }
                changeProjectAndRedirect(companyId, projectId, navUrl, isChangeRequired);
                break;
            }
            case NOTIFICATION_KEY_BY_ACTION_CODE.pendingInvoiceCreation: {
                const navUrl = `${ROUTES.INVOICES}`;
                const canRedirect = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_INVOICES, PERMISSION_ACTION.VIEW );
                if (!canRedirect) {
                    break;
                }
                changeProjectAndRedirect(companyId, projectId, navUrl, isChangeRequired);
                break;
            }
            case NOTIFICATION_KEY_BY_ACTION_CODE.campaignCreation:
            case NOTIFICATION_KEY_BY_ACTION_CODE.campaignActive:
            case NOTIFICATION_KEY_BY_ACTION_CODE.campaignInactive: {
                const navUrl = `${ROUTES.CAMPAIGNS_EDIT}/${campaignId}`;
                const canRedirect = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, PERMISSION_ACTION.VIEW );
                if (!canRedirect) {
                    break;
                }
                changeProjectAndRedirect(companyId, projectId, navUrl, isChangeRequired);
                break;
            }
            default:
                if (state === notificationState.unreaded) {
                    markAsRead(id, type);
                }
                break;
        }
    }

    return (
        <div className="rt--notification-item" style={{ height: `${height}px` }}>
            <div className={`rt--notification-item-content rt--flex ${unreadClass}`}>
                <div className="rt--ntf-click-area" onClick={handleClick} style={disableStyle}>
                    {
                        !isMobile && (
                            <div className="rt--icon-holder">
                                <Icon name={iconName} size={24} />
                            </div>
                        )
                    }
                    <div className="rt--flex rt--flex-col rt--flex-equal">
                        <span className="rt--font-big rt--font-bold">{t(title)}</span>
                        <span className="rt--pt-6 rt--font-normal rt--font-regular rt--text-light">{message}</span>
                        <span className="rt--text-light rt--font-small rt--mt-12">
                            {dateService.format(time)}
                        </span>
                    </div>
                </div>
                <div>
                    <Dropdown
                        title={t("backoffice.common.actions")}
                        menu={{
                            items: items,
                            onClick: handleMenuAction,
                            className: "rt--table-actions-menu"
                        }}
                        popupOpened={menuOpened}
                        onOpenChange={openChange}
                    >
                        <div className="rt--table-col-actions-item rt--ml-8">
                            <Icon name="more" />
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default NotificationItem;
