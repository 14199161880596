import React from "react";

const NotificationSound = ({
   onEnded
}) => {
    return (
        <audio autoPlay src={`${import.meta.env.SYSTEM_CDN_URL}/system/static/media/notification.mp3`} onEnded={onEnded}/>
    )
}

export default NotificationSound;
