import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import { Form, Row, Col, Switch, Spin, DatePicker } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";

import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';
import Input from 'components/common/input';
import Confirmation from "components/common/confirmation";
import AvatarUpload from 'components/common/avatarUpload';
import Icon from "components/common/icon";
import AffiliateManagerActionsComponent from '../../affiliateManager-actions.component';

import { saveAffiliateManagerGeneralInfo, getAffiliateManagerGeneralInfo } from "store/actions/portal/userManagement/affiliateManagers/general.action";

import useDate from "core/hooks/useDate";
import useLongId from 'hooks/useLongId';

import { USER_GENDER, USER_ROLE, USER_STATE } from 'constants/user.constants';
import { TEL_REGEX, ADDRESS_REGEX, LAST_NAME_REGEX, FIRST_NAME_REGEX, EMAIL_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'core/constants/permission';

import { isMobile } from 'utils/common';
import { isFormChanged } from "utils/form";

import userGeneralInfoType from "types/user/generalInfo.type";

import usePermissions from 'core/hooks/usePermission';
import { globalCompanyIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Affiliate Manager Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    getAffiliateManagerGeneralInfo,
    saveAffiliateManagerGeneralInfo,
    isSaving,
    isLoading,
    generalInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const permissionUtils = usePermissions();

    const { getCompanyLongIdById } = useLongId();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);

    const { dateService } = useDate();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [expirationEnabled, setExpirationEnabled] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const mainCompanyLongId = getCompanyLongIdById(globalCompanyId);


    /** Load affiliate manager general info */
    useEffect(() => {
        getAffiliateManagerGeneralInfo(searchParams.id)
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            firstName: generalInfo.firstName,
            lastName: generalInfo.lastName,
            gender: generalInfo.gender,
            phoneNumber: generalInfo.phoneNumber,
            address: generalInfo.address,
            passwordExpirationEnabled: generalInfo.passwordExpirationEnabled,
            expirationEnabled: generalInfo.expirationEnabled,
            allowEdit: generalInfo.allowEdit,
            email: generalInfo.email
        });

        setExpirationEnabled(generalInfo.expirationEnabled)
    }, [generalInfo])


    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = forceSave => {
        validateFields()
            .then(data => {
                if (!generalInfo.expirationEnabled || data.expirationEnabled || forceSave) {
                    saveAffiliateManagerGeneralInfo({
                        ...data,
                        expirationDate: data.expirationDate && data.expirationEnabled ? dateService.toISOString(data.expirationDate) : null,
                        id: searchParams.id
                    });
                    setIsFormTouched(false);
                    if (forceSave) {
                        setShowConfirm(false)
                    }
                } else {
                    setShowConfirm(true)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    /** Function, returns if fields are editable
       * @function
       * @returns {boolean}
       * @memberOf GeneralInfoComponent
   */
    const canEdit = () => permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_MANAGER_GENERALINFO, PERMISSION_ACTION.MODIFY );

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: canEdit(),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            actions={<AffiliateManagerActionsComponent />}
            id={generalInfo.id}
            longId={generalInfo.longId}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        firstName: generalInfo.firstName,
                        lastName: generalInfo.lastName,
                        email: generalInfo.email,
                        gender: generalInfo.gender,
                        phoneNumber: generalInfo.phoneNumber,
                        address: generalInfo.address,
                        userName: generalInfo.userName,
                        passwordExpirationEnabled: generalInfo.passwordExpirationEnabled,
                        expirationEnabled: generalInfo.expirationEnabled,
                        allowEdit: generalInfo.allowEdit
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, {
                        firstName: generalInfo.firstName,
                        lastName: generalInfo.lastName,
                        email: generalInfo.email,
                        gender: generalInfo.gender,
                        phoneNumber: generalInfo.phoneNumber,
                        address: generalInfo.address,
                        passwordExpirationEnabled: generalInfo.passwordExpirationEnabled,
                        expirationEnabled: generalInfo.expirationEnabled,
                        allowEdit: generalInfo.allowEdit
                    }))}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} xl={8} >
                            <AvatarUpload
                                userId={searchParams.id}
                                userLongId={generalInfo.longId}
                                name={`${generalInfo.firstName} ${generalInfo.lastName}`}
                                avatarId={generalInfo.avatarId}
                                canEdit={canEdit()}
                                isProfile={false}
                                role={USER_ROLE.AFFILIATE_MANAGER}
                                companyId={mainCompanyLongId}
                            />
                        </Col>

                        <Col xs={24} sm={24} xl={16}>

                            <Row gutter={[16, 0]}>
                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.username')}
                                        className='rt--form-item-disabled'
                                    >
                                        <Input
                                            disabled={true}
                                            value={generalInfo.userName}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.email')}
                                        name="email"
                                        validateFirst={true}
                                        className={'rt--general-form-item' + (!canEdit() ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                                        rules={[
                                            { pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') },
                                            { max: 50, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.email')}`}
                                            disabled={!canEdit()}
                                            maxLength={50}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={`${t('backoffice.users.firstName')} *`}
                                        name="firstName"
                                        rules={[
                                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                            { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                            { pattern: FIRST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        validateFirst={true}
                                        className={'rt--general-form-item' + (!canEdit() ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                                    >
                                        <Input
                                            maxLength={48}
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.firstName')}`}
                                            disabled={!canEdit()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={`${t('backoffice.users.lastName')} *`}
                                        name="lastName"
                                        rules={[
                                            { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                            { max: 48, message: t('backoffice.validation.fieldInvalid') },
                                            { pattern: LAST_NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        validateFirst={true}
                                        className={'rt--general-form-item' + (!canEdit() ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                                    >
                                        <Input
                                            maxLength={48}
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.lastName')}`}
                                            disabled={!canEdit()}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={`${t('backoffice.users.gender')} *`}
                                        name="gender"
                                        rules={[
                                            { required: true, message: t('backoffice.validation.fieldRequired') },
                                        ]}
                                        className={!canEdit() ? " rt--form-item-disabled" : ""}
                                    >
                                        <Select
                                            options={[
                                                { value: USER_GENDER.MALE, text: t('backoffice.common.male') },
                                                { value: USER_GENDER.FEMALE, text: t('backoffice.common.female') },
                                                { value: USER_GENDER.NONE, text: t('backoffice.common.other') }
                                            ]}
                                            disabled={!canEdit()}
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.gender')}`}
                                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.tel')}
                                        name="phoneNumber"
                                        rules={[
                                            { pattern: TEL_REGEX, message: t('backoffice.validation.telFormat') },
                                            { max: 18, message: t('backoffice.validation.fieldInvalid') }
                                        ]}
                                        className={'rt--general-form-item' + (!canEdit() ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.tel')}`}
                                    >
                                        <NumericInput
                                            maxLength={18}
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.tel')}`}
                                            disabled={!canEdit()}
                                            isMobileNumber={true}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={14} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.address')}
                                        name="address"
                                        rules={[
                                            { max: 102, message: t('backoffice.validation.fieldInvalid') },
                                            { pattern: ADDRESS_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                        ]}
                                        validateFirst
                                        className={'rt--general-form-item' + (!canEdit() ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.address')}`}
                                    >
                                        <Input
                                            maxLength={102}
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.users.address')}`}
                                            disabled={!canEdit()}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                        <Form.Item
                                            name="passwordExpirationEnabled"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch
                                                disabled={!canEdit() || generalInfo.state === USER_STATE.IN_PROGRESS}
                                            />
                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.users.forcePasswordChange')}</label>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                        <Form.Item
                                            name="allowEdit"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch
                                                disabled={!canEdit()}
                                            />
                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.users.allowEdit')}</label>

                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                        <Form.Item
                                            name="expirationEnabled"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch
                                                onChange={e => setExpirationEnabled(e)}
                                                disabled={!canEdit()}
                                            />
                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.users.expiration')}</label>

                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]} style={{ display: expirationEnabled ? "block" : "none" }}>
                                <Col xs={24} sm={24} xl={12} >
                                    <Form.Item
                                        label={t('backoffice.users.expirationDate')}
                                        name="expirationDate"
                                        rules={[

                                            () => ({
                                                validator(rule, value) {
                                                    if (!value && expirationEnabled) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }
                                                    if(
                                                        expirationEnabled &&
                                                        dateService.isBefore(value, dateService.getNow())
                                                    ){
                                                        return Promise.reject(t('backoffice.validation.fieldInvalid'))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                    >
                                        {
                                            !generalInfo.expirationEnabled ?
                                                (
                                                    <DatePicker
                                                        className="rt--datepicker rt--datepicker-withtime"
                                                        dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup'
                                                        format={`${dateService.getFormat()}`}
                                                        showTime={{ format: dateService.getFormat(true, false) }}
                                                        disabledDate={d => dateService.isBefore(d, dateService.getNow())}
                                                        showToday={false}
                                                        showNow={false}
                                                        allowClear={true}
                                                        placeholder={t('backoffice.common.selectDate')}
                                                        suffixIcon={<Icon name='date' />}
                                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                                        disabled={!canEdit() || generalInfo.state === USER_STATE.EXPIRED}
                                                        inputReadOnly={isMobile()}
                                                    />
                                                ) : generalInfo.expirationDate ? (
                                                    <div className='rt--flex rt--justify-start rt-align-center rt--form-info-row rt--pr-8'>
                                                        <b className='rt--title rt--font-bold rt--font-normal rt--pr-8'>{t('backoffice.users.expirationDate')}</b>
                                                        <span className='rt--title rt--font-regular rt--font-normal rt--mr-16'>{dateService.format(generalInfo.expirationDate)}</span>
                                                    </div>
                                                ) : null
                                        }

                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                </Form>
                {
                    showConfirm && (
                        <Confirmation
                            title=""
                            message={t('backoffice.users.expirationConfirmMessage')}
                            onOk={() => handleForm(true)}
                            onCancel={() => setShowConfirm(false)}
                            isVisible={true}
                        />
                    )
                }
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux action to get affiliate manager General info */
    getAffiliateManagerGeneralInfo: PropTypes.func,
    /** Redux action to save affiliate manager General info */
    saveAffiliateManagerGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing affiliate manager  */
    generalInfo: userGeneralInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateManagerGeneralInfo: id => {
            dispatch(getAffiliateManagerGeneralInfo(id));
        },

        saveAffiliateManagerGeneralInfo: data => {
            dispatch(saveAffiliateManagerGeneralInfo(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.affiliateManagers.edit.general,
        isSaving: state.affiliateManagers.isSaving,
        isLoading: state.affiliateManagers.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)