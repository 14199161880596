import NotificationsStore from "./store";

import { createSelector } from "core/lib/baseStore";

import { debugStore } from "core/lib/baseStore/debugStore";

export const notificationsStore = new NotificationsStore();

debugStore.registerStore(notificationsStore);

export const useNotificationsSelector = createSelector(notificationsStore);

export const notificationsSelector = state => state.notifications;
export const totalSelector = state => state.total;

export const unreadNotificationsSelector = state => state.unreadNotifications;
export const unreadTotalSelector = state => state.unreadTotal;

export const soundEnabledSelector = state => state.isSoundEnabled;
export const playSoundSelector = state => state.playSound;