import React, { useEffect, useState } from 'react';

import { useTranslation } from "core/hooks/useTranslation";
import usePermissions from 'core/hooks/usePermission';
import useCurrentProject from 'core/hooks/useCurrentProject';

import { Row, Col } from "core/ui-kit/layout";
import Form from "core/ui-kit/form";
import Divider from 'core/ui-kit/divider';
import Alert from 'core/ui-kit/alert';
import Modal from "core/ui-kit/modal";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'core/constants/permission';
import { FORM_FIELD_NAMES } from './constants';

import FormRulesBuilder from 'core/helpers/common/formRulesBuilder';
import affiliateService from 'core/services/apiServices/affiliateService';


/** Approve Popup */
const ApprovePopup = ({
    isVisible,
    onClose,
    showCommissionsPopup,
    affiliateId,
    onSuccess
}) => {
    const { t } = useTranslation();

    const currentProject = useCurrentProject();

    const [commissionsCount, setCommissionCount] = useState(0)

    const [formInstance] = Form.useForm();
    
    const permissionUtils = usePermissions();

    const handleForm = data => {
        onSuccess(data[FORM_FIELD_NAMES.REASON]);
 
        onClose();
    }

    const redirectToCommissionsPopup = () => {
        showCommissionsPopup();
        onClose();
    }

    useEffect(() => {
        if(isVisible && currentProject.commissionEnabled){
            affiliateService.getCommissionsCount(affiliateId)
                .then(value => setCommissionCount(value))
        } 
    }, [isVisible, currentProject.commissionEnabled])

    const canSave = !currentProject.commissionEnabled || commissionsCount > 0

    return (
        <Modal
            open={isVisible}
            title={t('backoffice.common.approve')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.approve')}
            okButtonProps={{disabled: !canSave}}
            onOk={() => formInstance.submit()}
            onCancel={onClose}
        >
            <Form
                form={formInstance}
                initialValues={{
                    reason: ""
                }}
                onFinish={handleForm}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24} >
                        <Form.Textarea
                            label={t('backoffice.common.reason')}
                            name={FORM_FIELD_NAMES.REASON}
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.common.reason')}`}
                            rules={
                                new FormRulesBuilder(t).required().maxCharactersCount(1000).minCharactersCount(6).build()
                            }
                            maxLength={1000}
                        />
                    </Col>    
                </Row>
                {
                    (
                        commissionsCount === 0 && currentProject.commissionEnabled
                    ) && (
                        <Row gutter={[16, 0]}>
                            <Col span={24}>                        
                                <Divider />
                                <Alert 
                                    type="warning"
                                    message={t("backoffice.affiliates.affiliateHasNoAssignedCommissions")}
                                    description={
                                        permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, PERMISSION_ACTION.VIEW ) ? (
                                            <span className='rt--link' onClick={redirectToCommissionsPopup}>{t("backoffice.affiliates.commissionsDetails")}</span> 
                                        ) : ""
                                    }
                                />
                            </Col>
                        </Row>
                    )
                }
            </Form>
        </Modal>
    )
}


export default ApprovePopup;