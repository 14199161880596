import React, { Fragment, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'core/hooks/useTranslation';

import TabTableDashboardLayout from 'components/layouts/tab/table';

import {Col, Row, Carousel} from 'antd';

import PieChart from '../../../components/pieChart';
import OperationalLineChart from '../../../components/operationalLineChart';
import OperationalWidget from '../../../components/operationalWidget';
import Filters from "../../../components/filters";

import { isMobile } from 'utils/common';

import { OPERATIONAL_ENTITY_TYPE, TIME_PERIOD } from '../../../constants';
import { USER_ROLE } from 'constants/user.constants';

import useFirstRender from 'hooks/useFirstRender';

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import FilterByAffiliate from 'pages/dashboard/components/filterByAffiliate';

const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        },
        affiliateId: '',
    }
)

const General = ({
    updateLastUpdatedDate
}) => {

    const { t } = useTranslation();

    const [ filters, setFilters ] = useState(getDefaultFilters());

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const showFilterByAffiliate = userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.AFFILIATE_MANAGER;

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })


    const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);

    const handleCarouselChange = (_, nextIndex) => {
        setCarouselActiveIndex(nextIndex);
    }

    const availableEntityTypes = useMemo(() => {
        if(userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.AFFILIATE_MANAGER){
            return [
                OPERATIONAL_ENTITY_TYPE.AFFILIATE,
                OPERATIONAL_ENTITY_TYPE.MEDIA,
                OPERATIONAL_ENTITY_TYPE.CAMPAIGN,
                OPERATIONAL_ENTITY_TYPE.PLAYER
            ]
        } else {
            return [
                OPERATIONAL_ENTITY_TYPE.MEDIA,
                OPERATIONAL_ENTITY_TYPE.PLAYER
            ]
        }
    }, [])

    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ? (
                    <Filters filters={filters} updateFilters={updateFilters} showAffiliateFilter={showFilterByAffiliate} />
                ) : undefined,
            }}
        >
            {
                !isMobile() ? (
                    <Fragment>
                        {
                            !isMobile() && (
                                <div className='rt--flex rt--pb-24 rt--justify-end'>
                                    {
                                        showFilterByAffiliate && (
                                            <FilterByAffiliate
                                                onChange={(affiliateId) => updateFilters({ affiliateId: affiliateId })}
                                                value={filters?.affiliateId}
                                            />
                                        )
                                    }
                                </div>
                            )
                        }
                        <Row gutter={[16, 16]}>
                            {
                                availableEntityTypes.map(entityType => (
                                    <Col xl={6} lg={12} xs={12} key={entityType}>
                                        <PieChart
                                            entityType={entityType}
                                            globalProjectId={globalProjectId}
                                            updateLastUpdatedDate={updateLastUpdatedDate}
                                            affiliateId={filters?.affiliateId}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                        <div className='rt--mt-24'>
                            <div className='rt--flex rt--justify-between rt--mb-16'>
                                <h4 className='rt--form-section-title rt--title rt--font-bold rt--font-biger'>
                                    {t("backoffice.dashboard.player")}
                                </h4>
                                <Filters filters={filters} updateFilters={updateFilters} />
                            </div>
                            
                            <Row gutter={[16, 24]}>
                                <Col span={16}>
                                    <OperationalLineChart
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        filters={filters}
                                    />
                                </Col>
                                <Col span={8}>
                                    <div className='rt--chart-section rt--pt-16 rt--pb-16 rt--pl-24 rt--pr-24'>
                                        <div className='rt--mb-16'>
                                            <OperationalWidget
                                                dataType={OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_DEPOSIT}
                                                updateLastUpdatedDate={updateLastUpdatedDate}
                                                filters={filters}
                                            />
                                        </div>
                                        <div>
                                            <OperationalWidget
                                                dataType={OPERATIONAL_ENTITY_TYPE.ACTIVE_PLAYERS_FOR_BET}
                                                updateLastUpdatedDate={updateLastUpdatedDate}
                                                filters={filters}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Fragment>
                ) : (
                    <div className='rt--chart-operational-pie-carousel'>
                        <Carousel 
                            infinite={false} 
                            beforeChange={handleCarouselChange}
                        >
                            {
                                availableEntityTypes.map( ( entityType, index) => (
                                    <PieChart
                                        entityType={entityType}
                                        globalProjectId={globalProjectId}
                                        isActive={carouselActiveIndex === index}
                                        updateLastUpdatedDate={updateLastUpdatedDate}
                                        key={entityType}
                                        affiliateId={filters?.affiliateId}
                                    />
                                ))
                            }
                            
                        </Carousel>
                    </div>
                )
            }
            
        </TabTableDashboardLayout>
    )
}

General.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default General;