import {
    getCheckPointsTooltipConfigs,
    getXAxisConfigs,
    getYAxisConfigs,
    getLegendConfigs
} from "./configCreators";

import { LINE_CHART_DEFAULT_CONFIGS } from "constants/lineChart.constants";

export const constructLineChartConfigs = ({
    xAxisConfigs = getXAxisConfigs(),
    yAxisConfigs = getYAxisConfigs(),
    checkPointsTooltipConfigs = getCheckPointsTooltipConfigs(),
    legend = getLegendConfigs(), // For Constructing legend Configs Use <getLegendConfigs> Function
    lines = [], // For Constructing Each Line Configs Use <getMainLineConfigs> Function,
    height
} = {}) => {
    return {
        ...LINE_CHART_DEFAULT_CONFIGS,
        ...legend,
        chart: {
            ...LINE_CHART_DEFAULT_CONFIGS.chart,
            height
        },
        xAxis: xAxisConfigs,
        yAxis: yAxisConfigs,
        tooltip: checkPointsTooltipConfigs,
        series: lines,
    }
}
